// *************************************************************//
//@Purpose : We can use following api calls and can change api name easily
//*************************************************************//
export default {
    //*****************************************   SUPER ADMIN *************************************** */
    
    //***************** COMMON *************** */
    FILE_UPLOAD: '/admin/fileUpload',
    EXPORT_CSV: '/admin/downloadCsv',
    GET_DASHBOARD_DATA: '/admin/dashboard',
    
    //*************** ADMIN AUTHENTICATION **************** */
    ADMIN_LOGIN: '/admin/login',
    FORGOT_PASSWORD: '/admin/forgotPassword',
    RESET_PASSWORD: '/admin/resetPassword',

    //************ WORKTYPE ************** */
    ADD_WORKTYPE: '/workTypeManagement/addWorkType',
    UPDATE_WORKTYPE: '/workTypeManagement/',
    LIST_WORKTYPE: '/workTypeManagement/listing',
    DELETE_WORKTYPE: '/workTypeManagement/delete',
    GET_WORKTYPE: '/workTypeManagement/',
    WORKTYPE_STATUS:'/workTypeManagement/changeStatus/',

    //************SUBJECT MANAGEMENT ********** */
    ADD_SUBJECT: '/subjectManagement/AddSubject',
    UPDATE_SUBJECT: '/subjectManagement/',
    LIST_SUBJECT: '/subjectManagement/listing',
    DELETE_SUBJECT: '/subjectManagement/delete',
    GET_SUBJECT: '/subjectManagement/',
    SUBJECT_STATUS:'/subjectManagement/changeStatus/',

    //********* DEPARTMENT MANAGEMENT *************/
    ADD_DEPARTMENT: '/departmentManagement/addDepartment',
    UPDATE_DEPARTMENT: '/departmentManagement/',
    LIST_DEPARTMENT: '/departmentManagement/listing',
    DELETE_DEPARTMENT: '/departmentManagement/delete',
    GET_DEPARTMENT: '/departmentManagement/',
    DEPARTMENT_STATUS: '/departmentManagement/changeStatus/',
    DEPT_UPLOAD_VIA_CSV: '/departmentManagement/addByCSV',    
    
    //************TUTOR MANAGEMENT ****************/
    ADD_TUTOR: '/tutorManagement/addTutor',
    UPDATE_TUTOR: '/tutorManagement/',
    LIST_TUTOR: '/tutorManagement/listing',
    GET_TUTOR: '/tutorManagement/',
    TUTOR_STATUS :'/tutorManagement/changeStatus/',
    DELETE_TUTOR: '/tutorManagement/delete',

     //*********** MENTOR MANAGEMENT ****************/
     ADD_MENTOR: '/mentorManagement/addMentor',
     UPDATE_MENTOR: '/mentorManagement/',
     LIST_MENTOR: '/mentorManagement/listing',
     GET_MENTOR: '/mentorManagement/',
     MENTOR_STATUS: '/mentorManagement/mentorStatus',
     DELETE_MENTOR: '/mentorManagement/delete',

     //*********** USER(STUDENT) MANAGEMENT ****************/
     LIST_USER: '/studentManagement/listing',
     USER_STATUS: '/studentManagement/changeStatus/',
     GET_USER: '/studentManagement/',
     DELETE_USER: '/studentManagement/delete',

     //************* REPORT MANAGEMENT ***************** */
     LIST_TASK: '/taskManagement/listing',
     GET_TASK: '/taskManagement/'
}