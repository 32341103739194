import React, { useState,useEffect } from 'react'
import Home from '../Home'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom";
import common from '../../actions/common';
import swal from 'sweetalert'
import $ from 'jquery';
import _ from 'lodash';
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import * as language from "../../translations/index";
import moment from 'moment'
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WorkType(props){

    const [blogCategoryTitle, setBlogCategoryTitle] = useState('');
    const [blogCategoryDescription, setBlogCategoryDescription] = useState('');
    const [categoryStatus, setCategoryStatus] = useState(false);
    const [workTypeListing, setWorkTypeListing] = useState('');
    const [createdById, setCreatedById] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [columnSettingsArr, setColumnSettingsArr] = useState([]);
    const [page, setPage] = useState(1);
    const [sortData,sort, onSort] = useSortng();
    const [pagesize, setPagesize] = useState(10);
    const [total, setTotal] = useState(1);
    const [multipleDelete, setMultipleDelete] = useState([]);
    const [condition, setCondition] = useState('');
    const [loading, setLoading] = useState(true);
    const [workTypeId, setWorkTypeId] = useState('');
    const [isFormValid, setIsFormValid] = useState('');
    const [show, setShow] = useState(false);
    const [editWorkType, setEditWorkType] = useState(false);
    const [workId, setWorkId] = useState('');
    const [searchText, setSearchText] = useState('');
    let [errors, setErrors] = useState('');

    const [workType, setWorkType] = useState('');

    useEffect(() => {
      getAllWorkType();
      setShow(false);
    }, []);

    useEffect(() => {
      if (props.admindata && !_.isEmpty(props.admindata)) {
        let { firstname, lastname, _id } = props.admindata;
        setCreatedBy(firstname + " " + lastname);
        setCreatedById(_id);
      }
      
    }, []);
    
    const getAllWorkType = async() => {
      let body = {
        page, 
        pagesize, 
        sort, 
        // columnKey:"workTypeListing",
        // condition:condition
      };
      try {
      const response = await props.callApi(API.LIST_WORKTYPE, body, 'post', null, true);
      console.log("response", response);
        if (response.settings.status === 1) {
          setWorkTypeListing(response.data.listing);
          // setColumnSettingsArr(response.data.columnSettings);
          setTotal(response.data.total);
          setLoading(false);
        } 
      } catch(error){
        setLoading(false);
        throw (error)
      }
    }

  //**************** VALIDATE FORM **************** */
  const  validateForm = () => {
    let errors  = { workType : "" };
    let isFormValid = true;
    
    if (!workType.trim()) errors.workType = '*Work type is required'
    else if(workType.length < 2) errors.workType = "*Work Type should be minimum 2 characters"
    else errors.workType = '';


    if (errors.workType !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
  }

  //***************** GET WORK TYPE ******************* */
  const getWorkType = async(id) => {
    setShow(true);
    setEditWorkType(true);
    try {
    const response = await props.callApi(API.GET_WORKTYPE + id, '', 'get', null, true);
    if (response.settings.status === 1) {
       let {format, _id} = response.data;
       setWorkType(format);
       setWorkTypeId(_id);
      }
    } catch(error) {
      throw error;
    }
  }

    //**************** EDIT WORK TYPE ******************* */
    const updateWorkTypeHandler = async(id) => {
      setShow(true);
      if(validateForm()){
        let body = {
            format: workType
        };

        try{
            const response = await props.callApi(API.UPDATE_WORKTYPE + id, body, 'PUT', null, true);
            console.log("response update", response);
            if (response.settings.status === 1) {
              toast.success('WorkType updated successfully',{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShow(false);
              getAllWorkType();
            } else {
              common.showErrorMessage(response.settings.message)
            }
        } catch(error) {
          throw(error)
        }
      }
    }

    //************* DELETE WORK TYPE ********************/
    const  deleteWorkTypeHandler  = async (bId) => {
      var delArr = multipleDelete;
      delArr = _.compact(delArr)
      swal({
        title: "Are you sure, you want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { ids: delArr }
          }
          else {
            body = { ids: [bId] }
          }
          const response = await props.callApi(API.DELETE_WORKTYPE, body, 'post', null, true);
          if (response.settings.status === 1) {
            toast.success('workType deleted successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAllWorkType()
          }
        }
      });
    }
    
    //************ ADD WORK TYPE ***************** */
    const addWorkTypeHandler = async(e) => {
      setShow(true);
      e.preventDefault();
      if(validateForm()){

        let body = { 
          format: workType
        };
        console.log("body", body);
        try{
            const response = await props.callApi(API.ADD_WORKTYPE, body, 'post', null, true);
            console.log("response", response)
            if (response.settings.status === 1) {
              toast.success('WorkType added successfully',{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShow(false)
            } else {
              toast.error(response.settings.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              setShow(false);
            }
            getAllWorkType();
        } catch(error) {
          throw(error)
        }
      }
    }

    const workTypeStatusHandler = async (status, id) => {
      setCategoryStatus(current => !current);

      const response = await props.callApi(API.WORKTYPE_STATUS + id, '', 'GET', null, true);
      if(response.settings.status === 1) {
        toast.success('Status updated  successfully',{
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllWorkType();
      }
    }

    const modelCloseBtn = () => {
      setShow(false);
      setErrors("");
      setWorkType("");
      setWorkTypeId("");
      setWorkId("");
    }

    const modalAddWorkType = () => {
      setShow(true); 
      setWorkTypeId("");
      setWorkType("");
      setWorkId("");
    }

    const addUpdateWorkType = (e) => {
      workId ? updateWorkTypeHandler(workId) :  addWorkTypeHandler(e);
    }

    //************** SEARCH FIELD HANDLER *************** */
    const searchField = async() => {

      let body = {
        page,
        pagesize,
        searchText: searchText && searchText.length > 1 ? searchText : ""
      };
  
      try {
        const response = await props.callApi(API.LIST_WORKTYPE, body, 'POST', null, true);
        let {data, settings} = response;
        if (settings.status === 1) {
          setWorkTypeListing(data.listing);
          setTotal(data.total);
        } else {
          toast.error(settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        throw(error);
      }
    }

    //************** CLEAR SEARCH BOX *************** */
    const clearSearchBox = () => {
      setSearchText("");
      getAllWorkType();
    }

    const CategoryPagination = (page, pageSize) => {
      setPage(page);
      setPagesize(pageSize);
    }

    return ( 
      <Home>
        <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">Master Data</li>
                  <li className="breadcrumb-item active" aria-current="page">Work Type</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* filterbar buttons */}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder="search"
                            value = {searchText} onChange={(evt) => {searchField(); setSearchText(evt.target.value)}}
                            />
                              {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearSearchBox} ><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                        <Button onClick={modalAddWorkType}  id="addNewCategory" type="button" className="btn glow-primary btn-primary">
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">Add New WorkType</span>
                        </Button>
                    </div>
                   
                    {/* filter bar buttons end */}
                    {/* datatable start */}
                    <Modal show={show} onHide={modelCloseBtn} >
                        <Modal.Header closeButton>
                          <div class="d-flex align-items-center">
                              <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                              <h5 class="modal-title" id="workType">{workId ? "Update WorkType" : "Add WorkType"}</h5>
                          </div>
                        </Modal.Header>
                        <Modal.Body closeButton>
                          <div class="notification-form">
                              <div class="row">
                                  <div class="col-md-12">
                                    <div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="Title">Enter Work Type<sup className="text-danger">*</sup></label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="workType" 
                                        name="workType"
                                        placeholder="Work Type" 
                                        value={workType}
                                        onChange={(e) => {setWorkType(e.target.value); errors=Object.assign(errors, { workType: "" }); setErrors(errors);}}
                                        />
                                        <div className="text-danger d-block">{errors.workType}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div class="modal-btn text-center">
                                        <Link onClick={e => addUpdateWorkType(e)} class="btn btn-primary"> {workId ? "Update" : "Add"} </Link>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="text-center">
                                      <Button  id="uploadCSV" type="button" className="btn glow-primary btn-primary">
                                          <i className="bx bx-user-plus d-lg-none" />
                                          <span className="d-none d-sm-none d-lg-inline-block">Upload Via CSV</span>
                                      </Button>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal> 
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          <th className="all"><b>Work Type</b></th>
                          <th className="all"><b>Status</b></th> 
                          <th className="all"><b>Action</b></th>
                        </tr>
                      </thead>
                      <tbody>
                         {
                             loading ? 
                             <tr className="text-center text-danger not-found-txt">
                               <td colSpan="5"><Loader/>
                               </td>
                             </tr>
                            :
                            (workTypeListing && workTypeListing.length !== 0) ?
                            workTypeListing && Array.isArray(workTypeListing) && workTypeListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    <td>{item.format}</td>
                                    <td>
                                      <div className="custom-control custom-switch light">
                                          <input type="checkbox" className="custom-control-input"
                                              id={item._id} 
                                              checked={item.status ? item.status : false} 
                                              onChange={() => {item.status = !item.status; workTypeStatusHandler(item.status, item._id)}}
                                              />
                                          <label className="custom-control-label"
                                              for={item._id}></label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                          <Link className="cursor-pointer mr-3" ><i class='bx bx-edit' onClick={() => {getWorkType(item._id); setWorkId(item._id)}}/></Link>
                                          
                                          <Link className="cursor-pointer mr-3" ><i className="bx bx-trash-alt" onClick={() => deleteWorkTypeHandler(item._id)}/></Link>   
                                      </div>
                                    </td>
                                </tr>
                              )
                            }) :
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="5">
                              <h6 className="text-center text-danger not-found-txt" colSpan="5">No Records found!</h6>
                              </td>
                            </tr>
                          }
                      </tbody>
                    </table>
                    {/* <div className="mt-3">
                      <Pagination  
                      pageSize={pagesize} 
                      current={page} 
                      total={total} 
                      showPrevNextJumpers={false} 
                      onChange={CategoryPagination}
                      />
                    </div> */}
                    {/* datatable ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
        {/* Bundle Scripts */}
      </div>
      </Home>
     );
  }

  const mapStateToProps = state => ({
    admindata: state.admin.adminData,
  });

  export default connect(mapStateToProps, { callApi })(WorkType);