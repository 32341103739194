// module.exports = {
//     API_URL: "http://commonapi.node.indianic.com/api",
//     IMG_URL: "http://commonapi.node.indianic.com/public/upload/images/",
//     PORT: '4019'
//   };  

  module.exports = {
    API_URL: process.env.REACT_APP_API_URL,
    IMG_URL: process.env.REACT_APP_IMG_URL,
    PORT: process.env.PORT
  };  