import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction';
import { isEmpty } from "lodash";
import Select from "react-select";
import common from '../../actions/common';
import {Link, withRouter} from "react-router-dom"
import errorMessages from '../../actions/errorMessages';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import API from '../../actions/API';

function EditTutor(props){

    const [mobile, setMobile] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [countryCode, setCountryCode] = useState('');
    let [disablePhoneinput, setdisablePhoneinput] = useState(false);
    const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    let [errors, setErrors] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [departmentList, setDepartmentList] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesize, setPagesize] = useState(50);
    const [deptOptions, setDeptOptions] = useState([]);
    const [shiftSlot, setShiftSlot] = useState('');
    const [shiftSlotOptions, setShiftSlotOptions] = useState( [
      { value: '08:00 - 16:00', label: '08:00 - 16:00' },
      { value: '16:00 - 00:00', label: '16:00 - 00:00' },
      { value: '00:00 - 08:00', label: '00:00 - 08:00' }
    ]);
    const [password, setPassword] = useState('');
    const [description, setDescription] = useState('');
    const [selectDays, setSelectDays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tutorId, setTutorId] = useState(props.match.params.id);
    const [tempArr,setTempArr] = useState(["mon", "tue", "wed", "thu", "fri", "sat", "sun"]);

    useEffect(() => {
      getDepartMentLists();
      getTutorDetails(tutorId)
    }, [tutorId]);

  //*************** Get Tutor Details ****************/
  const getTutorDetails = async (id) => {
    try {
      const response = await props.callApi(API.GET_TUTOR + id, '', 'get', null, true);
      let {data, settings} = response;
      if (settings.status === 1) {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setCountryCode(data.countryCode);
        setMobile(`${data.countryCode}-${data.mobileNumber}`);
        setMobileNo(data.mobileNumber);
        setSelectDays(data.availability.map(el => el.day));
        setDepartment(data.departments.map((dep, i) => {
          return ({value: dep._id, label: dep.name})
        }));
        setShiftSlot({
          value: `${(data.availability[0].from)/60}:00 - ${(data.availability[0].until)/60}:00`, 
          label: `${(data.availability[0].from)/60}:00 - ${(data.availability[0].until)/60}:00`
        });
        // setTempDays(data.availability.map(el => el.days))
        setDescription(data.description);
        setLoading(true);
      }
    } catch (error) {
      throw error;
    }
  }

  //******** GET DEPARTMENTS LIST *************** */
  const getDepartMentLists = async() => {
    let body = {
      page, 
      pagesize,  
    };
    let options = [];
    try {
    const response = await props.callApi(API.LIST_DEPARTMENT, body, 'post', null, true);
      if (response.settings.status === 1) {
        for (let i = 0; i< response.data.listing.length; i++) {
          options.push({
            value: response.data.listing[i]._id,
            label: response.data.listing[i].name
          })
        }
        setDeptOptions(options);          
      } 
    } catch(error) {
      throw (error)
    }
  } 

   //*************  validate Form ******************/
  const  validateForm = () => {
    let isFormValid = true;

    let errors = { firstName: "", lastName: "", email:"", phoneNo: "",mobileNo:"", description: "", department: "", shiftSlot: "", mobileNo: ""};
    if (!firstName) errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
    else errors.firstName = '';

    if (!lastName) errors.lastName = errorMessages.PROVIDE_LAST_NAME;
    else errors.lastName = '';

    if (!mobileNo.trim()) errors.mobileNo = errorMessages.PROVIDE_MOBILE_NUMBER;
    else if (mobileNo.length !== 10)  errors.mobileNo = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
    else errors.mobileNo = '';

    if (!(email.trim())) errors.email = errorMessages.PROVIDE_EMAIL;
    else if(!common.validateEmail(email)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
    else errors.email= '';

    if (!description) errors.description = errorMessages.PROVIDE_DESCRIPTION;
    else errors.description = '';

    if (!shiftSlot) errors.shiftSlot = errorMessages.PROVIDE_SHIFTSLOT;
    else errors.shiftSlot = '';

    if (!department) errors.department = errorMessages.PROVIDE_DEPARTMENT;
    else errors.department = '';

  
    if (errors.firstName!== '' || errors.lastName!== '' || errors.email!== ''|| errors.description!== '' || errors.department !== '' || errors.mobileNo !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);

    return isFormValid
  }

  const handleOnChanges = (value, data) => {
    setdisablePhoneinput(false)
    let dialnums = data.dialCode;
    let mobilenums = value.slice(data.dialCode.length);
		setCountryCode(`+${dialnums}`)
    setMobileNo(mobilenums)
		if(mobilenums.length >=10){
			setdisablePhoneinput(true)
		}
  }
    
// ********************* Add Details ******************
 const  updateTutor = async (id) => {
  if (validateForm()) {
    
    let slot = [];
    let from = parseInt(shiftSlot.label.split("-")[0].trim().split(":")[0], 10)*60;
    let until = parseInt(shiftSlot.label.split("-")[1].trim().split(":")[0], 10)*60;

    for (let i = 0; i <selectDays.length; i++){
      slot.push({
        from: from,
        until: until,
        day: selectDays[i]
      })
    }

    let deptLists = [];

    for (let i = 0; i < department.length; i++) {
      deptLists.push(department[i].value);
    }

    var body = { 
      email,
      firstName,
      lastName,
      countryCode : countryCode,
      mobileNumber: mobileNo,
      departments :deptLists,
      availability : slot,
      description,
      password
    }

    console.log("body", body);
    try {
      const response = await props.callApi(API.UPDATE_TUTOR + id, body, 'PUT', null, true);
      if (response.settings.status === 1) {
        toast.success('Tutor updated successfully',{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        props.history.push(`/viewTutor/${tutorId}`)
      } else {
        toast.error(response.settings.message,{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } catch (error) {
      throw error;
    }
  }
 }

  const statusChangeHandler = (day) => {
    selectDays.includes(day) ? 
    setSelectDays(selectDays.filter(d => d !== day))
    : 
    setSelectDays([...selectDays, day])
  }

   // *********************

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Tutor</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listTutors')}>List All Tutors</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link onClick={() => props.history.push(`/viewtutor/${tutorId}`)}>View Tutor</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Tutor</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">Edit Details</h5>
                    </div>
                  </div>
                  <form action="#">
                    {
                      loading ?
                        <div className="row">
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="firstName">First Name<sup className="text-danger">*</sup></label>
                          <input 
                          type="text" 
                          className="form-control" 
                          id="firstName"
                          name="firstName" 
                          placeholder="First name" 
                          value={firstName}
                          onChange={(e) => {setFirstName(e.target.value); errors=Object.assign(errors, { firstName: "" }); setErrors(errors)}} 
                          />
                          <span className='error-msg'  style={{color: "red"}} >{errors.firstName}</span>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="lastName">Last name<sup className="text-danger">*</sup></label>
                          <input 
                          type="text" 
                          className="form-control" 
                          id="lastName" 
                          name="lastName"
                          placeholder="Last name" 
                          value={lastName}
                          onChange={(e) => {setLastName(e.target.value); errors=Object.assign(errors, { lastName: "" }); setErrors(errors)}} 
                          />
                          <span className='error-msg'  style={{color: "red"}} >{errors.lastName}</span>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label>
                          <input 
                          type="text" 
                          className="form-control" 
                          id="email"
                          name="email" 
                          placeholder="Email" 
                          value={email}
                          onChange={(e) => {setEmail(e.target.value); errors=Object.assign(errors, { email: "" }); setErrors(errors)}} 
                          />
                          <span className='error-msg'  style={{color: "red"}} >{errors.email}</span>
                        </div>
                        { 
                            <div className="form-group col-md-4 mb-md-5 mb-3">
                                <label htmlFor="Email">Phone number<sup className="text-danger">*</sup></label>
                                <div className="w-100">
                                <PhoneInput
                                    className="form-control w-100"
                                    name="phone"
                                    country={countryCode === "+1" ? 'us' : 'in'}
                                    onlyCountries = {['us','in']}
                                    // excludeCountries={'in'}
                                    masks={''}
                                    countryCodeEditable={false}
                                    value={mobile}
                                    onChange={handleOnChanges}
                                />
                                </div>
                                <span className='error-msg'  style={{color: "red"}} >{errors.mobileNo}</span>
                            </div>   
                          }
                        <div className="align-items-center col-md-4 mb-md-5 mb-3 user-details">
                          <label htmlFor="department">Department<sup className="text-danger">*</sup></label>
                            <Select className="selectpicker w-100"
                              isMulti
                              id="department"
                              name="department"
                              value={department}
                              options={deptOptions}
                              onChange={(e) => {setDepartment(e); errors = Object.assign(errors, {department: ''}); setErrors(errors);}}
                            >
                            </Select>
                          <span className='error-msg'  style={{color: "red"}} >{errors.department}</span>
                        </div>
                        <div className="align-items-center col-md-4 mb-md-5 mb-3 user-details">
                          <label htmlFor="shiftSlot">Shift Slot<sup className="text-danger">*</sup></label>
                          <Select className="selectpicker w-100"
                          id="shiftSlot"
                          name="shiftSlot"
                          value={shiftSlot}
                          options={shiftSlotOptions}
                          onChange={(e) => { setShiftSlot(e); errors = Object.assign(errors, {shiftSlot: ''}); setErrors(errors);}}>
                          </Select>
                          <span className='error-msg'  style={{color: "red"}} >{errors.shiftSlot}</span>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <div><label>Select Days<sup className="text-danger">*</sup></label></div> 
                          <div className="d-inline-flex">
                          {
                            
                            loading ? 
                              selectDays ? tempArr.map((day, i) => {
                                return (
                                  <>
                                  <span className="form-check mr-2" key={day}>
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        value={day}
                                        id={day}
                                        checked={selectDays.includes(day)}
                                        onChange={(e) => statusChangeHandler(day)}
                                      />
                                    <label className="form-check-label" htmlFor={day}>
                                      {day}
                                    </label>
                                  </span>
                                  </>
                                )
                              }) : null : "loading"
                          }
                          </div>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="password">Description<sup className="text-danger">*</sup></label>
                          <input 
                          type="textarea" 
                          className="form-control" 
                          id="description" 
                          name="description"
                          placeholder="Description" 
                          value={description}
                          onChange={(e) => {setDescription(e.target.value); errors=Object.assign(errors, { description: "" }); setErrors(errors)}} 
                          />
                          <span className='error-msg'  style={{color: "red"}} >{errors.description}</span>
                        </div>
                      </div> : <div className="each-detail text-center"><h4>Loading</h4></div> 
                    }
                  </form>
                  <div className="text-right mt-md-5 mt-3">
                    <button className="btn btn-primary mr-2" onClick={() => updateTutor(tutorId)}>Save</button>
                    <button className="btn btn-primary btn-glow" onClick={() => props.history.push('/listTutors')}>Cancel</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(EditTutor);