import React, { useEffect, useState } from 'react';
import Home from '../Home';
import { connect } from 'react-redux';
import { callApi } from '../../actions/commonFunction'; // COMMON FUNCTIONS
import API from '../../actions/API';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import swal from 'sweetalert';
import {useSortng} from '../CommonFeature/Sorting';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ListMentors(props) {


  const [mentorListing, setMentorListing] = useState('');
  const [columnSettingsArr, setColumnSettingsArr] = useState([]);
  const [page, setPage] = useState(1);
  const [sortData,sort, onSort] = useSortng();
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(1);
  const [multipleDelete, setMultipleDelete] = useState([]);
  const [condition, setCondition] = useState('');
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState('');

    useEffect(() => {
        getMentorsList();
        setShow(false);
    }, []);
  
    //*************** GET MENTOR LISTS ***************** */  
    const getMentorsList = async() => {
      let body = {
        page, 
        pagesize
      };
      try {
      const response = await props.callApi(API.LIST_MENTOR, body, 'post', null, true);
        if (response.settings.status === 1) {
          setMentorListing(response.data.listing);
          setColumnSettingsArr(response.data.columnSettings);
          setTotal(response.total);
          setLoading(false);
        } else {
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch(error){
        setLoading(false);
        throw (error)
      }
    }

    // **************** UPDATE MENTOR STATUS ********************
    const mentorStatusHandler = async (status, id) => {
      const response = await props.callApi(API.TUTOR_STATUS + id, '', 'GET', null, true);
      if(response.settings.status === 1) {
        toast.success('Status updated successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(response.settings.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      getMentorsList();
    }

    //**************** DELETE MENTOR **************** */
    const  deleteMentor =  async (id) => {
      try {
        swal({
          title: "Are you sure,You Want To Delete ?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async willDelete => {
          if (willDelete) {
            let body = { ids: [id] }
            const response = await props.callApi(API.DELETE_MENTOR, body, 'post', null, true);
            if (response.settings.status === 1) {
              toast.success('Deleted successfully',{
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(response.settings.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            getMentorsList();
          }
        });
      } catch (error) {
        throw error;
      }
    }

    //************** DOWNLOAD CSV HANDLER ****************/
    const exportCSVHandler = async() => {
      let body = {
        key:  "mentorListing",
        fields: ["userInfo", "email","mobileNumber", "departments", "worktypes","status"]
      }
    
      try {
        const response = await props.callApi(API.EXPORT_CSV, body, 'POST', null, true);
        console.log("export CSV", response);
        window.open(response.data, "_blank");
        if(response.settings.status === 1) {
          toast.success('Mentor Lists downloaded successfully', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        throw error;
      }
    } 
    
    //************** SEARCH FIELD HANDLER *************** */
    const searchField = async() => {

      let body = {
        page,
        pagesize,
        searchText: searchText && searchText.length > 1 ? searchText : ""
      };
  
      try {
        const response = await props.callApi(API.LIST_MENTOR, body, 'POST', null, true);
        let {data, settings} = response;
        if (settings.status === 1) {
          setMentorListing(data.listing);
          setTotal(data.total);
        } else {
          toast.error(settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        throw(error);
      }
    }

    //************** CLEAR SEARCH BOX *************** */
    const clearSearchBox = () => {
      setSearchText("");
      getMentorsList();
    }

    return (
        <Home>
        <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Manage Mentor</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  {/* <li className="breadcrumb-item">Home</li> */}
                  <li className="breadcrumb-item active" aria-current="page">List All Mentors</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                <Button  id="exportCSVData" type="button" className="btn glow-primary btn-primary ml-2 float-right" onClick={exportCSVHandler}>
                  <i className="bx bx-user-plus d-lg-none" />
                  <span className="d-none d-sm-none d-lg-inline-block">Export CSV</span>
                </Button>
                  <div className="grid-content">
                    {/* filterbar buttons */}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder="search"
                            value = {searchText} onChange={(e) => {setSearchText(e.target.value); searchField();}}
                            />
                              {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearSearchBox}><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                        <Button  id="addMentor" type="button" className="btn glow-primary btn-primary" onClick={() => props.history.push('/addMentor')}> 
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">Add Mentor</span>
                        </Button>
                    </div>
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          <th className="all"><b>User Info</b></th>
                          <th className="all"><b>Email</b></th>
                          <th className="all"><b>Mobile</b></th>
                          <th className="all"><b>Department</b></th> 
                          <th className="all"><b>Work Type</b></th> 
                          <th className="all"><b>Status</b></th> 
                          <th className="all"><b>Action</b></th> 
                        </tr>
                      </thead>
                      <tbody>
                         {
                            loading ? 
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="7"><Loader/>
                              </td>
                            </tr>
                            :
                            (mentorListing && mentorListing.length !== 0) ? 
                             mentorListing && Array.isArray(mentorListing) && mentorListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    <td><Link onClick={() => props.history.push(`/viewMentor/${item._id}`)}>{`${item.firstName} ${item.lastName}`}</Link></td>
                                    <td>{item.email}</td>
                                    <td>{`${item.countryCode}-${item.mobileNumber}`}</td>
                                    <td>
                                      {
                                        item.departments.map((dept,index, arr) => {
                                          if (dept !== arr[arr.length-1]) {
                                            return `${dept.name}, `
                                          } else {
                                            return `${dept.name}`
                                          }
                                        })
                                      }
                                    </td>
                                    <td>
                                      {
                                         item.worktypes.map((work,index, arr) => {
                                          if (work !== arr[arr.length-1]) {
                                            return `${work.format}, `
                                          } else {
                                            return `${work.format}`
                                          }
                                        })
                                      }
                                      </td>
                                    <td>
                                      <div className="custom-control custom-switch light">
                                          <input type="checkbox" className="custom-control-input"
                                              id={item._id} 
                                              checked={item.status ? item.status : false} 
                                              onChange={() => {item.status = !item.status; mentorStatusHandler(item.status, item._id)}}
                                           />
                                          <label className="custom-control-label"
                                              for={item._id}></label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                          <Link className="cursor-pointer mr-3" onClick={() => props.history.push(`/editMentor/${item._id}`)}><i class='bx bx-edit'/></Link>
                                          
                                          <Link className="cursor-pointer mr-3" onClick={() => deleteMentor(item._id)}><i className="bx bx-trash-alt"/></Link>   
                                      </div>
                                    </td>
                                </tr>
                              )
                            }) : 
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="7">
                              <h6 className="text-center text-danger not-found-txt" colSpan="7">No Records found!</h6>
                              </td>
                            </tr>
                          }
                      </tbody>
                    </table>
                    {/* <div className="mt-3">
                      <Pagination  
                      pageSize={pagesize} 
                      current={page} 
                      total={total} 
                      // showPrevNextJumpers={false} 
                      onChange={CategoryPagination}
                      />
                    </div> */}
                    {/* datatable ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Home>
    )
}

export default connect(null, { callApi })(ListMentors)
