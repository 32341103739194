import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction';
import {Link} from "react-router-dom"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../actions/API';

function ViewTutor(props){

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState([]);
    const [shiftSlot, setShiftSlot] = useState([]);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [tutorId, setTutorId] = useState(props.match.params.id);
    const [tempArr,setTempArr] = useState(["mon", "tue", "wed", "thu", "fri", "sat", "sun"]);

    useEffect(() => {
      getTutorDetails(tutorId)
    }, [tutorId]);

  //*************** Get Tutor Details ****************/
  const getTutorDetails = async (id) => {
    try {
      const response = await props.callApi(API.GET_TUTOR + id, '', 'get', null, true);
      let {data, settings} = response;
      if (response.settings.status === 1) {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setCountryCode(data.countryCode);
        setMobile(data.mobileNumber);
        setDepartment(data.departments);
        setShiftSlot(data.availability);
        setDescription(data.description);
        setLoading(true);
      } else {
        toast.error(response.settings.message,{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } catch (error) {
      throw error;
    }
  }

  const renderSlot = () => {

    // Easy way for filtering out single slot(static method)
    let singleSlot=[];

    if (shiftSlot && shiftSlot.length) {
      singleSlot.push({from: shiftSlot[0].from, until: shiftSlot[0].until})
      console.log("singleSlot",singleSlot, shiftSlot)
      return (
        singleSlot && singleSlot.length ? 
        <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
      )
    } else {
      return null;
    }


    // proper way for filtering out single slot(if huge records)
   
    // let uniqueSlot, singleSlot;

    // // creating deep clone of shiftSlot
    // let newSlot = JSON.parse(JSON.stringify(shiftSlot));
    
    // // filtering only "from" and "until" from shiftSlot object
    // if (newSlot && newSlot.length) {
    //   newSlot.map((shift,index, arr) => { delete shift._id; delete shift.day}); 
    // }

    // // filtering unique values from object
    // uniqueSlot = new Set(newSlot.map(JSON.stringify));

    // // converting back to array of object
    // singleSlot = Array.from(uniqueSlot).map(JSON.parse);
    // return (
      // singleSlot && singleSlot.length ? 
      // <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
    // )
  }

   // *********************

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Tutor</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listTutors')}>List All Tutors</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">View Tutor</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">View Details</h5>
                    </div>
                  </div>
                  <form action="#">
                    { 
                      loading ? 
                      <div className="row">
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="firstName">First Name<sup className="text-danger">*</sup></label>
                          <div className="each-detail">{firstName}</div>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="lastName">Last name<sup className="text-danger">*</sup></label>
                          <div className="each-detail">{lastName}</div>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="Email">Email ID<sup className="text-danger">*</sup></label>
                          <div className="each-detail">{email}</div>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="Phone Number">Phone number<sup className="text-danger">*</sup></label>
                                <div className="each-detail">
                                {
                                  mobile && countryCode ?
                                      <p>{`${countryCode}- ${mobile}`}</p>
                                    : null
                                      
                                }
                                </div>
                        </div>  
                        <div className="align-items-center col-md-4 mb-md-5 mb-3 user-details">
                          <label htmlFor="department">Department<sup className="text-danger">*</sup></label>
                          <br/>
                          <div className="each-detail">
                            {
                              department && department.length ? 
                              department.map((dept,index, arr) => {
                                if (dept !== arr[arr.length-1]) {
                                  return <span key={index}>{`${dept.name}, `}</span>
                                } else {
                                  return <span key={index}>{`${dept.name}`}</span>
                                }
                              }) : null 
                              
                            }
                          </div>  
                        </div>
                        <div className="align-items-center col-md-4 mb-md-5 mb-3 user-details">
                          <label htmlFor="shiftSlot">Shift Slot<sup className="text-danger">*</sup></label>
                          {
                            <div className="each-detail">
                              { 
                                renderSlot()
                              }
                            </div>
                          }
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <div><label>Select Days<sup className="text-danger">*</sup></label></div>
                          <div class="d-inline-flex">
                              {
                                
                                shiftSlot ? tempArr.map((el, i) => {
                                  const day = shiftSlot.find(obj => obj.day === el);
                                  const available = !!day;
                                  return (
                                    <>
                                    <div className="form-check mr-2">
                                      <input 
                                          key={i}
                                          className="form-check-input" 
                                          type="checkbox" 
                                          value={el}
                                          id={el}
                                          checked={available}
                                        />
                                      <label className="form-check-label" htmlFor={el} >
                                        {el}
                                    </label>
                                    </div>
                                    </>
                                  )
                                }) : null
                              }          
                          </div>
                        </div>
                        <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="description">Description<sup className="text-danger">*</sup></label>
                          <div className="each-detail">{description}</div>
                        </div>                    
                      </div> : <div className="each-detail text-center"><h4>Loading</h4></div> 
                    }
                  </form>
                  <div className="text-right mt-md-5 mt-3">
                    <button className="btn btn-primary mr-2" onClick={() => props.history.push(`/editTutor/${tutorId}`)}>Edit</button>
                    <button className="btn btn-primary btn-glow" onClick={() => props.history.push('/listTutors')}>Cancel</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(ViewTutor);