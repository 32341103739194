import React, { Component } from 'react';
// import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AdminLogin from './pages/AdminLogin'
import AdminRegister from './pages/AdminRegister'
import Dashboard from './pages/Dashboard';
import passwordPage from './pages/passwordPage'
import Profile from './pages/Profile'
import ResetPassword from './pages/ResetPassword'
import ChangePassword from './pages/ChangePassword'

import ForgotPassword from './pages/ForgotPassword'

import UsersList from './pages/UserManagement/UsersList';
import Test from './pages/UserManagement/Test';

import UserProfile from './pages/UserManagement/UserProfile';
import ViewuserDetails from './pages/UserManagement/ViewuserDetails';
import ViewadminuserDetails from './pages/UserManagement/ViewadminuserDetails';


import AdminUsers from './pages/UserManagement/AdminUsers';
import AddUser from './pages/UserManagement/AddUser'

import StaticPage from './pages/ContentManagement/StaticPages';
import CmsListing from './pages/ContentManagement/CmsListing';
import StaticCMS from './pages/ContentManagement/StaticCMS';
import faq from './pages/ContentManagement/faq';
import BlogCategory from './pages/ContentManagement/BlogCategoryListing';
import BlogPreview from './pages/ContentManagement/BlogPreview';
import StaticPagePreview from './pages/ContentManagement/StaticPagePreview';
import EachStatic from './pages/ContentManagement/eachStatic';
import AddBlog from './pages/ContentManagement/AddBlog';
import EditBlog from './pages/ContentManagement/EditBlog';
import EditPreview from './pages/ContentManagement/EditPreview';

import State from './pages/MasterManagement/State';
import ImportFiles from './pages/MasterManagement/ImportFiles';
import MasterManagement from './pages/MasterManagement/MasterManagement';

import HomePage from './pages/ContentManagement/HomePage';
import Offlinepage from './components/Offlinepage'
import { Offline, Online } from "react-detect-offline";
import PageNotFound from './pages/PageNotFound';
import Sidebar from './components/SideBar';
import "./scss/styles.scss"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//***************** SUPER ADMIN **************** */
import EmailVerified from './pages/SEmailVerified';

import ListUsers from './pages/UserManagement/ListUsers';
import ViewUsers from './pages/UserManagement/ViewUsers';
import ListMentors from './pages/UserManagement/ListMentors';
import AddMentor from './pages/UserManagement/AddMentor';
import ViewMentor from './pages/UserManagement/ViewMentor';
import EditMentor from './pages/UserManagement/EditMentor';
import ListTutors from './pages/UserManagement/ListTutors';
import AddTutor from './pages/UserManagement/AddTutor';
import ViewTutor from './pages/UserManagement/ViewTutor';
import EditTutor from './pages/UserManagement/EditTutor';

import Departments from './pages/MasterManagement/Departments';
import Subjects from './pages/MasterManagement/Subjects';
import WorkType from './pages/MasterManagement/WorkType';

import ListTasks from './pages/ReportManagement/ListTasks';
import ViewTask from './pages/ReportManagement/ViewTask';
// import ChatDetails from './pages/ReportManagement/ChatDetails';

function App() {
  const Authorization = () => {
    return localStorage.getItem('accessToken') ? true : false
  }

  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route {...rest} render={(props) => (
      Authorization() ?
        <Component1 {...props} />
        : <Redirect to='/' />
    )} />
  );

  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route {...rest} render={(props) => (
      Authorization()
        ? <Redirect to='/dashboard' />
        : <Component2 {...props} />
    )} />
  );
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route {...rest} render={(props) => (
      Authorization()
        ? <Redirect to='/dashboard' />
        : <Component3 {...props} />
    )} />
  );
  return (
    <Provider store={store}>
      {/* <Theme/> */}
      <Offline>
        <Offlinepage />
      </Offline>
      <Online>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Sidebar />
            <div style={{ textAlign: "center" }}><ToastContainer /></div>
            {/* <AppMenu /> */}

            <Switch>

                {/*  **************{All Super Admin}**************** */}
              <Route exact path="/emailVerify" component={EmailVerified}/>

              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path="/listUsers" component={ListUsers}/>
              <Route exact path="/listUsers/viewUsers/:id" component={ViewUsers}/>

              <Route exact path="/listMentors" component={ListMentors}/>
              <Route exact path="/addMentor" component={AddMentor}/>
              <Route exact path="/viewMentor/:id" component={ViewMentor}/>
              <Route exact path="/editMentor/:id" component={EditMentor}/>

              <Route exact path="/listTutors" component={ListTutors}/>
              <Route exact path="/addTutor" component={AddTutor}/>
              <Route exact path="/viewTutor/:id" component={ViewTutor}/>
              <Route exact path="/editTutor/:id" component={EditTutor}/>

              <Route exact path="/listDepartments" component={Departments}/>
              <Route exact path="/listSubjects" component={Subjects}/>
              <Route exact path="/listWorkType" component={WorkType}/>

              <Route exact path="/listTasks" component={ListTasks}/>
              <Route exact path="/listTasks/viewTask/:id" component={ViewTask}/>
              {/* <Route exact path="/listTasks/viewTask/chatDetails" component={ChatDetails}/> */}

              <LoginCheckRoute exact path='/' component={AdminLogin} />
              <Route exact path='/forgotpassword' component={ForgotPassword} />
              <ResetPasswordCheckRoute exact path='/resetpassword' component={ResetPassword} />
              
              <Route exact path='/register' component={AdminRegister} />
              <PrivateRoute exact path='/password' component={passwordPage} />
              <Route exact path='/changepassword' component={ChangePassword} />
              
              <PrivateRoute exact path='/dashboard' component={Dashboard} />

              <PrivateRoute exact path='/usermanagement' component={UsersList} />
              <Route exact path='/test' component={Test} />

              <PrivateRoute exact path='/adminusers' component={AdminUsers} />
              <PrivateRoute exact path='/userProfile/:slug' component={UserProfile} />
              <PrivateRoute exact path='/userdetails/:slug' component={ViewuserDetails} />
              <PrivateRoute exact path='/adminuserdetails/:slug' component={ViewadminuserDetails} />


              <PrivateRoute exact path='/profile' component={Profile} />
              <PrivateRoute exact path='/adduser' component={AddUser} />
              <PrivateRoute exact path='/editAdminUser' component={AddUser} />

              <PrivateRoute exact path='/editStatic/eachStatic/:slug' component={EachStatic} />
              <PrivateRoute exact path='/staticPreview/:slug' component={StaticPagePreview} />
              <PrivateRoute exact path='/editStatic/:slug' component={StaticCMS} />
              <PrivateRoute exact path='/staticPage' component={StaticPage} />
              <PrivateRoute exact path='/blogListing' component={CmsListing} />
              <PrivateRoute exact path='/addBlog' component={AddBlog} />
              <PrivateRoute exact path='/editBlog/:slug' component={EditBlog} />
              <PrivateRoute exact path='/blogPreview/:slug' component={BlogPreview} />
              <PrivateRoute exact path='/editBlog/editPreview/:slug' component={EditPreview} />
              <PrivateRoute exact path='/faq' component={faq} />
              <PrivateRoute exact path='/blogCategory' component={BlogCategory} />

              <PrivateRoute exact path='/home' component={HomePage} />

              <PrivateRoute exact path='/state' component={State} />
              <PrivateRoute exact path='/import' component={ImportFiles} />
              <PrivateRoute exact path='/master' component={MasterManagement} />

              <PrivateRoute path="/*" component={PageNotFound} />

            </Switch>
          </Router>
        </PersistGate>
      </Online>
    </Provider>

  );
}


export default App;
