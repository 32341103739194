import React, { useState,useEffect } from 'react'
import Home from '../Home'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom";
import { Select, Spin } from 'antd';
import { BeatLoader } from 'react-spinners'
import common from '../../actions/common';
import swal from 'sweetalert'
import $ from 'jquery';
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import * as language from "../../translations/index";
import moment from 'moment';
import momentTimeZone from "moment-timezone";
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { IMG_URL } from '../../config/configs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
$.DataTable = require('datatables.net');
require('datatables.net-responsive');
var {Option} = Select;
function StaticPage (props){

      const [page, setPage] = useState(1);
      const [pagesize, setPagesize] = useState(10);
      const [total, setTotal] = useState(1);
      const [length, setLength] = useState(1);
      const [blogListing, setBlogListing] = useState([]);
      const [categoryStatus, setCategoryStatus] = useState(false);
      const [condition, setCondition] = useState('');
      const [filterObj,setFilterObj] = useState([{ key: "", type: "",  value: { startDate: '', endDate: '' } }]);
      const [columnSettingsArr, setColumnSettingsArr] = useState([])
      const [sortData,sort, onSort] = useSortng();
      const [getFilterValues, setGetFilterValues] = useState([]);
      const [searchText, setSearchText] = useState('');
      const [loading, setLoading] = useState(true);
      const [dateFormatUI, setDateFormatUI] = useState('');
      const [timeFormatUI, setTimeFormatUI] = useState('');
      const [timeZoneUI, setTimeZoneUI] = useState('');
      const [allChecked, setAllChecked] = useState(false);
      const [cmsPagesAccess, setCmsPagesAccess] = useState(1);
    
    //  ****************
useEffect(() => {
  getAllBlogs();
  (async () => {
    await getAllBlogs();
  })();
}, [page, pagesize]);

const getAllBlogs = async(filterObj) => {
  var body = {page, pagesize, sort, columnKey:"blogListing", filter:filterObj ? filterObj:"" ,condition:condition}
    try {
    const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogListing(response.data.listing);
        console.log("blog Lists", blogListing)
        setColumnSettingsArr(response.data.columnSettings);
        setGetFilterValues(response.data.filterSettings);
        setTotal(response.total);
        setLength(response.data.listing.length);
        setLoading(false);
      } 
    } catch(error){
      setLoading(false);
      throw (error)
    }
}

//**************** delete cms **************** */
 const  deleteCms =  async (uid) =>{
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          var body = { ids: [uid] }
          const response = await props.callApi(API.DELETE_BLOG, body, 'post', null, true);
          if (response.status === 1) {
            toast.success('Deleted successfully',{
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAllBlogs();
          }
        }
      });
  }

  const clearInput = () => {
    setSearchText("");
    getAllBlogs();
  }

  const searchField = async() => {
    let body = {
      page,
      pagesize,
      searchText: searchText && searchText.length > 1 ? searchText : ""
    };

    try {
      const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogListing(response.data.listing);
        setTotal(response.data.total);
      } 
    } catch (error) {
      throw(error);
    }
  }

  
  const blogStatusHandler = async (status, id) => {
   
    let body = {
      ids: [id],
      status: status
    };

    const response = await props.callApi(API.CHANGE_BLOG_STATUS, body, 'post', null, true);
    if(response.status === 1) {
      toast.success('Status updated successfully',{
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getAllBlogs();
    }
  }

  const blogPagination = (page, pageSize) => {
    setPage(page);
    setPagesize(pageSize);
  }

  const CustomStatus = ({row}) => (
    <div className="custom-control custom-switch light">
      <input type="checkbox" className="custom-control-input"
          id={row._id} 
          checked={row.status ? true : false}  
          onChange={() => { row.status = !row.status; blogStatusHandler(row.status, row._id) }}
          />
      <label className="custom-control-label"
          for={row._id}></label>
    </div>
  )

  // const columns = [
  //   {
  //     name: 'PageTitle',
  //     selector: 'pageTitle',
  //     sortable: true
  //   },
  //   {
  //     name: 'BlogTitle',
  //     selector: 'blogTitle',
  //     sortable: true
  //   },
  //   {
  //     name: 'Status',
  //     cell: (row) => <CustomStatus row={row}/>
  //   },
  //   {
  //     name: 'UpdatedAt',
  //     selector: 'updatedAt',
  //     sortable: true,
  //     cell: (row) => <span className="dob-date d-block">{row.updatedAt ? getDateTimeFormat(row.updatedAt)  : null}</span>
  //   },
  //   {
  //     name: "Action",
  //     selector: 'action',
  //     cell: (row) =>  <div className="d-flex">
  //                         <Link className="cursor-pointer mr-3" onClick={() => { props.history.push(`/editStatic/${row._id}`);}}><i class='bx bx-edit'/></Link>
  //                         <Link className="cursor-pointer mr-3"  onClick={() => deleteCms(row._id)} ><i className="bx bx-trash-alt" /></Link>
  //                         <Link  className="cursor-pointer mr-3" onClick={() => props.history.push(`/staticPreview/${row._id}`)}><i className="bx bx-show-alt" /></Link> 
  //                     </div>
  //   }
    
  // ]

  useEffect(() => {
    (async () => {
      try {
        let body = {
          loginId : localStorage.getItem('accessToken')
        }

        const response = await props.callApi(API.GET_DATE_SETTINGS, body, 'get', null, true);
        if (response.status === 1) {
          let { dateFormat, timeFormat, timeZone } = response.data;
          setDateFormatUI(dateFormat);
          setTimeFormatUI(timeFormat);
          setTimeZoneUI(timeZone);
        } 
      } catch (error) {
        throw error;
      }
    })();
  }, [])

  //****** Date Time TimeZone Conversion based on setting in General Settings *********** */
  const getDateTimeFormat = (inputDatetime) => {
    let mergedDate;
    let timeZone = timeZoneUI.split(" ")[1];
    if(timeFormatUI === "24 Hours") {
      mergedDate = moment(inputDatetime).tz(`${timeZone}`).format(`${dateFormatUI} HH:mm `);
    } else {
      mergedDate = moment(inputDatetime).tz(`${timeZone}`).format(`${dateFormatUI} h:mm A `);
    }
    return mergedDate;
  }

  //************ Multiselect partially implemented ************** */
  const handleCheckbox = (e) => {
    let list = blogListing;
    
    let checkedALL = allChecked;
    if (e.target.value === "checkAll") {
      list.forEach(item => {
        item.isChecked = e.target.checked;
        checkedALL = e.target.checked
        // setAllChecked(e.target.checked)
      });
    }
    setBlogListing(list);
    setAllChecked(checkedALL)
  }

  const translations = language[props.language !== undefined ? props.language :"en"];
//**************** */
    return (
      <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Content</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">Content</li>
                  <li className="breadcrumb-item active" aria-current="page">Static Pages</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* filterbar buttons */}
                        
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder={`${translations.ContentManagement.CMSListing.search}`}
                            value= {searchText} onChange={(evt) => {searchField(); setSearchText(evt.target.value);}}
                            />
                             {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearInput} ><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                    </div>
                      <div className="row mb-2">
                        <div className="col-sm-12">
                         
                        </div>
                      </div> 
          
                            {console.log("blog Listing", blogListing, typeof blogListing)}
                
                    {/* filter bar buttons end */}
                    {/* datatable start */}
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          {/* <th className="checkbox-table">
                            <div className='custom-checkbox'>
                              <label>
                                <input type="checkbox"
                                  value="checkAll"
                                  checked={allChecked}
                                  onChange={handleCheckbox} 
                                />
                                <span></span>
                              </label>
                            </div>
                          </th> */}
                          <th className="all">Page Title </th>
                          <th className="all">Description</th>
                          <th className="all">Updated</th>
                          <th className="all">{translations.ContentManagement.CMSListing.status}</th>
                          <th className="all">{translations.ContentManagement.CMSListing.action}</th>
                        </tr>
                      </thead>
                      <tbody>
                         {
                            (blogListing && blogListing.length === 0)
                                ?
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                { loading  ?  <Loader/>    :null }

                                {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">{translations.ContentManagement.CMSListing.noRecord}</h6> : null}
                              </td>
                            </tr>
                            :
                            blogListing && Array.isArray(blogListing) && blogListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    {/* <td>
                                      <div className='custom-checkbox'>
                                        <label>
                                          <input key={item._id} type="checkbox" name={item.pageTitle} value={item.pageTitle} checked={item.isChecked} onChange={handleCheckbox} />
                                          <span />
                                        </label>
                                      </div>
                                    </td> */}
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span>{item.pageTitle.slice(0,30)}...</span>
                                        </div>
                                    </td>
                                    <td><span> {item.metaDescription.slice(0,170)}...</span></td>
                                    <td>
                                    <span className="dob-date d-block">{item.updatedAt ? getDateTimeFormat(item.updatedAt)  : null}</span>
                                    </td>
                                    <td>
                                    <div className="custom-control custom-switch light">
                                        <input type="checkbox" className="custom-control-input"
                                            id={item._id} 
                                            checked={item.status ? true : false}  
                                            onChange={() => { item.status = !item.status; blogStatusHandler(item.status, item._id) }}
                                            />
                                        <label className="custom-control-label"
                                            for={item._id}></label>
                                    </div>
                                    </td>
                                    <td>
                                    <div className="d-flex">
                                        <Link className="cursor-pointer mr-3" onClick={() => { props.history.push(`/editStatic/${item._id}`);}}><i class='bx bx-edit'/></Link>
                                        <Link className="cursor-pointer mr-3"  onClick={() => deleteCms(item._id)} ><i className="bx bx-trash-alt" /></Link>
                                        <Link  className="cursor-pointer mr-3" onClick={() => props.history.push(`/staticPreview/${item._id}`)}><i className="bx bx-show-alt" /></Link> 
                                    </div>
                                    </td>
                                </tr>
                              )
                            })
                          }
                      </tbody>
                    </table>
                    {
                      (blogListing && blogListing.length === 0) ? <div>  </div> :
                        <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center justify-content-between">
                          <div>
                            <label className="mr-2">Showing</label>
                            <Select placeholder={<b>10 Items Per Page</b>} optionFilterProp="children" value={length}
                              onChange={(value) => setPagesize(value)}  >
                              <Option value={2}>2</Option>
                              <Option value={5}>5</Option>
                              <Option value={10}>10</Option>
                              <Option value={15}>15</Option>
                              <Option value={50}>50</Option>
                            </Select>
                            <label className="ml-2">Out of {total} Users</label>
                          </div>
                          <div className="pagination-list mt-2 mt-sm-3">
                            <Pagination className="ant-pagination" pageSize={pagesize} current={page} total={total} onChange={blogPagination} />
                          </div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
        {/* Bundle Scripts */}
      </div>
      </Home>
    )
  }

const mapStateToProps = state => ({
  CmsPagesAccess: state.admin.adminData.rolePermission.cmsPagesAccess,
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(StaticPage);
