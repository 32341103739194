import React, { useEffect, useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { callApi, changeResize, changeTheme } from '../actions/commonFunction'; // COMMON FUNCTIONS
import common from '../actions/common' // Validations
import errorMessages from '../actions/errorMessages'; // ERROR MESSAGE
import API from '../actions/API';
import Theme from './Theme';
import { API_URL } from '../config/configs'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login(props) {
  const [emailId, setEmailId] = useState(localStorage.getItem("emailId"));
  const [password, setPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  let [errors, setErrors] = useState({ emailId: "", password: "" });
  let [loading, setLoading] = useState(false);
  let [hidden, setHidden] = useState(true)

  
  //******************** FORM VALIDATION ******************** */
  const validateForm = () => {
    let mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/;
    let errors = { emailId: '', password: "" }
    let isFormValid = true;
    
    //for Email 
    if (!emailId) errors.emailId = "*Please enter your email";
    else if (!mailFormat.test(emailId)) errors.emailId = "Please enter vaild email";
    else errors.emailId = '';

    // for password
    if (!password.trim()) errors.password = errorMessages.PROVIDE_PASSWORD;
    else if (!common.validatePassword(password)) errors.password = errorMessages.PROVIDE_VALID_PASSWORD;
    else errors.password = '';

    if (errors.emailId !== '' || errors.password !== "") isFormValid = false;
    else isFormValid = true;
    setErrors(errors); setIsFormValid(isFormValid)
    return isFormValid;
  }

  const renderTooltip = (props) => (
    !hidden ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> : <Tooltip id="button-tooltip" {...props}>
        Show Password
    </Tooltip>
  );

  /******************* LOGIN HANDLER  ******************/
  const adminLogin = async (event) => {
    event.preventDefault();
    try {
      if (validateForm()) {
        setLoading(true)
        try {
          let body = {
            email: emailId,
            password: password
          };

          const response = await props.callApi(API.ADMIN_LOGIN, body, 'post', 'ADMIN_LOGIN');
          console.log("response", response)
          setLoading(false)
          if (response.settings.status === 1) {
            if (response.settings.message == "Logged in successfully.") {
              toast.success(response.settings.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              common.setItem("accessToken", response.data.accessToken)
              props.history.push("/dashboard")
            } 
          } else {
            toast.error(response.settings.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        }
        catch (error) {
          throw (error)
        }
        // }
      }
    } catch (error) {
      setLoading(false)
      throw (error)
    }
  }

  return (
    <div className="App">
      <header className="App-header"></header>
      {/* theme-setting-block */}
      <Theme />
      {/* Login Wrapper */}
      <div className="login-wrapper" style={{ background: "url(../assets/images/login-bg.jpg) no-repeat center center" }}>
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-8 col-11">
              <div className="card bg-login-card">
                <div className="row m-0">
                  <div className="col-md-6 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header">
                        <div className="card-title text-center mb-4">
                          <h4 className="mb-2">Welcome</h4>
                        </div>
                        <div className="card-title text-center mb-4">
                          <h3 className="mb-2">Sign in to your account</h3>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="card-body">
                          <form onSubmit={(evt) => adminLogin(evt)} >
                            <div className="form-group mb-2">
                              <label htmlFor="emailId">Email<sup className="text-danger">*</sup></label>
                              <input 
                              type="email" 
                              id="emailId" 
                              aria-describedby="emailHelp" 
                              placeholder="Email"
                              type='text' 
                              className={errors.emailId ? 'form-control input-error' : 'form-control'} 
                              name='emailId' 
                              value={emailId} 
                              id='email'
                              onChange={(e) => { 
                                setEmailId(e.target.value); 
                                localStorage.setItem("emailId", e.target.value); 
                                errors = Object.assign(errors, { emailId: "" }); 
                                setErrors(errors) 
                              }}
                              />
                              <span className='error-msg' style={{ color: "red" }} >{errors.emailId}</span>
                            </div>
                            <div className="form-group mb-2 position-relative">
                              <label htmlFor="Password">Password<sup className="text-danger">*</sup></label>
                              <input 
                                className={errors.password ? 'form-control input-error' : 'form-control'}
                                name='password' 
                                value={password} 
                                id='password' 
                                type={hidden ? "password" : "text"} 
                                placeholder='Password' 
                                onChange={(e) => { 
                                  setPassword(e.target.value); 
                                  errors = Object.assign(errors, { password: "" }); 
                                  setErrors(errors) }} 
                              />
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}>
                                <span
                                  onClick={() => setHidden(!hidden)}
                                  toggle="#password-field"
                                  className={hidden ? "bx bx-hide field-icon toggle-password" : "bx bx-show field-icon toggle-password"}
                                ></span>
                              </OverlayTrigger>
                              <em className='fa fa-lock' aria-hidden='true' />
                              <span className="error-msg" style={{ color: "red" }}>{errors.password}</span>
                            </div>
                            <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                              <div className="text-left">
                              </div>
                              <div className="text-right">
                                <Link to="/forgotpassword" className="card-link">
                                  Forgot pasword?
                                </Link>
                              </div>
                            </div>
                            <Button type="button" className="btn btn-primary glow position-relative btn-block" type='submit' onClick={(evt) => adminLogin(evt, 'login')}>
                              {
                                loading ? "Loading..." : "Sign in"
                              }
                              <i className="icon-arrow bx bx-right-arrow-alt"></i>
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img className="img-fluid" src="../assets/images/login.png" alt="login"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { callApi, changeTheme, changeResize })(withRouter(Login))
