import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../actions/commonFunction'; // COMMON FUNCTIONS
import { Button } from 'react-bootstrap';

function EmailVerified(props) {
    // const emailId = localStorage.getItem('emailId');
    const emailId = localStorage.getItem('emailIdForVerify');

    const  emailCharReplace = (email) => {
        let partition = email.split("@");
        let before = partition[0];
        let after = partition[1];
        let middle = before.substr(before.length-5, before.length);
        let replaced = before.replace(middle, "*****")
        // console.log ("final", replaced+ "@" + after);
        return ("final", replaced+ "@" + after);
    }

    return (
        <div className="App">
        <header className="App-header"></header>
        {/* Login Wrapper */}
        <div className="login-wrapper" style={{ background: "url(../assets/images/login-bg.jpg) no-repeat center center" }}>
          <div className="login-body">
            <div className="login-container row m-0">
              <div className="col-xl-8 col-11">
                <div className="card bg-login-card">
                  <div className="row m-0">
                    <div className="col-md-6 col-12 pl-0 pr-0">
                      <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                            <div className="card-content">
                            <div className="card-body">
                            <div className="row m-0">
                                <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div className="card-header">
                                <div className="card-title text-center mb-4">
                                    <h4 className="mb-2">Email sent successfully</h4>
                                    <p>We have sent a reset password link to your email {emailCharReplace(emailId)}</p>
                                    <p>Please open email and click on reset password link to set a new password</p>
                                </div>
                                </div>
                                    <div>
                                        <Button  
                                            type="button" 
                                            className="btn btn-primary glow position-relative btn-block" 
                                            type='submit' 
                                            onClick={(evt) => {props.history.push('/'); localStorage.removeItem('emailIdForVerify')}}
                                        >
                                            Sign in 
                                        </Button>
                                    </div>
                                </div>
        
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                      <div class="card-content">
                        <img class="img-fluid" src="../assets/images/login.png" alt="login"></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default connect(null, { callApi })(EmailVerified)
