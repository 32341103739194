import React, { useState, useEffect, useRef } from 'react';
import Home from '../Home';
import { Tab, Tabs, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import errorMessages from '../../actions/errorMessages'
import { callApi } from '../../actions/commonFunction';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import common from '../../actions/common';
import swal from 'sweetalert';
import * as _ from 'lodash';
import * as language from "../../translations/index";
import API from '../../actions/API';
import TimezoneSelect from 'react-timezone-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Pagination from 'rc-pagination';
import Loader from '../../components/Loader';
import cc from "currency-codes";
import countryTelephoneCode from "country-telephone-code";
import csc from 'country-location-timezone'
import ReactCountryFlag from "react-country-flag"
import { right } from '@popperjs/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getSymbolFromCurrency from 'currency-symbol-map'
const ct = require('countries-and-timezones');

function MasterManagement(props) {
  const selectInputRef = useRef();
  const options = countryList().getData();
  const [countryListing, setCountryListing] = useState([]);
  const [show, setShow] = useState(false);
  const [showTimeZone, setTimeZone] = useState(false);
  const [showCurrency, setCurrency] = useState(false);
  const [countryName, setCountryName] = useState(null);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [countryTimeZone, setCountryTimeZone] = useState(null);
  const [selectedCountryCurrency, setSelectedCountryCurrency] = useState(null);
  const [countryCurrencyCode, setCountryCurrencyCode] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [lowerCasePhoneCode, setLowerCasePhoneCode] = useState("");
  var [errors, setErrors] = useState({});
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [isFormValid, setIsFormValid] = useState(true);
  const [countryDetails, setCountryDetails] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState({ value: "", label: "" });
  const [editCountry, setEditCountry] = useState(false)
  const [editTimeZone, setEditTimeZone] = useState(false)
  const [editCurrency, setEditCurrency] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState([]);
  const [total, setTotal] = useState(1);
  const [eventKeys, setEventKeys] = useState("country");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [timezonepage, setTimezonePage] = useState(1);
  const [timezonePagesize, setTimezonePagesize] = useState(10);
  const [timezoneListing, setTimezoneListing] = useState([]);
  const [timezoneTotal, setTimezoneTotal] = useState(1);
  const [timezoneArray, setTimezoneArray] = useState(null);
  const [currencyArray, setCurrencyArray] = useState(null)
  const [currencyCodeOptions, setCurrencyCodeOptions] = useState(null);
  const [currencyPage, setCurrencyPage] = useState(1);
  const [currencyPagesize, setCurrencyPagesize] = useState(10);
  const [currencylisting, setCurrencylisting] = useState([]);
  const [currencyTotal, setCurrencyTotal] = useState(1);
  const [countryStatus, setCountryStatus] = useState(false);
  const [timeZoneStatus, setTimeZoneStatus] = useState(false);
  const [currencyStatus, setCurrencyStatus] = useState(false);
  const [specificTimezone, setspecificTimezone] = useState([]);
  const [phonenumber, setPhonenumber] = useState("");
  const [searchText, setSearchtext] = useState("");
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  useEffect(() => {
    getAllCountryList();
    setEditCountry(false);
    setEditTimeZone(false);
    getAllTimezoneList();
    getAllCurrencyList();
  }, [page, pagesize, timezonepage, timezonePagesize, currencyPage, currencyPagesize]);

  const handleCloseCountry = () => {
    setShow(false);
    setEditCountry(false)
    setCountryName(null)
    setCountryCode('');
    setPhonenumber("")
    setCountryDetails([])
    setErrors({});
  }
  const handleCloseTimeZone = () => {
    setTimeZone(false);
    setEditTimeZone(false);
    setErrors({});
    setSelectedTimezone("");
    setCountryTimeZone(null);

  }
  const handleCloseCurrency = () => {
    setCurrency(false);
    setEditCurrency(false);
    setErrors({});
    setSelectedCountryCurrency(null);
    setCountryCurrencyCode(null);

  }

  const modelCloseFun = () => {
    handleCloseCountry()
    handleCloseTimeZone()
    handleCloseCurrency()
  }
  const handleAddCountry = () => {
    setShow(true);
    setTimeZone(false);
    setCurrency(false);
    setEditCountry(false)
    setCountryName(null)
    setCountryCode('')
    setErrors({});
    setCountryDetails([])
  }

  const handleAddTimeZone = () => {
    setTimeZone(true);
    setEditTimeZone(false)
    setShow(false);
    setCurrency(false);
    setErrors({});
    setSelectedTimezone("");
    setCountryTimeZone(null);
  }
  const handleAddCurrency = () => {
    setCurrency(true);
    setTimeZone(false);
    setEditCurrency(false);
    setShow(false);
    setErrors({});
    setSelectedCountryCurrency(null);
    setCountryCurrencyCode(null);
  }

  const modelOnAddBtn = () => {
    if (eventKeys === "country") {
      handleAddCountry()
    }
    if (eventKeys === "timezone") {
      handleAddTimeZone()
    }
    if (eventKeys === "currency") {
      handleAddCurrency()
    }
  }


  const changeHandler = val => {
    console.log(val)
    if (val && val.label) {
      var cc = require('currency-codes');
      console.log(cc.country(val.label));
      let countryCurrency = [];
      let list = [];
      list = cc.country(val.label)
      console.log(list)
      for (let i = 0; i < list.length; i++) {
        countryCurrency.push({
          label: list[i].code,
          value: list[i].code,
        });
      }
      let currency = countryCurrency && countryCurrency[0] && countryCurrency[0].value
      console.log(currency)
      setCountryCurrencyCode(currency)
    }











    setCountryName(val);
    errors.countryName = ""
    let data = countryTelephoneCode(val.value)
    setCountryCode(data[0])
    setPhonenumber(data[0])
  }


  const changeCountryTimeZoneHandlr = val => {
    console.log(val)
    setSelectedTimezone("")
    if (val && val.label) {
      let data = countryList().getValue(val.label)
      const timezones = ct.getTimezonesForCountry(data);
      console.log(timezones)
      console.log(timezones)
      const newArray = timezones.map(item => {
        return { label: `(${item.dstOffsetStr}) ${item.name}`, value: `(${item.dstOffsetStr}) ${item.name} ` };
      });
      setspecificTimezone(newArray)
    }
    const o3 = { ...timezoneArray, timezone: "" };
    console.log(o3);
    // setTimezoneArray(o3)
    setCountryTimeZone(val);
    errors.countryTimeZone = ""
  }

  const changeCurrencyCountryHndlr = val => {
    setCountryCurrencyCode("")
    setSelectedCountryCurrency(val)
    console.log(val)
    if (val && val.label) {
      var cc = require('currency-codes');
      console.log(cc.country(val.label));
      let countryCurrency = [];
      let list = [];
      list = cc.country(val.label)
      console.log(list)
      for (let i = 0; i < list.length; i++) {
        countryCurrency.push({
          label: list[i].code,
          value: list[i].code,
        });
      }
      setCurrencyCodeOptions(countryCurrency)
    }
    errors.selectedCountryCurrency = ""
  }

  const changeCountryCurrencyHandler = val => {
    setCountryCurrencyCode(val && val)
    errors.countryCurrencyCode = ""
  }

  const changeTimeZone = val => {
    setSelectedTimezone(val)
    errors.selectedTimezone = ""
  }


  const validateForm = (type) => {
    let errors = { countryName, countryTimeZone, selectedTimezone, countryCurrencyCode, selectedCountryCurrency }
    let isFormValid = true;
    if (type === "addCountry") {
      if (!countryName) {
        errors.countryName = errorMessages.PROVIDE_COUNTRY_NAME;
        isFormValid = false
      }
      else errors.countryName = ""
    }

    if (type === "addTimezone") {
      console.log("dddddddd ", countryTimeZone)
      if (!countryTimeZone) {

        errors.countryTimeZone = errorMessages.PROVIDE_COUNTRY_NAME;
        isFormValid = false
      }
      else errors.countryTimeZone = ""
      if (!selectedTimezone) {
        errors.selectedTimezone = errorMessages.PROVIDE_TIMEZONE;
        isFormValid = false
      }
      else errors.selectedTimezone = ""
    }
    else if (type === "addCurrency") {
      if (!selectedCountryCurrency) {
        errors.selectedCountryCurrency = errorMessages.PROVIDE_COUNTRY_NAME;
        isFormValid = false
      }
      else errors.selectedCountryCurrency = ""
      if (!countryCurrencyCode) {
        errors.countryCurrencyCode = errorMessages.PROVIDE_CURRENCY;
        isFormValid = false
      }
      else errors.countryCurrencyCode = ""

    }
    setErrors(errors);
    setIsFormValid(isFormValid);
    return isFormValid
  }
  const addCountryInfo = async (e, type) => {
    let body;
    if (validateForm(type)) {
      e.preventDefault();
      if (type === "addCountry") {
        body = {
          countryName: countryName.label,
          countryCode: countryName.value,
          phoneCode: countryCode,
          currency: countryCurrencyCode && countryCurrencyCode != undefined ? countryCurrencyCode : "",

        }
      }
      console.log(body, countryCurrencyCode)


      const response = await props.callApi(API.ADD_UPDATE_COUNTRY, body, 'post', null, true);
      if (response.status === 1) {
        // common.showSuccessMessage(" Data Added Successfully")
        toast.success('Data added successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShow(false);
        setEditCountry(false)
        getAllCountryList();


      }
    }
  }

  const updateCountryInfo = async (e, type) => {
    let body;
    let updateName = !countryName ? defaultCountry.label : countryName.label;
    let updateCode = !countryName ? defaultCountry.value : countryName.value;
    let updatePhoneCode = !countryCode ? countryDetails.phoneCode : countryCode;
    let updateCurrency = !countryCurrencyCode ? currencyArray.currency : countryCurrencyCode;
    e.preventDefault();

    body = {
      countryName: updateName,
      countryCode: updateCode,
      phoneCode: updatePhoneCode,
      countryId: countryDetails._id,
      currency: updateCurrency,
    }
    const response = await props.callApi(API.ADD_UPDATE_COUNTRY, body, 'post', null, true);
    if (response.status === 1) {
      // common.showSuccessMessage(" Data Added Successfully")
      toast.success('Data added successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShow(false);
      setEditCountry(false)
      getAllCountryList();
    }

  }

  const updateTimezoneInfo = async (e, type) => {
    let body;
    let updateCountry = !countryTimeZone ? timezoneArray.countryId.countryName : countryTimeZone.label;
    let countryID = !countryTimeZone ? timezoneArray.countryId._id : countryTimeZone.value;
    let updateTimeZone = !selectedTimezone ? timezoneArray.timezone : selectedTimezone;
    e.preventDefault();

    if (validateForm("addTimezone")) {
      body = {
        countryName: updateCountry,
        timezone: updateTimeZone.label,
        phoneCode: timezoneArray.countryId.phoneCode,
        countryId: countryID,
        timezoneId: timezoneArray._id
      }
      const response = await props.callApi(API.ADD_UPDATE_TIMEZONE, body, 'post', null, true);
      if (response.status === 1) {
        // common.showSuccessMessage(" Data Added Successfully")
        toast.success('Data added successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeZone(false);
        setEditTimeZone(false)
        getAllTimezoneList();
      }

    }

  }

  const updateCurrancyInfo = async (e, type) => {
    let body;
    let updateCountry = !selectedCountryCurrency ? currencyArray.countryId.countryName : selectedCountryCurrency.label;
    let countryID = !selectedCountryCurrency ? currencyArray.countryId._id : selectedCountryCurrency.value;
    let updateCurrency = !countryCurrencyCode ? currencyArray.currency : countryCurrencyCode.value;
    e.preventDefault();
    if (validateForm("addCurrency")) {
      body = {
        countryName: updateCountry,
        currency: updateCurrency,
        currencyId: currencyArray._id,
        countryId: countryID,
      }
      const response = await props.callApi(API.ADD_UPDATE_CURRENCY, body, 'post', null, true);
      if (response.status === 1) {
        // common.showSuccessMessage(" Data Added Successfully")
        toast.success('Data added successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCurrency(false);
        setEditCurrency(false);
        getAllCurrencyList();
      }
    }


  }

  const addTimezoneInfo = async (e, type) => {
    e.preventDefault();
    if (validateForm(type)) {
      if (type === "addTimezone") {
        var body = {
          countryName: countryTimeZone.label,
          countryId: countryTimeZone.value,
          timezone: selectedTimezone.label,
        }

      }
      const response = await props.callApi(API.ADD_UPDATE_TIMEZONE, body, 'post', null, true);
      if (response.status === 1) {
        // common.showSuccessMessage(" Data Added Successfully")
        toast.success('Data added successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeZone(false);
        setEditTimeZone(false)
        getAllTimezoneList();

      }
    }
  }

  const addCurrancyInfo = async (e, type) => {
    if (validateForm(type)) {
      e.preventDefault();
      if (type === "addCurrency") {
        var body = {
          countryName: selectedCountryCurrency.label,
          currency: countryCurrencyCode.value,
          countryId: selectedCountryCurrency.value,
        }
      }
      const response = await props.callApi(API.ADD_UPDATE_CURRENCY, body, 'post', null, true);
      if (response.status === 1) {
        // common.showSuccessMessage(" Data Added Successfully")
        toast.success('Data added successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCurrency(false);
        setEditCurrency(false);
        getAllCurrencyList();
      }
    }
  }

  const getAllTimezoneList = async () => {
    var body = {
      page: timezonepage,
      pagesize: timezonePagesize,
      columnKey: "timezoneListing",
    }
    const response = await props.callApi(API.TIMEZONE_LISTING, body, 'post', null, true);
    if (response.status === 1) {
      setTimezoneListing(response.data.listing);
      setTimezoneTotal(response.total);
      setLoading(false);
    }
  }

  const getAllCountryList = async () => {
    var body = { page: page, pagesize: pagesize, columnKey: "countriesListing" }
    const response = await props.callApi(API.COUNTRY_LIST, body, 'post', null, true);
    if (response.status === 1) {
      setCountryListing(response.data.listing);
      setTotal(response.total)
      let countryList = [];
      let list = [];
      list = response.data.listing;

      for (let i = 0; i < list.length; i++) {
        countryList.push({
          label: list[i].countryName,
          value: list[i]._id,

        });
      }
      setSelectedCountryName(countryList);
      setLoading(false);
    }
  }

  const getAllCurrencyList = async () => {
    var body = {
      page: currencyPage,
      pagesize: currencyPagesize,
      columnKey: "currenciesListing",
    }
    const response = await props.callApi(API.CURRENCY_LIST, body, 'post', null, true);
    if (response.status === 1) {
      setCurrencylisting(response.data.listing);
      setCurrencyTotal(response.total);
      setLoading(false);
    }

  }
  const paginationCurrency = (page, pagesize) => {
    setCurrencyPage(page); setCurrencyPagesize(pagesize);
  }
  const paginationCountry = (page, pagesize) => {

    setPage(page);
    setPagesize(pagesize);
  }

  const paginationTimezone = (page, pagesize) => {
    setTimezonePage(page);
    setTimezonePagesize(pagesize);
  }

  const handleOnPhoneCode = (value) => {
    errors.countryCode = ""
    let data = countryTelephoneCode(value.value)
    setCountryCode(data)
  }


  const getCountryDetails = async (id) => {
    setEditCountry(true)
    setShow(true);
    const response = await props.callApi(API.GET_COUNTERY_DETAILS + id, "", 'get', null, true);
    if (response.status === 1) {
      console.log(response)
      setCountryDetails(response.data)
      let code = response.data.countryCode
      let res = code.toLowerCase();
      setLowerCasePhoneCode(res)
      setDefaultCountry(prev => ({ ...prev, value: response.data.countryCode, label: response.data.countryName }));
      setPhonenumber(response.data.phoneCode);
      console.log(response)
      setCountryCurrencyCode(response.data.currency)
    }
  }

  const getTimeZoneDetails = async (id) => {
    setEditTimeZone(true)
    setTimeZone(true)
    const response = await props.callApi(API.GET_TIMEZONE_DETAILS + id, "", "GET", null, true);
    if (response.status === 1) {
      let obj = {}
      obj.label = response.data.timezone
      obj.value = response.data.timezone
      setTimezoneArray(response.data)
      let obj1 = {}
      obj1.label = response.data.countryId && response.data.countryId.countryName
      obj1.value = response.data._id && response.data.countryId._id
      setSelectedTimezone(obj)
      setCountryTimeZone(obj1)
    }
  }

  const getCurrencyDetails = async (id) => {
    setEditCurrency(true);
    setCurrency(true)
    const response = await props.callApi(API.GET_CURRENCY_DETAILS + id, "", "GET", null, true);
    if (response.status === 1) {
      console.log(response.data)
      setCurrencyArray(response.data);
      let obj = {}
      obj.label = response.data.currency
      obj.value = response.data.currency
      setCountryCurrencyCode(obj)
      let obj1 = {}
      obj1.label = response.data.countryId && response.data.countryId.countryName
      obj1.value = response.data._id && response.data.countryId._id
      setSelectedCountryCurrency(obj1)
    }
  }

  const deleteCountries = async (cId) => {
    var delArr = multipleDelete;
    delArr = _.compact(delArr)
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { countriesIds: delArr }
          }
          else {
            body = { countriesIds: [cId] }
          }
          const response = await props.callApi(API.DELETE_COUNTRIES, body, 'post', null, true);
          if (response.status === 1) {
            // common.showSuccessMessage("Deleted Successfully")
            toast.success('Deleted successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setPage(1); setPagesize(10)
            getAllCountryList();
            getAllTimezoneList();
            getAllCurrencyList();
          }
        }
      });
  }

  const deleteTimezone = async (tId) => {
    var delArr = multipleDelete;
    delArr = _.compact(delArr)
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { "timezoneIds": delArr }
          }
          else {
            body = { "timezoneIds": [tId] }
          }
          const response = await props.callApi(API.DELETE_TIMEZONE, body, 'post', null, true);
          if (response.status === 1) {
            // common.showSuccessMessage("Deleted Successfully")
            toast.success('Deleted successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setPage(1); setPagesize(10)
            getAllTimezoneList()
          }
        }
      });
  }
  const deleteCurrencies = async (cId) => {
    var delArr = multipleDelete;
    delArr = _.compact(delArr)
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { "currenciesIds": delArr }
          }
          else {
            body = { "currenciesIds": [cId] }
          }
          const response = await props.callApi(API.DETETE_CURRENCY, body, 'post', null, true);
          if (response.status === 1) {
            // common.showSuccessMessage("Deleted Successfully")
            toast.success('Deleted successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setPage(1); setPagesize(10)
            getAllCurrencyList()
          }
        }
      });
  }
  const addUpdateFunction = (event) => {
    if (!editCountry) {
      addCountryInfo(event, "addCountry")
    }
    else {
      updateCountryInfo(event, "updateCountry")
    }
  }

  const addUpdateTimezoneInfo = (event) => {
    if (!editTimeZone) {
      addTimezoneInfo(event, "addTimezone")
    }
    else {
      updateTimezoneInfo(event, "updateTimezone")
    }
  }
  const addUpdateCurrencyInfo = (event) => {
    if (!editCurrency) {
      addCurrancyInfo(event, "addCurrency")
    }
    else {
      updateCurrancyInfo(event, "updateTimezone")
    }
  }


  const searchField = async (eventKeys) => {
    var body = {
      page: page,
      pagesize: pagesize,
      searchText: searchText && searchText.length > 1 ? searchText : ""
    };
    if (eventKeys === "country") {
      const response = await props.callApi(API.COUNTRY_LIST, body, 'post', null, true);
      if (response.status === 1) {
        setCountryListing(response.data.listing);
        setTotal(response.total);
        getAllCurrencyList();
        getAllTimezoneList()
      }
    }
    else if (eventKeys === "timezone") {
      const response = await props.callApi('/timezoneListing', body, 'post', null, true);
      if (response.status === 1) {
        setTimezoneListing(response.data.listing);
        setTimezoneTotal(response.data.listing.length);
        getAllCountryList()
        getAllCurrencyList()
      }
    }
    else if (eventKeys === "currency") {
      const response = await props.callApi('/currenciesListing', body, 'post', null, true);
      if (response.status === 1) {
        setCurrencylisting(response.data.listing);
        setCurrencyTotal(response.data.listing.length);
        getAllCountryList()
        getAllTimezoneList()
        // ;setCurrencyTotal(response.total);
      }
    }
  }

  const countryStatusHandler = async (status, countryId) => {
    setCountryStatus(current => !current)
    var body = { countriesIds: [countryId], status: status };
    const response = await props.callApi(API.COUNTRY_STATUS, body, 'post', null, true);
    if (response.status === 1) {
      // common.showSuccessMessage("Status Updated Successfully")
      toast.success('Status updated successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getAllCountryList();
    }
  }

  const timeZoneStatusHandler = async (status, timezoneId) => {
    setTimeZoneStatus(current => !current)
    var body = { timezoneIds: [timezoneId], status };
    const response = await props.callApi(API.TIMEZONE_STATUS, body, 'post', null, true);
    if (response.status === 1) {
      // common.showSuccessMessage("Status Updated Successfully");
      toast.success('Status updated successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getAllTimezoneList()
    }
  }


  const currencyStatusHandler = async (status, currencyId) => {
    setCurrencyStatus(current => !current)
    var body = { currenciesIds: [currencyId], status };
    const response = await props.callApi(API.CURRENCY_STATUS, body, 'post', null, true);
    if (response.status === 1) {
      // common.showSuccessMessage("Status Updated Successfully");
      toast.success('Status updated successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getAllCurrencyList();
    }
  }

  // const onClear = (val) => {
  //   console.log(val)

  //   selectInputRef.current.select.onInputBlur();


  // };
  const clearInput = () => {
    setSearchtext("");
    getAllCountryList();
    getAllTimezoneList();
    getAllCurrencyList()
  }
  const translations = language[props.language !== undefined ? props.language : "en"];
  return (
    <Home>
      <div className="main-content-area">
        <div className="main-content-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="content-header-title">{translations.MasterMangement.master}</li>
              <li className="breadcrumb-item"><Link onClick={() => { props.history.push('/dashboard') }}><i className="bx bx-home-alt"></i></Link></li>
              <li className="breadcrumb-item active" aria-current="page">{translations.MasterMangement.manageMaster}</li>
            </ol>
          </nav>
          <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
            <div className="d-flex align-items-center">
              <div className="search position-relative has-icon-left">
                <input type="search" value={searchText} className="form-control text-capitalize" placeholder="search"
                  onChange={(evt) => { searchField(eventKeys); setSearchtext(evt.target.value) }} />


                {
                  searchText && searchText.length > 0 ?
                    <div className="clear">
                      <span onClick={clearInput} ><em className="bx bx-x"></em></span>
                    </div> : null
                }


                <div className="form-control-position">
                  <em className="bx bx-search"></em>
                </div>
              </div>
            </div>
            <Button type="button" onClick={modelOnAddBtn} className="btn btn-primary glow-primary">
              {translations.MasterMangement.add}
            </Button>
            <Modal show={show} onHide={modelCloseFun} >
              <Modal.Header closeButton>
                <div class="d-flex align-items-center">
                  <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                  <h5 class="modal-title" id="exampleModalLongTitle">{translations.MasterMangement.addCountry}</h5>
                </div>

              </Modal.Header>
              <Modal.Body closeButton>
                <div class="notification-form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group mb-4">
                        <label class="mb-0" for="CountryName">{translations.MasterMangement.countryName}<sup className="text-danger">*</sup></label>

                        <Select
                          placeholder={editCountry && countryDetails ? defaultCountry.label : "Please Select Country"}
                          options={options}
                          ref={selectInputRef}
                          defaultValue={editCountry && countryDetails ? defaultCountry : ""}
                          value={countryName}
                          onChange={changeHandler}
                          // onInputChange={onClear}
                          className="country-name"
                        />
                        <span className='error-msg' style={{ color: "red" }} >{errors.countryName}</span>
                      </div>
                    </div>
                  </div>

                  {
                    countryName != null || countryDetails.countryCode != undefined ?
                      <div className="row">
                        <div class="col-md-6">
                          <div class="form-group mb-5">
                            <label class="mb-0" for="CountryCode">{translations.MasterMangement.countryCode}<sup className="text-danger">*</sup></label>
                            {/* <select class="form-control selectpicker" id="CountryCode" data-live-search="true">
                                <option>
                                  {countryName && countryName.value ? countryName.value : (editCountry ? countryDetails.countryCode : "Country Code")}
                                </option>

                              </select> */}
                            <span className="d-block input-code">
                              {countryName && countryName.value ? countryName.value : (editCountry ? countryDetails.countryCode : "Country Code")}
                            </span>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mb-2">
                            <label class="mb-0" for="PhoneCode">{translations.MasterMangement.phoneCode}<sup className="text-danger">*</sup></label>
                            <span className="d-block input-code">
                              {
                                phonenumber && phonenumber ?
                                  phonenumber : null
                              }
                            </span>
                            <span className='error-msg' style={{ color: "red" }} >{errors.countryCode}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mb-2">
                            <label class="mb-0" for="PhoneCode">currency</label>
                            <span className="d-block input-code">
                              {
                                countryCurrencyCode && countryCurrencyCode ?
                                  countryCurrencyCode : null
                              }
                            </span>
                            <span className='error-msg' style={{ color: "red" }} >{errors.countryCode}</span>
                          </div>
                        </div>
                      </div> : null

                  }
                  <div className="row">
                    <div className="col-md-12">
                      <div class="modal-btn">
                        <Link href="#" onClick={(event) => addUpdateFunction(event)} class="btn btn-primary">{translations.MasterMangement.add}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {eventKeys === "timezone" ?
              <Modal show={showTimeZone} onHide={modelCloseFun} >
                <Modal.Header closeButton>
                  <div class="d-flex align-items-center">
                    <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                    <h5 class="modal-title" id="exampleModalLongTitle">{translations.MasterMangement.addCountryTimeZone}</h5>
                  </div>

                </Modal.Header>
                <Modal.Body closeButton>
                  <div class="notification-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group mb-4">
                          <label class="mb-0" for="CountryName">{translations.MasterMangement.countryName}<sup className="text-danger">*</sup></label>
                          <Select
                            placeholder={editTimeZone && timezoneArray ? timezoneArray.countryId.countryName : "Please Select Country"}
                            options={selectedCountryName}
                            value={countryTimeZone}
                            onChange={changeCountryTimeZoneHandlr}
                          />
                          <span className='error-msg' style={{ color: "red" }} >{errors.countryTimeZone}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-5">
                          <label class="mb-0" for="CountryCode">{translations.MasterMangement.timeZone}<sup className="text-danger">*</sup></label>

                          <Select
                            // placeholder={editTimeZone && timezoneArray ? timezoneArray.timezone : "Please Select Time Zone"}
                            placeholder={editTimeZone && specificTimezone.label ? specificTimezone.label : "Please Select Time Zone"}
                            options={specificTimezone}
                            value={selectedTimezone}
                            onChange={changeTimeZone}
                          />
                          <span className='error-msg' style={{ color: "red" }} >{errors.selectedTimezone}</span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="modal-btn">
                          <Link href="#" class="btn btn-primary" onClick={(e) => addUpdateTimezoneInfo(e)}>Add</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal> : null}
            {eventKeys === "currency" ?
              <Modal show={showCurrency} onHide={modelCloseFun} >
                <Modal.Header closeButton>
                  <div class="d-flex align-items-center">
                    <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                    <h5 class="modal-title" id="exampleModalLongTitle">{translations.MasterMangement.addCurrency}</h5>
                  </div>
                  {/* <Button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true"><em class='bx bx-x'></em></span>
                          </Button> */}
                </Modal.Header>
                <Modal.Body closeButton>
                  <div class="notification-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group mb-4">
                          <label class="mb-0" for="CountryName">{translations.MasterMangement.countryName}<sup className="text-danger">*</sup></label>

                          <Select
                            placeholder={editCurrency && currencyArray ? currencyArray.countryId.countryName : "Please Select Country"}
                            options={selectedCountryName}
                            value={selectedCountryCurrency}
                            onChange={changeCurrencyCountryHndlr}
                          />
                          <span className='error-msg' style={{ color: "red" }} >{errors.selectedCountryCurrency}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-5">
                          <label class="mb-0" for="CountryCode">{translations.MasterMangement.currency}<sup className="text-danger">*</sup></label>
                          <Select
                            placeholder={"Please Select Country Currency Code"}
                            options={currencyCodeOptions && currencyCodeOptions}
                            value={countryCurrencyCode && countryCurrencyCode}
                            // defaultInputValue={countryCurrencyCode}
                            onChange={changeCountryCurrencyHandler}
                          />
                          <span className='error-msg' style={{ color: "red" }} >{errors.countryCurrencyCode}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="modal-btn">
                          <Link href="#" class="btn btn-primary" onClick={(e) => addUpdateCurrencyInfo(e)}  >{translations.MasterMangement.add}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal> : null}
          </div>
          <div className="card notification-card tabs-block p-0">
            <Tabs defaultActiveKey="country" id="uncontrolled-tab-example" className="pl-5 pt-3" onSelect={(eventKey) => setEventKeys(eventKey)} >
              <Tab eventKey="country" title={translations.MasterMangement.countryName}>
                <div className="card-body content">
                  {/* datatable start */}
                  <table className="table row-border nowrap common-datatable" id="master-country-listing">
                    <thead>
                      <tr>
                        <th className="all">{translations.MasterMangement.countryName}</th>
                        <th className="all">{translations.MasterMangement.countryCode}</th>
                        <th className="all">{translations.MasterMangement.phoneCode}</th>
                        <th className="all">{translations.MasterMangement.currency}</th>
                        <th className="all">{translations.MasterMangement.status}</th>
                        <th className="all">{translations.MasterMangement.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {countryListing && countryListing.length === 0 ?
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">


                            {
                              loading ? <Loader /> : null
                            }                  {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">{translations.MasterMangement.noRecord}</h6> : null}
                          </td>
                        </tr> :

                        countryListing && Array.isArray(countryListing) && countryListing.map((list, listKey) => {

                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  {/* <span className="country-img d-block mr-1"><img src="assets/images/india-flag.svg" alt="country-flag"/></span> */}
                                  <ReactCountryFlag countryCode={list.countryCode} svg
                                    style={{
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>{list.countryName}</span>
                                </div>
                              </td>
                              <td><span>{list.countryCode}</span></td>
                              <td><span>{`+ ${list.phoneCode}`}</span></td>
                              <td><span>{list.currency}</span> - ({getSymbolFromCurrency(list.currency)})</td>
                              {/* <td><span>{getSymbolFromCurrency(list.currency)}</span></td> */}
                              <td>
                                <div className="custom-control custom-switch light">
                                  <input type="checkbox" className="custom-control-input"
                                    id={list._id}
                                    checked={list.status ? list.status : false}
                                    onChange={() => { list.status = !list.status; countryStatusHandler(list.status, list._id) }}
                                  />
                                  <label className="custom-control-label"
                                    for={list._id}></label>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <a className="cursor-pointer mr-3" onClick={() => getCountryDetails(list._id)} ><i className='bx bx-edit'></i></a>
                                  <a className="cursor-pointer" onClick={() => deleteCountries(list._id)} ><i className='bx bx-trash-alt'></i></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}

                    </tbody>
                  </table>
                  {/* datatable ends  */}
                  <div className="mt-3">
                    <Pagination pageSize={pagesize} current={page} total={total} onChange={paginationCountry} />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="timezone" title={translations.MasterMangement.timeZone} >
                <div className="card-body content">
                  <table className="table row-border nowrap common-datatable" id="master-timezone-listing">
                    <thead>
                      <tr>
                        <th className="all">{translations.MasterMangement.countryName}</th>
                        <th className="all">{translations.MasterMangement.timeZone}</th>
                        <th className="all">{translations.MasterMangement.status}</th>
                        <th className="all">{translations.MasterMangement.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timezoneListing && timezoneListing.length === 0 ?
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">


                            {
                              loading ? <Loader /> : null
                            }                  {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">{translations.MasterMangement.noRecord}</h6> : null}
                          </td>
                        </tr> :
                        timezoneListing && Array.isArray(timezoneListing) && timezoneListing.map((list, listKey) => {

                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <ReactCountryFlag countryCode={list.countryCode} svg
                                    style={{
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>{list.countryName}</span>
                                </div>
                              </td>
                              <td><span>{list.timezone}</span></td>
                              <td>
                                <div className="custom-control custom-switch light">
                                  <input type="checkbox" className="custom-control-input"
                                    id={list._id}
                                    checked={list.status ? list.status : false}
                                    onChange={() => { list.status = !list.status; timeZoneStatusHandler(list.status, list._id) }} />
                                  <label className="custom-control-label"
                                    for={list._id}></label>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <a className="cursor-pointer mr-3" onClick={() => getTimeZoneDetails(list._id)}><i className='bx bx-edit'></i></a>
                                  <a className="cursor-pointer" onClick={() => deleteTimezone(list._id)} ><i className='bx bx-trash-alt'></i></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}

                    </tbody>
                  </table>
                  <div className="mt-3">
                    <Pagination pageSize={timezonePagesize} current={timezonepage} total={timezoneTotal} onChange={paginationTimezone} />
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey="currency" title={translations.MasterMangement.currency}>
                <div className="card-body content">
                  <table className="table row-border nowrap common-datatable" id="master-currency-listing">
                    <thead>
                      <tr>
                        <th className="all">{translations.MasterMangement.countryName}</th>
                        <th className="all">{translations.MasterMangement.currency}</th>
                        <th className="all">Symbol</th>
                        <th className="all">{translations.MasterMangement.status}</th>
                        <th className="all">{translations.MasterMangement.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currencylisting && currencylisting.length === 0 ? <tr className="text-center text-danger not-found-txt">
                        <td colSpan="6">

                          {
                            loading ? <Loader /> : null
                          }                  {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">{translations.MasterMangement.noRecord}</h6> : null}
                        </td>
                      </tr> :
                        currencylisting && Array.isArray(currencylisting) && currencylisting.map((list, listKey) => {
                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <ReactCountryFlag countryCode={list.countryCode} svg
                                    style={{
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>{list.countryName}</span>
                                </div>
                              </td>
                              <td><span>{list.currency}</span></td>
                              <td><span>{getSymbolFromCurrency(list.currency)}</span></td>

                              <td>
                                <div className="custom-control custom-switch light">
                                  <input type="checkbox" className="custom-control-input"
                                    onChange={() => { list.status = !list.status; currencyStatusHandler(list.status, list._id) }}
                                    id={list._id} checked={list.status ? list.status : false} />
                                  <label className="custom-control-label"
                                    for={list._id}></label>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <a className="cursor-pointer mr-3" onClick={() => getCurrencyDetails(list._id)} ><i className='bx bx-edit'></i></a>
                                  <a className="cursor-pointer" onClick={() => deleteCurrencies(list._id)}><i className='bx bx-trash-alt'></i></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}



                    </tbody>
                  </table>
                  <div className="mt-3">
                    <Pagination pageSize={currencyPagesize} current={currencyPage} total={currencyTotal} onChange={paginationCurrency} />
                  </div>
                </div>
              </Tab> */}
            </Tabs>
            {/* <ul id="tabs" className="nav nav-tabs pl-5 pt-3" role="tablist">
                            <li className="nav-item">
                                <a id="tab-country" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab"><i className="bx bx-globe align-middle mr-1"></i><span className="align-middle">Country</span></a>
                            </li>
                            <li className="nav-item">
                                <a id="tab-timezone" href="#pane-B" className="nav-link" data-toggle="tab" role="tab"><i className="bx bx-time-five align-middle mr-1"></i><span className="align-middle">Timezone</span></a>
                            </li>
                            <li className="nav-item">
                                <a id="tab-currency" href="#pane-C" className="nav-link" data-toggle="tab" role="tab"><i className="bx bx-money align-middle mr-1"></i><span className="align-middle">Currency</span></a>
                            </li>
                        </ul> */}


          </div>

        </div>
        <footer>
          <div className="footer-text d-flex align-items-centerf justify-content-between">
            <span className="d-block">2020 © IndiaNIC</span>
          </div>
        </footer>
      </div>
    </Home>
  );
}

const mapStateToProps = state => ({
  language: state.admin.language,
});

export default connect(mapStateToProps, { callApi })(MasterManagement);
