import React, { useState, useEffect } from "react";
import { callApi } from '../../actions/commonFunction'
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import common from '../../actions/common'
import PhoneInput from 'react-phone-input-2'
import { connect } from "react-redux";
import moment, { months } from "moment";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import errorMessages from '../../actions/errorMessages';
import countryList from 'react-select-country-list';
import API from "../../actions/API"
import CropImages from '../../utils/CropImages'
import { IMG_URL } from '../../config/configs';
import DatePicker from "react-datepicker";
import * as _ from 'lodash';
import swal from 'sweetalert';
import "react-datepicker/dist/react-datepicker.css";
import * as language from "../../translations/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from '../Home'
const queryString = require('query-string');

let { Option } = Select;
function ViewuserDetails(props) {
    const options = countryList().getData();
    const [photo, setPhoto] = useState("")
    const [birthday, setBirthday] = useState('');
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        gender: "Male",
        dob: "",
        website: "",
        mobile: "",
        address: "",
        country: "",
        twitterLink: "",
        fbLink: "",
        instagramLink: "",
        company: "",
        gitHubLink: "",
        codePen: "",
        slack: "",
        errors: {},
    });
    const [role, setRole] = useState({});
    let [loading, setLoading] = useState(false);
    let [disablePhoneinput, setdisablePhoneinput] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [showInput, setShowInput] = useState({});
    const [status, setStatus] = useState(true);
    const [userId, setUserId] = useState('');
    const [multipleDelete, setMultipleDelete] = useState('');
    const [listOfRoles, setListOfRoles] = useState([]);



    const handleChange1 = (value, e) => {
        if (e.props && e.props['data-role']) {
            setRole(e.props['data-role'])
        }
    }

    const getAllRoles = async () => {
        var body = { page: 1, pagesize: 20 }
        const response = await props.callApi(API.LIST_ADMIN_ROLE, body, 'post', null, true);
        if (response.status === 1) {
            setListOfRoles(response.data.listing)
        }
    }

    useEffect(() => {
        if (props.UserAccess && props.UserAccess.viewDetails === false) {
            props.history.push("/dashboard")
        }
        // let url = props.location.search;
        // let params = queryString.parse(url);
        // var id = params.id;
        let id = props.match.params;
        console.log(id.slug)
        if (id) {
            getDetails(id.slug);
            setUserId(id.slug);
        }
        getCountryList()
    }, []);


    //********************* get All Details 
    const getDetails = async (id) => {
        const response = await props.callApi(API.GET_USERS_PROFILE + id, '', 'get', null, true);
        if (response.status === 1) {

            let { data } = response,
                { firstName, lastName, emailId, mobile, photo, dob, gender, website, address, country, fbLink,
                    twitterLink, instagramLink, gitHubLink, slack, codePen, status, company } = data;
            setFormData({
                ...formData,
                firstName,
                lastName,
                email: emailId,
                gender,
                website: website,
                mobile,
                address: address,
                country: country,
                twitterLink: twitterLink,
                fbLink: fbLink,
                instagramLink: instagramLink,
                gitHubLink: gitHubLink,
                codePen: codePen,
                slack: slack,
                status,
                company
            });
            setBirthday(dob)
            setPhoto(photo)
        }
    }
    // //********************** get country List at the time of Add contry  ************* */
    const getCountryList = async () => {
        const response = await props.callApi(API.GET_LIST, null, 'post', null, true);
        if (response.status === 1) {
            // setCountryList(response.data )
        }
    }






    const validateForm = () => {
        const {
            firstName,
            lastName,
            email,
            dob,
            website,
            mobile,
            address,
            country,
            twitterLink,
            fbLink,
            instagramLink,
            gitHubLink,
            codePen,
            slack,
            company,
            errors
        } = formData;

        // for firstName
        if (formData.hasOwnProperty("firstName")) {
            if (isEmpty(firstName))
                errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
            else delete errors.firstName;
        }

        //for lastName
        if (formData.hasOwnProperty("lastName")) {
            if (isEmpty(lastName))
                errors.lastName = errorMessages.PROVIDE_LAST_NAME;
            else delete errors.lastName;
        }

        if (formData.hasOwnProperty("email")) {
            if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
            else if (!common.validateEmail(email))
                errors.email = errorMessages.PROVIDE_VALID_EMAIL;
            else delete errors.email;
        }

        //for mobile
        // let mobileFormat = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
        // if (formData.hasOwnProperty("mobile")) {
        //   console.log("isMobile", isEmpty(mobile.split("-")[1]))
        //   if (isEmpty(mobile.split("-")[1])) {
        //     errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER
        //   }
        //   else delete errors.mobile;
        // }

        //for website





        if (formData.hasOwnProperty("website")) {
            if (!isEmpty(website) && !common.validateURL(website))
                errors.website = errorMessages.PROVIDE_WEBSITE;
            else delete errors.website;
        }

        if (formData.hasOwnProperty("company")) {
            if (!company.trim()) errors.company = errorMessages.PROVIDE_COMPANY_NAME;
            else delete errors.company;

        }


        //for twitterLink
        if (formData.hasOwnProperty("twitterLink")) {
            if (!isEmpty(twitterLink) && !common.validateURL(twitterLink))
                errors.twitterLink = errorMessages.PROVIDE_TWITTER;
            else delete errors.twitterLink;
        }

        //for fbLink
        if (formData.hasOwnProperty("fbLink")) {
            if (!isEmpty(fbLink) && !common.validateURL(fbLink))
                errors.fbLink = errorMessages.PROVIDE_FACEBOOK;
            else delete errors.fbLink;
        }

        //for instagramLink
        if (formData.hasOwnProperty("instagramLink")) {
            if (!isEmpty(instagramLink) && !common.validateURL(instagramLink))
                errors.instagramLink = errorMessages.PROVIDE_INSTAGRAM;
            else delete errors.instagramLink;
        }

        //for gitHubLink
        if (formData.hasOwnProperty("gitHubLink")) {
            if (!isEmpty(gitHubLink) && !common.validateURL(gitHubLink))
                errors.gitHubLink = errorMessages.PROVIDE_GITHUB;
            else delete errors.gitHubLink;
        }

        //for codepen
        if (formData.hasOwnProperty("codePen")) {
            if (!isEmpty(codePen) && !common.validateURL(codePen))
                errors.codePen = errorMessages.PROVIDE_CODEPEN;
            else delete errors.codePen;
        }

        //for slack
        if (formData.hasOwnProperty("slack")) {
            if (!isEmpty(slack) && !common.validateURL(slack))
                errors.slack = errorMessages.PROVIDE_SLACK;
            else delete errors.slack;
        }

        delete errors.gender;
        delete errors.address;
        delete errors.gender;


        const isFormValid = Object.keys(errors).length !== 0 ? false : true;
        setFormData({
            ...formData,
            errors: errors,
        });
        return isFormValid;

    };

    const handleChange = (e) => {
        if (e.target.value) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
                errors: Object.assign(formData.errors, { [e.target.name]: "" }),
            });
        } else
            setFormData({
                ...formData,
                [e.target.name]: "",
            });
    };

    const handleOnChanges = (value, data) => {
        setdisablePhoneinput(false)
        let dialnums = data.dialCode;
        let mobilenums = value.slice(data.dialCode.length);
        mobile = `+${dialnums}-${mobilenums}`;
        if (mobile.length >= 10) {
            setdisablePhoneinput(true)
        }
        setFormData({
            ...formData,
            mobile: mobile
        })
    }

    const handleCountrySelector = val => {
        country = val;
        setFormData({
            ...formData,
            country: country
        })
    }



    let {
        firstName,
        lastName,
        email,
        website,
        mobile,
        gender,
        address,
        country,
        twitterLink,
        fbLink,
        instagramLink,
        gitHubLink,
        codePen,
        slack,
        company,
        errors,
    } = formData,
        { emailshow, mobileShow, emailText } = showInput;


    /****************** Update Users Profile **********/
    const updateUsersData = async (e) => {
        e.preventDefault();
        if (userId === "") {
            let {
                firstName,
                lastName,
                email,
                dob,
                // website,
                mobile,
                gender,
                address,
                // country,
                twitterLink,
                fblink,
                instagramLink,
                gitHubLink,
                codePen,
                slack,
                company,
            } = formData;
            var body = Object.assign({}, formData)
            body.dob = birthday
            body.emailId = email
            body.status = status
            body.userName = firstName + lastName
            body.photo = photo

        } else {
            let {
                firstName,
                lastName,
                email,
                dob,
                // website,
                mobile,
                gender,
                address,
                // country,
                twitterLink,
                fblink,
                instagramLink,
                gitHubLink,
                codePen,
                slack,
                company,
            } = formData;
            var body = Object.assign({}, formData)
            body.dob = birthday
            body.emailId = email
            body.status = status
            body.userName = firstName + lastName
            body.photo = photo
            body.userId = userId
        }
        if (validateForm()) {
            const response = await props.callApi(API.ADD_NEW_USERS, body, 'post', null, true);
            if (response.status === 1) {
                // common.showSuccessMessage(" Data Added Successfully")
                toast.success('Data added successfully', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                props.history.push('/usermanagement')
            }
        }

    }

    const getpreview = (image) => {
        setPhoto(image)
    }
    // *********** Delete User *************************************************************

    const deleteUser = async (uid) => {
        // var delArr = multipleDelete;
        // delArr = _.compact(delArr)
        swal({
            title: "Are you sure,You Want To Delete ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    var body = { "userIds": [uid] }

                    // else {
                    //   body = { "userIds": [uid] }
                    // }
                    const response = await props.callApi(API.DELETE_USERS, body, 'post', null, true);
                    if (response.status === 1) {
                        // common.showSuccessMessage("Deleted Successfully")
                        toast.success('Deleted successfully', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        props.history.push("/usermanagement")
                    }
                }
            });
    }
    //****************************/
    // const handleCountryValue=(value)=>{
    // let obj = countryList.find(o => o.phoneCode === value);
    // setCountry(obj);
    // }
    //********************* 


    let imagePreview = null;
    if (photo) {
        imagePreview = (<img src={photo ? IMG_URL + photo : ''} alt="Icon" />);
    } else {
        imagePreview = (<img src={"assets/images/avatar-s-16.jpg"} alt="Icon" />);
    }

    const genderHandler = (event) => {
        if(event.value && event.label) {
          setFormData({
            ...formData,
            gender: event
          })
        } else {
          setFormData({
            ...formData,
            gender: "",
          })
        }
      }

      const genderOption = [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' }
      ]
    const translations = language[props.language != undefined ? props.language : "en"];
    return (
        <Home>
            <div>
                <div className="dashboard-container">
                    {/* Main start here */}
                    <div className="main-content-area">
                        <div className="overlay" />
                        {/* -- Body overlay */}
                        {/* Header end */}
                        <div className="main-content-block">
                            <form>
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="content-header-title">{translations.Usermanagement.edituser && translations.Usermanagement.edituser.labels && translations.Usermanagement.edituser.labels.userprofile} </li>
                                            <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{translations.Usermanagement.edituser && translations.Usermanagement.edituser.labels && translations.Usermanagement.edituser.labels.userprofile} </li>
                                        </ol>
                                    </nav>
                                    {/* <div className="btn-blocks mb-md-0 mb-2">
                                        <a className="btn btn-primary glow-primary mr-3" onClick={(e) => updateUsersData(e)}><em className="bx bx-revision mr-2" />{loading ? "saving.." : "save"}</a>
                                        <a className="btn btn-primary glow-primary mr-3" onClick={() => deleteUser(userId)}><em className="bx bx-revision mr-2" />Delete</a>
                                    </div> */}
                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div class="card profile-card mb-5">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-3">
                                                    <div className="edit-image">
                                                        <div className="user-image">
                                                            {imagePreview}
                                                            <label className="img-upload" htmlfor="attach-file">
                                                                {/* <input type="file" id="attach-file" /> */}
                                                                <CropImages getPreview={getpreview} />
                                                                <em className="bx bxs-edit-alt" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-9">
                                                    {/* <div className="row">
                        <div className="col-md-6 mt-3"> */}
                                                    <div className="user-title-info user-details">
                                                        <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                                            <label className="mb-0" htmlFor="firstName">{translations.Usermanagement.edituser.labels.firsname} <sup className="text-danger">*</sup></label>
                                                            <div className="w-100">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="firstName"
                                                                    disabled
                                                                    name="firstName"
                                                                    placeholder={translations.Usermanagement.edituser.labels.firsname}
                                                                    value={firstName}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <span className="text-danger d-block">{errors.firstName}</span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                                            <label className="mb-0" htmlFor="lastName">{translations.Usermanagement.edituser.labels.lastname} <sup className="text-danger">*</sup></label>
                                                            <div className="w-100">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="lastName"
                                                                    disabled
                                                                    name="lastName"
                                                                    placeholder={translations.Usermanagement.edituser.labels.lastname}
                                                                    value={lastName}
                                                                    onChange={(e) => handleChange(e)}
                                                                />

                                                                <span className="text-danger d-block">{errors.lastName}</span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                        <label className="mb-0" htmlFor="email">email</label>
                        <div className="w-100">
                          <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          disabled
                          onChange={(e) => handleChange(e)}
                          />
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      </div>

                      {
                        mobile && mobile !="" ?

                        <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                        <label className="mb-0" htmlFor="email">Phone number</label>
                        <div className="w-100">
                          <PhoneInput
                              disabled
                              className="form-control w-100"
                              name="phone"
                              country={'in'}
                              masks={''}
                              countryCodeEditable={false}
                              value={mobile}
                              onChange={handleOnChanges}
                              />
                          </div>
                        </div>:null
                        }*/}




                                                    </div>
                                                    {/* </div> */}
                                                    {/* <div className="col-md-5 mt-3"> */}
                                                    <div className="user-title-info user-details">
                                                        <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                                            <label className="mb-0" htmlFor="email">{translations.Usermanagement.edituser.labels.email} <sup className="text-danger">*</sup></label>
                                                            <div className="w-100">
                                                                <input
                                                                    className="form-control"
                                                                    id="email"
                                                                    name="email"
                                                                    disabled
                                                                    placeholder={translations.Usermanagement.edituser.labels.email}
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <span className="text-danger d-block">{errors.email}</span>
                                                            </div>
                                                        </div>


                                                        <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                                            <label className="mb-0" htmlFor="email">{translations.Usermanagement.edituser.labels.company} <sup className="text-danger">*</sup></label>
                                                            <div className="w-100">
                                                                <input
                                                                    className='form-control'
                                                                    type='company'
                                                                    name='company'
                                                                    disabled
                                                                    placeholder={translations.Usermanagement.edituser.labels.company}
                                                                    value={company}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <span className="text-danger d-block">{errors.company} </span>

                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-md-4 mb-3">
                                                            <label className="mb-0" htmlFor="email">{translations.Usermanagement.edituser.labels.status} <sup className="text-danger">*</sup></label>
                                                            <div class="custom-control custom-switch light w-100">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id='checkbox1'
                                                                    disabled
                                                                    onChange={() => setStatus(!status)}
                                                                    checked={status ? status : false}
                                                                />
                                                                <label class="custom-control-label"
                                                                    for='checkbox1'></label>
                                                            </div>
                                                        </div>

                                                        {
                                                            mobile && mobile != "" ?

                                                                <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                                                    <label className="mb-0" htmlFor="email">{translations.Usermanagement.edituser.labels.phoneNumber} <sup className="text-danger">*</sup></label>
                                                                    <div className="w-100">
                                                                        <PhoneInput

                                                                            className="form-control w-100"
                                                                            name="phone"
                                                                            country={'in'}
                                                                            masks={''}
                                                                            disabled
                                                                            countryCodeEditable={false}
                                                                            value={mobile}
                                                                            onChange={handleOnChanges}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }




                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div>
                </div> */}
                                    </div>






                                    {/* <div className='form-group row'>
                    <label className='col-lg-2 col-sm-3 col-form-label'>Role<span className="text-danger">*</span> :</label>
                    <div className='col-md-8 co-sm-7'>
                      <Select className="col-md-4 mr-2"
                        placeholder={<b>Select one</b>} value={role.role} onChange={(dataset, e) => handleChange1(dataset, e)} onFocus={getAllRoles}>
                        {listOfRoles.map((each, id) => <Option data-role={each} value={each._id} key={id}>{each.role}</Option>
                        )}
                      </Select>
                      <span className="error-block"> {errors.role} </span>
                    </div>
                  </div> */}


                                    <div className="col-md-6 mb-5">
                                        <div className="card profile-card">
                                            <h6 className="d-flex align-items-center title mb-4"><i className="bx bx-user mr-1" />Personal Info</h6>
                                            <div className="form-group d-flex align-items-center mb-md-4 mb-3 user-details">
                                                <label className="mb-0" htmlFor="BirthDate">{translations.Usermanagement.edituser.labels.birthdate} </label>
                                                <fieldset className="position-relative w-100">
                                                    <DatePicker
                                                        selected={birthday ? new Date(birthday) : ""}
                                                        dateFormat="d MMM yyyy"
                                                        placeholderText="Select Date"
                                                        disabled
                                                        className="form-control w-100"
                                                        onChange={(evt) => {
                                                            setBirthday(evt)
                                                            setFormData({
                                                                ...formData,
                                                                dob: birthday,
                                                            });
                                                        }}
                                                    />
                                                    <span className="text-danger d-block">{errors.birthday}</span>
                                                </fieldset>
                                            </div>
                                            <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                                                <label className="mb-0" htmlFor="WebSite">{translations.Usermanagement.edituser.labels.website} </label>
                                                <div className="w-100">
                                                    <input type="text"
                                                        className="form-control"
                                                        id="website"
                                                        name="website"
                                                        disabled
                                                        placeholder={translations.Usermanagement.edituser.labels.website}
                                                        value={website}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    <span className="text-danger d-block">{errors.website}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                                                <label className="mb-0" htmlFor="Gender">{translations.Usermanagement.edituser.labels.gender} </label>
                                                {/* <select className="form-control selectpicker"
                                                    id="gender"
                                                    name="gender"
                                                    value={gender}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="Female">
                                                        Female
                    </option>
                                                    <option value="Male">
                                                        Male
                    </option>
                                                </select> */}
                                                <Select className="selectpicker w-100"
                                                id="gender"
                                                name="gender"
                                                value={gender}
                                                isDisabled 
                                                options={genderOption}
                                                onChange={(e) => genderHandler(e)}>
                                                </Select>
                                            </div>
                                            <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                                                <label className="mb-0" htmlFor="Address">{translations.Usermanagement.edituser.labels.address} </label>
                                                <div className="w-100">
                                                    <input type="text"
                                                        id="address"
                                                        name="address"
                                                        className="form-control"
                                                        disabled
                                                        placeholder="Enter address"
                                                        value={address}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    <span className="text-danger d-block">{errors.address}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                                                <label className="mb-0" htmlFor="Country">Country </label>
                                                <Select
                                                    className="selectpicker w-100"
                                                    data-live-search="true"
                                                    id="country"
                                                    name="country"
                                                    value={country}
                                                    isDisabled 
                                                    options={options}
                                                    onChange={handleCountrySelector}
                                                //  menuIsOpen={true}
                                                />
                                            </div>
                                            <span className='text-danger d-block'>{errors.country}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 mb-5">
              <div className="card profile-card">
                <h6 className="d-flex align-items-center title mb-4"><i className="bx bx-link-alt mr-1" />Social Links</h6>
                <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                  <label className="mb-0" htmlFor="Twitter">Twitter</label>
                  <div className="w-100">
                    <input type="text"
                    className="form-control"
                    id="twitterLink"
                    name="twitterLink"
                    placeholder="Enter twitter link"
                    value={twitterLink}
                    onChange={(e) => handleChange(e)}/>
                    <span className="text-danger d-block">{errors.twitterLink}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                  <label className="mb-0" htmlFor="Facebook">Facebook</label>
                  <div className="w-100">
                    <input type="text"
                    id="fbLink"
                    name="fbLink"
                    className="form-control"
                    placeholder="Enter facebook link"
                    value={fbLink}
                    onChange={(e) => handleChange(e)}
                    />
                    <span className="text-danger d-block">{errors.fbLink}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                  <label className="mb-0" htmlFor="Instagram">Instagram</label>
                  <div className="w-100">
                    <input type="text"
                    className="form-control"
                    id="instagramLink"
                    name="instagramLink"
                    placeholder="Enter instagram link"
                    value={instagramLink}
                    onChange={(e) => handleChange(e)}/>
                    <span className="text-danger d-block">{errors.instagramLink}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                  <label className="mb-0" htmlFor="Github">Github</label>
                  <div className="w-100">
                    <input type="text"
                    className="form-control"
                    id="gitHubLink"
                    name="gitHubLink"
                    placeholder="Enter github link"
                    value={gitHubLink}
                    onChange={(e) => handleChange(e)}/>
                    <span className="text-danger d-block">{errors.gitHubLink}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                  <label className="mb-0" htmlFor="Codepen">CodePen</label>
                  <div className="w-100">
                    <input type="text"
                    className="form-control"
                    id="codePen"
                    name="codePen"
                    placeholder="Enter codepen link"
                    value={codePen}
                    onChange={(e) => handleChange(e)}/>
                    <span className="text-danger d-block">{errors.codePen}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center user-details mb-md-4 mb-3">
                  <label className="mb-0" htmlFor="Slack">Slack</label>
                  <div className="w-100">
                    <input type="text"
                    className="form-control"
                    id="slack"
                    name="slack"
                    placeholder="Enter slack link"
                    value={slack}
                    onChange={(e) => handleChange(e)}
                    />
                    <span className="text-danger d-block">{errors.slack}</span>
                  </div>
                </div>

              </div>
            </div> */}
                                </div>
                            </form>
                        </div>
                        <footer>
                            <div className="footer-text d-flex align-items-centerf justify-content-between">
                                <span className="d-block">2020 © IndiaNIC</span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </Home>
    );
}

const mapStateToProps = state => ({
    language: state.admin.language,
    UserId: state.admin.edituserId,
    UserAccess: state.admin.adminData.rolePermission.userAccess,
    // AdminUserId: state.admin.edituserId,
    // AdminUserAccess: state.admin.adminData.rolePermission.adminUserAccess
})

export default connect(mapStateToProps, { callApi })(ViewuserDetails);