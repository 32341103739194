import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import API from '../../actions/API';

function UserDetails(props){

    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [image, setImage] = useState('');
    const [noOfTasks, setNoOfTasks] = useState('');
    const [registeredOn, setRegisteredOn] = useState('');
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(props.match.params.id);

    useEffect(() => {
      getUserDetails(userId);
    }, []);

  
    //*************** Get User Details ****************/
    const getUserDetails = async (id) => {
      try {
        const response = await props.callApi(API.GET_USER + id, '', 'get', null, true);
        console.log("response get", response)
        let {data, settings} = response;
        if (response.settings.status === 1) {
          setUserName(`${data.firstName} ${data.lastName}`);
          setEmail(data.email);
          setMobile(`${data.countryCode}-${data.mobileNumber}`);
          setRegisteredOn(data.createdAt);
          setNoOfTasks(data.numberOfTasks);
          setLoading(false);
        } else {
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      } catch (error) {
        throw error;
      }
    }

    //****************** DEACTIVATE PROFILE ******************* */
    const deactivateProfileHandler = async(id) => {  
      try {

        swal({
          title: "Are you sure,You want to deactivate the profile?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async willDelete => {
          if (willDelete) {
            let body = { ids: [id] }
            const response = await props.callApi(API.DELETE_USER, body, 'POST', null, true);
            if (response.settings.status === 1) {
              toast.success('Profile deactivated successfully',{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              props.history.push('/listUsers');
            } else {
              toast.error(response.settings.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
      } catch (error) {
          throw error;
      }
    }

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Tutor</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listUsers')}>List All Users</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">View User Details</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">View Details</h5>
                    </div>
                  </div>
                  {
                    !loading ? 
                    <form action="#">
                    <div className="row">
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="userName">User Name<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{userName}</div>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{email}</div>
                      </div> 
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                          <label htmlFor="Email">Phone number<sup className="text-danger">*</sup></label>
                          <div className="each-detail">{mobile}</div>
                      </div>           
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="password">User Image<sup className="text-danger">*</sup></label>
                        <div className="custom-table-image col-md-6">
                          <img  src={"/assets/images/icon/no-image-icon-0.jpg"} alt="icon" width="70px"/>
                        </div>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="password">No of Tasks<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{noOfTasks}</div>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="password">Registered On<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{moment(registeredOn).format('L')}</div>
                        <div className="each-detail">{moment(registeredOn).format('LT')}</div>
                      </div>
                      
                    </div>
                   </form> : <div className="each-detail text-center"><h4>Loading</h4></div>
                  }
                  <div className="text-right mt-md-5 mt-3">
                    <button className="btn btn-primary btn-glow" onClick={() => deactivateProfileHandler(userId)}>Deactivate Profile</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(UserDetails);