import React, { useState,useEffect } from 'react'
import Home from '../Home'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom"
import { BeatLoader } from 'react-spinners'
import common from '../../actions/common';
import swal from 'sweetalert'
import $ from 'jquery';
import Select from 'react-select'
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import * as language from "../../translations/index";
import moment from 'moment'
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { IMG_URL } from '../../config/configs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogCategoryListing from './BlogCategoryListing';
$.DataTable = require('datatables.net');
require('datatables.net-responsive');

function CmsListing (props){

      
      const [page, setPage] = useState(1);
      const [pagesize, setPagesize] = useState(10);
      const [total, setTotal] = useState(1);
      const [blogListing, setBlogListing] = useState([]);
      const [categoryStatus, setCategoryStatus] = useState(false);
      const [condition, setCondition] = useState('');
      const [filterObj,setFilterObj] = useState([{ key: "", type: "",  value: { startDate: '', endDate: '' } }]);
      const [columnSettingsArr, setColumnSettingsArr] = useState([])
      const [sortData,sort, onSort] = useSortng();
      const [openFilter, setOpenFilter] = useState(false);
      const [loading, setLoading] = useState(true);
      const [searchText, setSearchText] = useState('');
      const [blogCategoryListing, setBlogCategoryListing] = useState([]);
    //  ****************
useEffect(() => {
  getAllBlogs();
}, []);

/************* List all blogs ************** */
const getAllBlogs = async(filterObj) => {
  var body = {page, pagesize, sort, columnKey:"blogListing", filter:filterObj ? filterObj:"" ,condition:condition}

    try {
    const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogListing(response.data.listing);
        setColumnSettingsArr(response.data.columnSettings);
        setBlogCategoryListing(response.data.listing);
        setLoading(false);
      } 
    } catch(error){
      setLoading(false);
      throw (error)
    }
}

//**************** delete cms **************** */
 const  deleteCms =  async (uid) => {
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          var body = { ids: [uid] }
          const response = await props.callApi(API.DELETE_BLOG, body, 'post', null, true);
          if (response.status === 1) {
            toast.success('Deleted successfully',{
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            getAllBlogs();
          }
        }
      });
  }

  const clearInput = () => {
    setSearchText('');
    getAllBlogs();
  }

  const searchField = async() => {
    let body = {
      page,
      pagesize,
      searchText: searchText && searchText.length > 1 ? searchText : ""
    };

    try {
      const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogListing(response.data.listing);
        setTotal(response.data.total);
      } 
    } catch (error) {
      throw(error);
    }
  }

  //***** Function to filter by some property in array of objects with unique property name ******* */
  const filterCategories = (data, key) => {
    return [
      ... new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  const filterCategoryHandler = async (e) => {
    console.log("event filter", e);
    let categoryList = [];
    if (e!== null && e.length && e.length > 0) {
      for (let i = 0; i< e.length; i++) {
        categoryList.push(e[i].label);
      }
    }
    console.log("category Arr", categoryList);

    try {
      let body = {
        blogCategory: categoryList,
        page,
        pagesize,
      }
      const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogListing(response.data.listing);
        setTotal(response.data.total);
      }
    } catch(error) {
      throw error;
    }
  }

  const renderFilter = () => {
   // value of computed filter (array of objects)
   let blogFilter = filterCategories(blogCategoryListing, id => id.blogCategory) 
   let categoryObj=[];

    for (let i = 0; i < blogFilter.length; i++) {
      categoryObj.push({
        value: blogFilter[i].blogCategory,
        label: blogFilter[i].blogCategory
      })
    }

    return (
      <div>
        <Select
          isMulti
          placeholder="Filter By Category"
          name="categories"
          options={categoryObj}
          className="basic-multi-select"
          styles={{width: "30px"}}
          classNamePrefix="select"
          onChange={e => filterCategoryHandler(e)}
        />
      </div>
    )
  }

  const translations = language[props.language !== undefined ? props.language :"en"];
//**************** */
    return (
      <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Content</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">Content</li>
                  <li className="breadcrumb-item active" aria-current="page">Blog Lists</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* filterbar buttons */}
                        
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="left-buttons d-flex align-items-center flex-wrap"> 
                          <div className="search position-relative has-icon-left mr-3">
                              <input type="search" className="form-control text-capitalize"  placeholder={`${translations.ContentManagement.CMSListing.search}`}
                              value={searchText} onChange={(evt) => {searchField(); setSearchText(evt.target.value)}}
                              />
                              {
                                  searchText && searchText.length > 0 ?
                                    <div className="clear">
                                      <span onClick={clearInput} ><em className="bx bx-x"></em></span>
                                    </div> : null
                                }
                                  <div className="form-control-position">
                                  <em className="bx bx-search"></em>
                                </div>
                          </div>
                          <div id="container1" className={openFilter ? "mr-5 filter-category custom-dropdown open" : "mr-5 filter-category custom-dropdown"}>
                              {
                                renderFilter()
                              }
                          </div>
                        </div>
                        <div className="right-buttons">
                          <Link onClick={() => { props.history.push('/addBlog') }}  id="addNewblog" type="button" className="btn glow-primary btn-primary">
                              <i className="bx bx-user-plus d-lg-none" />
                              <span className="d-none d-sm-none d-lg-inline-block">{translations.ContentManagement.CMSListing.addNewPage}</span>
                          </Link>
                        </div>
                    </div>
                    
                    <div className="listing-grid">
                      <div className="row">
                        {
                          (blogListing && blogListing.length === 0) ?
                            loading ?  <Loader/> :null 
                          : blogListing && Array.isArray(blogListing) && blogListing.map((item, key) =>{
                            return (
                              <div className="col-md-6 col-lg-4 mb-3 mb-sm-5" key={key}>
                              <Link className="d-block">
                                <div className="listing-image">
                                  <img src={IMG_URL + item.image} alt=""></img>
                                  <div className="info-details">
                                    <Link className="d-inline-block mr-2"><span className="bx bx-edit" onClick={() => { props.history.push(`/editBlog/${item._id}`); }}></span></Link>
                                    <Link className="d-inline-block mr-2"><span className="bx bx-trash-alt" onClick={() => deleteCms(item._id)}></span></Link>
                                    <Link><span className="bx bx-show-alt" onClick={() => {props.history.push(`/blogPreview/${item._id}`);}}></span></Link>
                                  </div>
                                </div>
                              
                              </Link>
                              
                                <div className="listing-details">
                                    <span className="category-name d-block mt-2 mb-2">{item.blogCategory}</span>
                                    <Link className="article-name" onClick={() => {props.history.push(`/blogPreview/${item._id}`);}}>{item.blogTitle.slice(0,30)}...</Link>
                                      <p>{item.metaDescription.slice(0,70)}...</p>
                                </div>
                               </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
        {/* Bundle Scripts */}
      </div>
      </Home>
    )
  }

const mapStateToProps = state => ({
  CmsPagesAccess: state.admin.adminData.rolePermission.cmsPagesAccess,
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(CmsListing);
