import React, { useState,useEffect } from 'react'
import Home from '../Home'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom"
import { BeatLoader } from 'react-spinners'
import common from '../../actions/common';
import swal from 'sweetalert'
import $ from 'jquery';
import _ from 'lodash';
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import * as language from "../../translations/index";
import moment from 'moment'
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { toast } from 'react-toastify';
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import { IMG_URL } from '../../config/configs';

function Subjects(props){

    const [categoryStatus, setCategoryStatus] = useState(false);
    const [subjectListing, setSubjectListing] = useState('');
    const [columnSettingsArr, setColumnSettingsArr] = useState([]);
    const [page, setPage] = useState(1);
    const [sortData,sort, onSort] = useSortng();
    const [pagesize, setPagesize] = useState(10);
    const [total, setTotal] = useState(1);
    const [multipleDelete, setMultipleDelete] = useState([]);
    const [loading, setLoading] = useState(true);
    const [blogId, setBlogId] = useState('');
    const [isFormValid, setIsFormValid] = useState('');
    const [show, setShow] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [subjectId, setSubjectId] = useState('');
    const [searchText, setSearchText] = useState('');
    let [errors, setErrors] = useState('');
    
    const [subjects, setSubjects] = useState('');
    const [department, setDepartment] = useState('');
    const [departmentListing, setDepartmentListing] = useState('');
    const [image, setImage] = useState('');
    const [imageName, setImageName] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [showSelect, setShowSelect] = useState(true);
    const [options, setOptions] = useState([]);
    
    useEffect(() => {
      getAllSubjects();
      getDepartments();
      setShow(false);
    }, []);

    useEffect(() => {
      if (props.admindata && !_.isEmpty(props.admindata)) {
        let { firstname, lastname, _id } = props.admindata;
      }
      
    }, []);
    
    const getAllSubjects = async() => {
      let body = {
        page, 
        pagesize, 
        sort, 
        columnKey:"subjectListing"
      };
      try {
      const response = await props.callApi(API.LIST_SUBJECT, body, 'post', null, true);
        if (response.settings.status === 1) {
          setSubjectListing(response.data.listing);
          // setColumnSettingsArr(response.data.columnSettings);
          setTotal(response.data.total);
          setLoading(false);
        } 
      } catch(error){
        setLoading(false);
        throw (error)
      }
    }
 
 //******************** Image Upload ****************** 
 const fileUpload = async (e) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('fileMode', 'image');
  const response = await props.callApi(API.FILE_UPLOAD, formData, 'post', null, true, true);
  if (response.settings.status === 1) {
    setSelectedImage(response.data.filePath);
  }
}

  //**************** VALIDATE FORM **************** */
  const  validateForm = () => {
    let errors  = { subjects : "" , department:"", image: ""};
    let isFormValid = true;
    
    if (!subjects.trim()) errors.subjects = '*Subject is required'
    else if(subjects.length < 2) errors.subjects = "*Subject should be minimum 2 characters"
    else errors.subjects = '';

    if (!department) errors.department = '*Department is required'
    else errors.department = '';

    if (subjectId) {
      errors.image = ''
    } else {
      if (!imageName) errors.imageName = "*Image is required"
      else errors.imageName = '';
    }

    if (errors.subjects !== '' || errors.department !== '' || errors.image !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
  }

  //***************** GET WORK TYPE ******************* */
  const getSubject = async(id) => {
   setShow(true);
   setEditCategory(true);
    try {
    const response = await props.callApi(API.GET_SUBJECT + id, '', 'get', null, true);
    let {data, settings} = response;
    console.log("resposne get", response);
      if (settings.status === 1) {
        setDepartment({value: data.department._id, label: data.department.name});
        setSubjects(data.name);
        setSelectedImage(`admin/${data.image}`);
      }
    } catch(error) {
      throw error;
    }
  }

  //***************** Image Selector ***************** */
  const selectImageHandler = async (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    // console.log("image handler", file);
    setImageName(file.name);
    setImage(file);
    reader.readAsDataURL(file);
    Object.assign(errors, {image: ""})
  }

  //**************** EDIT SUBJECTS ******************* */
  const updateSubjectHandler = async(id) => {
    if(validateForm()){
      let body = {
          department: department.value,
          name: subjects,
          image: selectedImage
      };

      try{
          const response = await props.callApi(API.UPDATE_SUBJECT + id, body, 'PUT', null, true);
          if (response.settings.status === 1) {
            toast.success('Subject updated successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShow(false);
          }else {
            toast.error(response.settings.message,{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShow(false)
          }
          getAllSubjects();
      } catch(error) {
        throw(error)
      }
    }
  }

    //************* DELETE SUBJECTS ********************/
    const  deleteWorkTypeHandler  = async (id) => {
      var delArr = multipleDelete;
      delArr = _.compact(delArr)
      swal({
        title: "Are you sure, you want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { ids: delArr }
          }
          else {
            body = { ids: [id] }
          }
          const response = await props.callApi(API.DELETE_SUBJECT, body, 'post', null, true);
          if (response.settings.status === 1) {
            toast.success('Subject deleted successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAllSubjects()
          }
        }
      });
    }
    
    //************ ADD SUBJECTS ***************** */
    const addSubjectHandler = async(e) => {
      setShow(true);
      e.preventDefault();
      if(validateForm()){
        let body;
        console.log("selected Image", selectedImage);
        if(selectedImage) {
          body = { 
            department: department.value,
            name: subjects,
            image: selectedImage
          };
        } else {
          body = { 
            department: department.value,
            name: subjects
          };
        }

        console.log("body", body)
        try{
            const response = await props.callApi(API.ADD_SUBJECT, body, 'post', null, true);
            console.log("Response add", response);
            if (response.settings.status === 1) {
              toast.success('Subject added successfully',{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShow(false)
            } else {
              toast.error(response.settings.message,{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShow(false)
            }
            getAllSubjects();
        } catch(error) {
            throw(error)
        }
      }
    }

    const statusHandler = async (status, id) => {
      setCategoryStatus(current => !current);
      try {
        const response = await props.callApi(API.SUBJECT_STATUS + id, '', 'GET', null, true);
          if(response.settings.status === 1) {
            toast.success('Status updated successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }else {
            toast.error(response.settings.message,{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShow(false)
          }
        getAllSubjects();
      } catch (error) {
          throw error
      }
    }

    const modelCloseBtn = () => {
      setShow(false);
      setSubjectId("");
      setDepartment("");
      setSubjects("");
      setImage("");
      setImageName("");
      setSelectedImage("");
      setErrors("");
    }

    const modalAddCategory = () => {
      setShow(true);
      setSubjectId(""); 
      setDepartment("");
      setSubjects("");
      setImage("");
      setImageName("");
      setSelectedImage("");
      setErrors("");
    }

    
    const addUpdateSubjects = (e) => {
      subjectId ? updateSubjectHandler(subjectId) :  addSubjectHandler(e);
    }

    const clearInput = () => {
      setSearchText("");
      getAllSubjects();
    }

    const CategoryPagination = (page, pageSize) => {
      setPage(page);
      setPagesize(pageSize);
    }

    //*********** GET DEPARTMENT NAMES FOR SELECTING DEPARTMENT ************** */
    const getDepartments = async() => {
        let body = {
          page, 
          pagesize,  
        };
        const tempOptions = [];
        try {
        const response = await props.callApi(API.LIST_DEPARTMENT, body, 'post', null, true);
          if (response.settings.status === 1) {
            for(let i = 0; i < response.data.listing.length; i++) {
              tempOptions.push({
                value: response.data.listing[i]._id,
                label: response.data.listing[i].name
              })
            }
            setOptions(tempOptions);
            setTotal(response.data.total);
          } 
        } catch(error){
          throw (error)
        }
    }

    //************** SEARCH FIELD HANDLER *************** */
    const searchField = async() => {

      let body = {
        page,
        pagesize,
        searchText: searchText && searchText.length > 1 ? searchText : ""
      };
  
      try {
        const response = await props.callApi(API.LIST_SUBJECT, body, 'POST', null, true);
        let {data, settings} = response;
        if (settings.status === 1) {
          setSubjectListing(data.listing);
          setTotal(data.total);
        } else {
          toast.error(settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        throw(error);
      }
    }

    //************** CLEAR SEARCH BOX *************** */
    const clearSearchBox = () => {
      setSearchText("");
      getAllSubjects();
    }

    return ( 
      <Home>
        <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">Master Data</li>
                  <li className="breadcrumb-item active" aria-current="page">Subjects</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* filterbar buttons */}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder="search"
                            value = {searchText} onChange={(evt) => {searchField(); setSearchText(evt.target.value)}}
                            />
                              {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearSearchBox} ><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                        <Button onClick={modalAddCategory}  id="addNewCategory" type="button" className="btn glow-primary btn-primary">
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">Add New Subject</span>
                        </Button>
                    </div>
                   
                    {/* filter bar buttons end */}
                    {/* datatable start */}
                    <Modal show={show} onHide={modelCloseBtn} >
                        <Modal.Header closeButton>
                          <div className="d-flex align-items-center">
                              <div className="icon mr-2"><span className='bx bxs-plus-circle'></span></div>
                              <h5 className="modal-title" id="ubject">{subjectId ? "Update Subject" : "Add Subject"}</h5>
                          </div>
                        </Modal.Header>
                        <Modal.Body closeButton>
                          <div className="notification-form">
                              <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group mb-md-5 mb-3">
                                      <label htmlFor="subjects">Select Department<sup className="text-danger">*</sup></label>
                                        <Select className="selectpicker w-100"
                                          id="department"
                                          name="department"
                                          placeholder = "Select Department"
                                          value={department}
                                          options={options}
                                          onChange={(e) => {setDepartment(e); errors = Object.assign(errors, { department: "" }); setErrors(errors);}}>
                                        </Select>                                          
                                      <span className="text-danger d-block">{errors.department}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="subjects">Enter Subject<sup className="text-danger">*</sup></label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="subjects" 
                                        name="subjects"
                                        placeholder="subject" 
                                        value={subjects}
                                        onChange={(e) => {setSubjects(e.target.value); errors=Object.assign(errors, { subjects: "" }); setErrors(errors);}}
                                        />
                                        <div className="text-danger d-block">{errors.subjects}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="button-continer pull-right my-3">
                                    {/* <label htmlFor="image">Add Image<sup className="text-danger">*</sup></label> */}
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="imageselect-text">Add Image</span>
                                        <label className="img-upload-square sm ml-2">
                                        <input type="file" className="form-control-file text-primary d-none" name='image' id="file" accept="image/*"
                                        data-title="Drag and drop a file" onChange={selectImageHandler} />
                                        <em className="bx bxs-edit-alt position-relative"></em>
                                        </label>
                                        <label>{imageName ? imageName : null}</label>
                                        <div className="text-danger d-block">{errors.image}</div>
                                    </div>
                                    <button className="btn btn-primary" type="button" onClick={fileUpload}> Upload Image</button>
                                    </div>
                                    <div className="form-group mb-0 mt-3">
                                    <label></label>
                                    </div>
                                    <div className="row justify-content-between my-2"> 
                                      <div className="col-md-6 select-image">
                                        <div className="custom-select-image">
                                          <img src = {
                                              !selectedImage ? 
                                              "/assets/images/icon/no-image-icon-0.jpg" :
                                                (!selectedImage.includes("undefined")) ? 
                                                  IMG_URL + selectedImage : 
                                                    "/assets/images/icon/no-image-icon-0.jpg"
                                            } 
                                            alt="icon" 
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <span className="text-danger d-block">errors</span> */}
                                  </div>
                                  <div className="col-md-6">
                                    <div className="modal-btn">
                                        <Link onClick={e => addUpdateSubjects(e)} className="btn btn-primary"> {subjectId ? "Update" : "Add"} </Link>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <Button  id="uploadCSV" type="button" className="btn glow-primary btn-primary mr-2">
                                        <i className="bx bx-user-plus d-lg-none" />
                                        <span className="d-none d-sm-none d-lg-inline-block">Upload Via CSV</span>
                                    </Button>
                                  </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal> 
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          <th className="all"><b>Subject</b></th>
                          <th className="all"><b>Department</b></th> 
                          <th className="all"><b>Image</b></th>
                          <th className="all"><b>Status</b></th> 
                          <th className="all"><b>Action</b></th>
                        </tr>
                      </thead>
                      <tbody>
                         {
                            loading ? 
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="5"><Loader/>
                              </td>
                            </tr>
                            :
                            (subjectListing && subjectListing.length !== 0) ?
                            subjectListing && Array.isArray(subjectListing) && subjectListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    <td>{item.name}</td>
                                    <td>{item.department.name}</td>
                                    <td>
                                      <div className="custom-table-image col-md-6">
                                        <img  src={item.image && item.image !== "undefined" ? IMG_URL + 'admin/' + item.image : "/assets/images/icon/no-image-icon-0.jpg"} alt="icon" width="70px"/>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="custom-control custom-switch light">
                                          <input type="checkbox" className="custom-control-input"
                                              id={item._id} 
                                              checked={item.status ? item.status : false} 
                                              onChange={() => {item.status = !item.status; statusHandler(item.status, item._id)}}
                                              />
                                          <label className="custom-control-label"
                                              htmlFor={item._id}></label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                          <Link className="cursor-pointer mr-3" ><i className='bx bx-edit' onClick={() => {getSubject(item._id); setSubjectId(item._id)}}/></Link>
                                          
                                          <Link className="cursor-pointer mr-3" ><i className="bx bx-trash-alt" onClick={() => deleteWorkTypeHandler(item._id)}/></Link>   
                                      </div>
                                    </td>
                                </tr>
                              )
                            }) :
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="5">
                              <h6 className="text-center text-danger not-found-txt" colSpan="5">No Records found!</h6>
                              </td>
                            </tr>
                          }
                      </tbody>
                    </table>
                    {/* <div className="mt-3">
                      <Pagination  
                      pageSize={pagesize} 
                      current={page} 
                      total={total} 
                      showPrevNextJumpers={false} 
                      onChange={CategoryPagination}
                      />
                    </div> */}
                    {/* datatable ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
        {/* Bundle Scripts */}
      </div>
      </Home>
     );
  }

  const mapStateToProps = state => ({
    admindata: state.admin.adminData,
    language: state.admin.language
  });

  export default connect(mapStateToProps, { callApi })(Subjects);