export const po = {
  CustomLabels: {
    dashboard: "painel de controle",
    users: "Comercial",
    content: "conteúdo",
    emailTemplateslabel: "Notificação de Email",
    master: "mestre",
    roles: "Transcação",
    Transcation: "Transcação",
    media: "meios de comunicação",
    support: " Apoio, suporte",
    settings: "definições",
    smtp: "SMTP",
    payment: "Detalhes do SDK do gateway de pagamento",
    blogs: "blogs",
    adminuser: "Usuários admin",
    staticpages: "Statische Seiten",
    faq: "Perguntas frequentes",
    generalsettings: "Configurações Gerais",
    SocialSdk: "Detalhes do SDK de mídia social",
    socilamedia: "Mídia social",
    SocialLinks: "Links de mídia social",
    paymentGateway: "Detalhes SDK do gateway de pagamento",
    emailNotification: "Configurações de notificação por email"
  },
  MasterMangement: {
    master: "Mistrz",
    manageMaster: "Zarządzaj Master",
    addCountry: "Dodaj kraj",
    countryName: "Nazwa kraju",
    countryCode: "Kod pocztowy",
    phoneCode: "Kod telefonu",
    add: "Dodaj",
    addCountryTimeZone: "Dodaj strefę czasową kraju",
    timeZone: "Strefa czasowa",
    addCurrency: "Dodaj walutę kraju",
    currency: "Waluta",
    status: "Status",
    action: "Akcja",
    noRecord: "Nic nie znaleziono!"
  },

  ContentManagement: {
    CMS: {
      edit: "Editar Página",
      add: "Adicionar Página",
      content: "conteúdo",
      category: "Categoria",
      title: "Título",
      slug: "lesma",
      metaKeywords: "Meta Palavras-chave",
      metaDescription: "Meta Descrição",
      blogDescription: "Descrição do blog",
      image: "Imagem",
      caption: "rubrica",
      postDate: "Data de postagem",
      tag: "Tag",
      status: "Status",
      author: "autor"
    },

    CMSListing: {
      blog: "Blogs",
      addNewPage: "Adicionar nova página",
      articleName: "Nome do artigo",
      category: "Categoria",
      created: "Criada",
      status: "Status",
      action: "Açao",
      noRecord: "Nenhum registro foi encontrado!",
      search: "Pesquisa"
    },

    FAQ: {
      content: "conteúdo",
      faq: "FAQ",
      faqList: "Lista de FAQ",
      faqCategory: "FAQ Kategorie",
      addCategory: "Categoria FAQ",
      addNewFAQ: "Adicionar novo FAQ",
      category: "Categoria",
      question: "Questão",
      answer: "Responda",
      action: "Açao",
      search: "Pesquisa"
    }
  },

  Usermanagement: {
    edituser: {
      labels: {
        userprofile: "Perfil de usuário",
        firsname: "Primeiro nome",
        lastname: "Último nome",
        email: "O email",
        company: "Companhia",
        status: "Status",
        phoneNumber: "Número de telefone",
        birthdate: "Data de nascimento",
        website: "Local na rede Internet",
        gender: "Gênero",
        address: "Endereço",
        country: "Country"
      },
      buttons: {
        save: "Salve",
        saving: "Salvando",
        delete: "Delete"
      }

    },
  }
};