export const fr = {
  CustomLabels: {
    dashboard: "tableau de bord",
    users: "utilisateurs",
    content: "contenu",
    emailTemplateslabel: "Courriel et notification",
    master: "maître",
    roles: "rôles",
    Transcation: "Transcation",
    media: "médias",
    support: "soutien",
    settings: "réglages",
    smtp: "SMTP",
    payment: "Détails du SDK de la passerelle de paiement",
    blogs: "blogs",
    adminuser: "Utilisateurs admin",
    staticpages: "Pages statiques",
    faq: "FAQs",
    generalsettings: "réglages généraux",
    socilamedia: "Des médias sociaux",
    SocialSdk: "Détails du SDK de médias sociaux",
    SocialLinks: "Liens de médias sociaux",
    paymentGateway: "Détails du SDK de la passerelle de paiement",
    emailNotification: "Paramètres de notification par e- mail"

  },
  MasterMangement: {
    master: "maître",
    manageMaster: "Gérer le maître",
    addCountry: "Ajouter un pays",
    countryName: "Nom du pays",
    countryCode: "Code postal",
    phoneCode: "Code de téléphone",
    add: "ajouter",
    addCountryTimeZone: "Ajouter la zone horaire du pays",
    timeZone: "Fuseau horaire",
    addCurrency: "Ajouter la devise du pays",
    currency: "Devise",
    status: "Statut",
    action: "Action",
    noRecord: "Aucun enregistrement trouvé!"
  },

  ContentManagement: {
    CMS: {
      edit: "Modifier la page",
      add: "Ajouter une page",
      content: "contenu",
      category: "Catégorie",
      title: "Titre",
      slug: "limace",
      metaKeywords: "Meta Keywords",
      metaDescription: "Meta Description",
      blogDescription: "Description du blog",
      image: "Image",
      caption: "Caption",
      postDate: "Date de publication",
      tag: "Marque",
      status: "Statut",
      author: "auteur"
    },

    CMSListing: {
      blog: "Blogs",
      addNewPage: "Ajouter une nouvelle page",
      articleName: "Nom d'article",
      category: "Catégorie",
      created: "Créé",
      status: "Statut",
      action: "action",
      noRecord: "Aucun enregistrement trouvé!",
      search: "Chercher"
    },

    FAQ: {
      content: "contenu",
      faq: "FAQs",
      faqList: "Liste des FAQs",
      faqCategory: "FAQ Catégorie",
      addCategory: "ajouter une catégorie",
      addNewFAQ: "Ajouter une nouvelle FAQ",
      category: "Catégorie",
      question: "Question",
      answer: "Répondre",
      action: "action",
      search: "Chercher"
    }
  },

  Usermanagement: {
    edituser: {
      labels: {
        userprofile: "Prénom",
        firsname: "PRÉNOM",
        lastname: "Nom de famille",
        email: "Email",
        company: "Entreprise",
        status: "Statut",
        phoneNumber: "Numéro de téléphone",
        birthdate: "Date de naissance",
        website: "WebSite Internetsite",
        gender: "Le genre",
        address: "Adresse",
        country: "Country"
      },
      buttons: {
        save: "sauvegarder",
        saving: "Économie",
        delete: "Delete"
      }

    },
  }

};