import { useEffect, useState } from 'react';

export const useSortng = () => {
  const [sortData, setSortData] = useState({firstName: false, lastName: false, mobile: false, emailId: false,role: false ,templateTitle: false, emailKey: false, subject: false
    ,countryCode: false, countryName: false, phoneCode: false, timezone: false, currency: false
}); 
  const [sort, setSort] = useState({ 'rate': 1 }); 


    //************************ SORTING **************************************************//
    const  onSort = (column) => {
      var element, value;
      for (const key in sortData) {
        if (key == column) {
          sortData[key] = !sortData[key];
          element = key;
          value = -1
          if (sortData[key]) {
            value = 1
          }
          setSort({ [element]: value })
          setSortData(sortData)
        }
        else {
          sortData[key] = false
          // element = key;
          // value = 1;
        }
      }
      setSortData(sortData)
    }


  return [sortData,sort, onSort]
};