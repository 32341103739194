import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import common from '../../actions/common' // Validations
import API from '../../actions/API';
import { callApi } from '../../actions/commonFunction'; // COMMON FUNCTIONS


import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { compose } from 'redux';


import katex from 'katex'
import 'katex/dist/katex.min.css'

class BlogEditorAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog_content: "",
            mountedEditor: false,
            isRoleAdmin: false,
            isVisible: false
        };
        this.formats = [
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
            "align",
            "color",
            "background",
            "link"
        ];

        this.state = {
            data: {},
            errors: {
                blog_content: "",

            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextprops) {

        // if (nextprops & nextprops.editContent) {
        // console.log(nextprops.editContent)
        this.setState({
            data: Object.assign(this.state.data, {
                blog_content: nextprops.editContent
            })
        });
        // console.log(nextprops.editContent)
        // }
    }


    updateContent(newContent) {
        this.setState({
            blog_content: newContent
        });
    }

    handleChange = html => {
        this.setState({
            data: Object.assign(this.state.data, {
                blog_content: html
            })
        });
        this.props.getPreview(html)
    };


    render() {

        // console.log("aca", this.state.blog_content, this.state.data)
        return (
            <div>
                <SunEditor name="blog_content"
                    insertHTML={this.state.blog_content}
                    onChange={this.handleChange}
                    //defaultValue={this.state.blog_content} 
                    placeholder={this.props.placeholder}
                    setOptions={{
                        height: 350,
                        buttonList: [
                            ['undo', 'redo',
                                'font', 'fontSize', 'formatBlock',
                                'paragraphStyle', 'blockquote',
                                'bold', 'underline', 'italic', 'subscript', 'superscript',
                                'outdent', 'indent',
                                'align', 'horizontalRule', 'lineHeight',
                                'table', 'list',
                                'link', 'image', /** 'math', */ // You must add the 'katex' library at options to use the 'math' plugin.
                                /** 'imageGallery', */ // You must add the "imageGalleryUrl".
                                'fullScreen', 'showBlocks', 'codeView',
                                'preview', 'print', 'save',
                            ]
                        ],
                        controller: [
                            'remove', 'resize100', 'maxSize',
                        ],
                        width: 'auto',
                        maxWidth: '1100px',
                        minWidth: '1100px',
                        // maxHeight: '300px',
                        minHeight: '300px',
                        height: 'auto',
                    }} />


            </div >

        );
    }
}

const mapStateToProps = state => ({
    profile: state.auth.user,
    auth: state.auth,
    access_permissions: state.auth.access_permissions
});

export default BlogEditorAdd;
