import React, { Component } from 'react';
import Home from '../Home';
function State(props){
    return ( 
      <Home>
        <div>
          <img src="/assets/images/comingsoon.jpg" alt="icon" />
        </div>
      </Home>
     );
  }

 
export default State;