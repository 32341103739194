import { useState, useEffect } from 'react';
import common from "./common";
import axios from 'axios';
import { API_URL } from '../config/configs'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && error.response && 401 === error.response.status) {
    swal('Token Expired', "", "error")
    localStorage.removeItem('accessToken', '')
    window.location.href = '/'
  } else {
    return Promise.reject(error);
  }
});
/*******************************************************************************************
     @PURPOSE      	: 	Call api.
     @Parameters 	: 	{
       url : <url of api>
       data : <data object (JSON)>
       method : String (get, post)
       isForm (Optional) : Boolean - to call api with form data
       isPublic (Optional) : Boolean - to call api without auth header
     }
     /*****************************************************************************************/
/**
 *  <url of api>
 * @param {string} url =>  
 * @param {*} data 
 * @param {*} method 
 * @param {*} Type 
 * @param {*} isAuthorized 
 * @param {*} isForm 
 */

export const callApi = (url, data, method, Type = null, isAuthorized = false, isForm = false) => (dispatch) => {
  url = API_URL + url;
  let headers = { 'Content-Type': 'application/json' };
  if (isAuthorized) headers.Authorization = common.getItem('accessToken');
  if (isForm) headers['Content-Type'] = 'multipart/form-data';
  // Api calling
  return new Promise((resolve, reject) => {
    axios({ method, url, headers, data })
      .then(response => {
        let { data } = response;
        if (response && data.status === 1) {
          if (Type !== null) {
            var payload = { data: data.data }
            dispatch({ type: Type, payload });
          }
          // typeof (data.message ? data.message : 'success') === "string" ? common.showSuccessMessage(data.message ? data.message : 'success') : common.showWarningMessage("Something went wrong! ")
        } else {
          // typeof (data.message ? data.message : 'error') === "string" ? common.showErrorMessage(data.message ? data.message : 'error') : common.showWarningMessage("Something went wrong! ")
        }
        resolve(data);
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reject(error);
      });
  })
}
/*****************************************************************************************/

//**************************** EDIT USERLIST DATA ********************************************/

export const editUser = (id) => async dispatch => {
  dispatch({ type: "EDIT_USER", payload: id });
};

export const editEmail = (id) => async dispatch => {
  dispatch({ type: "EDIT_EMAIL", payload: id });
};
const languageKey = "language";
const themeKey = "theme";
const resizeKey = "resize";
const sidebarKey = "sidebar"


export const changeLanguage = body => async dispatch => {
  localStorage.setItem(languageKey, body.language);
  const payload = {
    data: body.language
  };
  await dispatch({ type: "language", payload });

};



export const changeTheme = body => async dispatch => {
  localStorage.setItem(themeKey, body);
  const payload = {
    data: body
  };
  await dispatch({ type: "theme", payload });
};


export const sidebaropen = body => async dispatch => {
  localStorage.setItem(sidebarKey, body);
  const payload = {
    data: body
  };
  await dispatch({ type: "sidebar", payload });
};




export const changeResize = body => async dispatch => {
  localStorage.setItem(resizeKey, body);
  const payload = {
    data: body
  };
  await dispatch({ type: "resize", payload });
};




export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};