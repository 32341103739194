import React, { useState,useEffect } from 'react'
import Home from '../Home'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom"
import { BeatLoader } from 'react-spinners'
import common from '../../actions/common';
import swal from 'sweetalert'
import $ from 'jquery';
import _ from 'lodash';
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import * as language from "../../translations/index";
import moment from 'moment'
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
$.DataTable = require('datatables.net');
require('datatables.net-responsive');

function BlogCategory(props){

    const [blogCategoryTitle, setBlogCategoryTitle] = useState('');
    const [blogCategoryDescription, setBlogCategoryDescription] = useState('');
    const [categoryStatus, setCategoryStatus] = useState(false);
    const [blogCategoryListing, setBlogCategoryListing] = useState('');
    const [createdById, setCreatedById] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [columnSettingsArr, setColumnSettingsArr] = useState([]);
    const [page, setPage] = useState(1);
    const [sortData,sort, onSort] = useSortng();
    const [pagesize, setPagesize] = useState(10);
    const [total, setTotal] = useState(1);
    const [multipleDelete, setMultipleDelete] = useState([]);
    const [condition, setCondition] = useState('');
    const [loading, setLoading] = useState(true);
    const [blogId, setBlogId] = useState('');
    const [isFormValid, setIsFormValid] = useState('');
    const [show, setShow] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const translations = language[props.language !== undefined ? props.language :"en"];
    const [blogCategoryId, setBlogCategoryId] = useState('');
    const [searchText, setSearchText] = useState('');
    let [errors, setErrors] = useState('');

    useEffect(() => {
      getAllBlogCategory();
      setShow(false);
    }, []);

    useEffect(() => {
      if (props.admindata && !_.isEmpty(props.admindata)) {
        let { firstname, lastname, _id } = props.admindata;
        setCreatedBy(firstname + " " + lastname);
        setCreatedById(_id);
      }
      
    }, []);
    
    const getAllBlogCategory = async() => {
      let body = {
        page, 
        pagesize, 
        sort, 
        columnKey:"blogCategoryListing",
        condition:condition
      };
      try {
      const response = await props.callApi(API.LIST_BLOG_CATEGORY, body, 'post', null, true);
        if (response.status === 1) {
          setBlogCategoryListing(response.data.listing);
          setColumnSettingsArr(response.data.columnSettings);
          setTotal(response.total);
          setLoading(false);
        } 
      } catch(error){
        setLoading(false);
        throw (error)
      }
    }

  //**************** VALIDATE FORM **************** */
  const  validateForm = () => {
    let errors  = { blogCategoryTitle : "", blogCategoryDescription: ""};
    let isFormValid = true;
    
    if (!blogCategoryTitle.trim()) errors.blogCategoryTitle = '*Blog category is required'
    else if(blogCategoryTitle.length < 2) errors.blogCategoryTitle = "*Blog category should be minimum 2 characters"
    else errors.blogCategoryTitle = '';

    if (!blogCategoryDescription.trim()) errors.blogCategoryDescription = '*Blog description is required'
    else if(blogCategoryDescription.length < 20) errors.blogCategoryDescription = "*Blog description should be minimum 20 characters"
    else errors.blogCategoryDescription = '';


    if (errors.blogCategoryTitle !== '' || errors.blogCategoryDescription !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
  }

  //***************** GET BLOG CATEGORY ******************* */
 const getBlogCategory = async(id) => {
   setShow(true);
   setEditCategory(true);
    try {
    const response = await props.callApi(API.GET_BLOG_CATEGORY_DETAILS + id, '', 'get', null, true);
      if (response.status === 1) {
        let {
          blogCategoryTitle,
          blogCategoryDescription,
        } = response.data;
        
        setBlogCategoryTitle(blogCategoryTitle);
        setBlogCategoryDescription(blogCategoryDescription);
        setBlogId(id);
      }
    } catch(error) {
      throw error;
    }
  }

  //**************** EDIT BLOG CATEGORY ******************* */
  const updateCategoryHandler = async(id) => {
    if(validateForm()){
      let body = {
          id,
          blogCategoryTitle: blogCategoryTitle,
          blogCategoryDescription: blogCategoryDescription
      };

      try{
          const response = await props.callApi(API.ADD_UPDATE_BLOG_CATEGORY, body, 'post', null, true);
          if (response.status === 1) {
            toast.success('Category updated successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShow(false);
            getAllBlogCategory();
          }
      } catch(error) {
        throw(error)
      }
    }
  }

    //************* DELETE BLOG ********************/
    const  deleteCategoriesHandler  = async (bId) => {
      var delArr = multipleDelete;
      delArr = _.compact(delArr)
      swal({
        title: "Are you sure, you want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async willDelete => {
        if (willDelete) {
          if (delArr.length > 0) {
            var body = { ids: delArr }
          }
          else {
            body = { ids: [bId] }
          }
          const response = await props.callApi(API.DELETE_BLOG_CATEGORY, body, 'post', null, true);
          if (response.status === 1) {
            toast.success('Category deleted successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAllBlogCategory()
          }
        }
      });
    }
    
    //************ ADD BLOG CATEGORY ***************** */
    const addCategoryHandler = async(e) => {
      setShow(true);
      e.preventDefault();
      if(validateForm()){
        let body = { 
            blogCategoryTitle: blogCategoryTitle,
            blogCategoryDescription: blogCategoryDescription,
            // status: categoryStatus,
            createdBy: createdBy,
            createdById: createdById
        };
        
        try{
            const response = await props.callApi(API.ADD_UPDATE_BLOG_CATEGORY, body, 'post', null, true);
            if (response.status === 1) {
              toast.success('Category added successfully',{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShow(false)
            }
            getAllBlogCategory();
        } catch(error) {
            throw(error)
        }
      }
    }

    const blogStatusHandler = async (status, id) => {
      setCategoryStatus(current => !current);
      let body = {
        ids: [id],
        status: status
      };

      const response = await props.callApi(API.CHANGE_BLOG_CATEGORY_STATUS, body, 'post', null, true);
      if(response.status === 1) {
        toast.success('Status updated  successfully',{
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllBlogCategory();
      }
    }

    const modelCloseBtn = () => {
      setShow(false);
      setErrors("");
    }

    const modalAddCategory = () => {
      setShow(true); 
      setBlogCategoryTitle("");
      setBlogCategoryDescription("");
      setBlogCategoryId("");
    }

    const addUpdateCategory = (e) => {
      blogCategoryId ? updateCategoryHandler(blogCategoryId) :  addCategoryHandler(e);
    }

    const clearInput = () => {
      setSearchText("");
      getAllBlogCategory();
    }

    const searchField = async() => {
      let body = {
        page,
        pagesize,
        searchText: searchText && searchText.length > 1 ? searchText : ""
      };

      try {
        const response = await props.callApi(API.LIST_BLOG_CATEGORY, body, 'post', null, true);
        if (response.status === 1) {
          setBlogCategoryListing(response.data.listing);
          setTotal(response.data.total);
        } 
      } catch (error) {
        throw(error);
      }
    }

    const CategoryPagination = (page, pageSize) => {
      setPage(page);
      setPagesize(pageSize);
    }

    return ( 
      <Home>
        <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Content</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">Content</li>
                  <li className="breadcrumb-item active" aria-current="page">Blog Category</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                  <div className="grid-content">
                    {/* filterbar buttons */}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder="search"
                            value = {searchText} onChange={(evt) => {searchField(); setSearchText(evt.target.value)}}
                            />
                              {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearInput} ><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                        <Button onClick={modalAddCategory}  id="addNewCategory" type="button" className="btn glow-primary btn-primary">
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">Add Category</span>
                        </Button>
                    </div>
                   
                    {/* filter bar buttons end */}
                    {/* datatable start */}
                    <Modal show={show} onHide={modelCloseBtn} >
                        <Modal.Header closeButton>
                          <div class="d-flex align-items-center">
                              <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                              <h5 class="modal-title" id="exampleModalLongTitle">{blogCategoryId ? "Update Category" : "Add Category"}</h5>
                          </div>
                        </Modal.Header>
                        <Modal.Body closeButton>
                          <div class="notification-form">
                              <div class="row">
                                  <div class="col-md-12">
                                    <div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="Title">{translations.ContentManagement.CMS.category}<sup className="text-danger">*</sup></label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="blogCategoryTitle" 
                                        name="blogCategoryTitle"
                                        placeholder="Add Category" 
                                        value={blogCategoryTitle}
                                        onChange={(e) => {setBlogCategoryTitle(e.target.value); errors=Object.assign(errors, { blogCategoryTitle: "" }); setErrors(errors);}}
                                        />
                                        <div className="text-danger d-block">{errors.blogCategoryTitle}</div>
                                    </div>
                                    <div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="Title">Description<sup className="text-danger">*</sup></label>
                                        <textarea 
                                        type="text" 
                                        className="form-control" 
                                        id="blogCategoryDescription" 
                                        name="blogCategoryDescription"
                                        placeholder="Add Description" 
                                        value={blogCategoryDescription}
                                        onChange={(e) => {setBlogCategoryDescription(e.target.value); errors=Object.assign(errors, { blogCategoryDescription: "" }); setErrors(errors);}}
                                        />
                                        <span className="text-danger d-block">{errors.blogCategoryDescription}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div class="modal-btn">
                                        <Link onClick={e => addUpdateCategory(e)} class="btn btn-primary"> {blogCategoryId ? "Update" : "Add"} </Link>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal> 
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          <th className="all">Category</th>
                          <th className="all">Description</th>
                          <th className="all">Count</th>
                          <th className="all">Add blog</th>
                          <th className="all">Status</th> 
                          <th className="all">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         {
                            (blogCategoryListing && blogCategoryListing.length === 0)
                                ?
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                { loading  ?  <Loader/>    :null }

                                {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">No Records found!</h6> : null}
                              </td>
                            </tr>
                            :
                            blogCategoryListing && Array.isArray(blogCategoryListing) && blogCategoryListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    <td>{item.blogCategoryTitle}</td>
                                    <td>{item.blogCategoryDescription}</td>
                                    <td>{item.count}</td>
                                    <td>
                                    <div className="modalTitle" onClick={() => props.history.push("/addBlog")}><span class='bx bxs-plus-circle'></span></div>
                                    </td>
                                    <td>
                                      <div className="custom-control custom-switch light">
                                          <input type="checkbox" className="custom-control-input"
                                              id={item._id} 
                                              checked={item.status ? item.status : false} 
                                              onChange={() => {item.status = !item.status; blogStatusHandler(item.status, item._id)}}
                                              />
                                          <label className="custom-control-label"
                                              for={item._id}></label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                          <Link className="cursor-pointer mr-3" ><i class='bx bx-edit' onClick={() => {getBlogCategory(item._id); setBlogCategoryId(item._id)}}/></Link>
                                          
                                          <Link className="cursor-pointer mr-3" ><i className="bx bx-trash-alt" onClick={() => deleteCategoriesHandler(item._id)}/></Link>   
                                      </div>
                                    </td>
                                </tr>
                              )
                            })
                          }
                      </tbody>
                    </table>
                    <div className="mt-3">
                      <Pagination  
                      pageSize={pagesize} 
                      current={page} 
                      total={total} 
                      // showPrevNextJumpers={false} 
                      onChange={CategoryPagination}
                      />
                    </div>
                    {/* datatable ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
        {/* Bundle Scripts */}
      </div>
      </Home>
     );
  }

  const mapStateToProps = state => ({
    admindata: state.admin.adminData,
    language: state.admin.language
  });

  export default connect(mapStateToProps, { callApi })(BlogCategory);