import React, { useEffect, useState, useRef} from 'react';
import Home from '../Home'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Link, withRouter} from "react-router-dom"
import common from '../../actions/common';
import Select from "react-select";
import API from '../../actions/API';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import * as language from "../../translations/index";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {IMG_URL} from '../../config/configs';
import parser from 'html-react-parser';
import moment from 'moment';
import {useSortng} from '../CommonFeature/Sorting';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// import CKFinder from '@ckeditor/ckeditor5-ckfinder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogEditorAdd from '../../utils/BlogEditorUtils/BlogEditorAdd';

function AddBlog(props){

  const editorRef = useRef();
  const [blogCategory, setBlogCategory] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [blogSlug, setBlogSlug] = useState('');
  const [metaKeyword, setMetaKeyword] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [image, setImage] = useState('');
  const [caption, setCaption] = useState('');
  const [postDate, setPostDate] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [preview, setPreview] = useState('');
  const [imageVisible, setImageVisible] = useState(false);
  const [metaTitle, setMetaTitle] = useState('');
  const [imageName, setImageName] = useState('');
  const [tags, setTags] = useState(["content","general"]);
  const [blogStatus, setBlogStatus] = useState(false);
  const [blogCategoryListing, setBlogCategoryListing] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [createdById, setCreatedById] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(50);
  const [sortData,sort, onSort] = useSortng();
  const [dateFormatUI, setDateFormatUI] = useState('');
  const [timeFormatUI, setTimeFormatUI] = useState('');
  let [errors, setErrors] = useState({});

  const metaTitleTrackChanges = useRef(null);
  const metaKeywordTrackChanges = useRef(null);
  const metaDescriptionTrackChanges = useRef(null);
  const blogTitleTrackChanges = useRef(null);
  const pageTitleTrackChanges = useRef(null);

  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, _id } = props.admindata;
      setCreatedBy(firstname + " " + lastname);
      setCreatedById(_id);
    }
    fetchBlogCategories();

    return (() => fetchBlogCategories())
  }, []);

  const handleBlogTitleChange = (e) => {
    let maxChar = 70;
    blogTitleTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
    setBlogTitle(e.target.value); 
    errors = Object.assign(errors, { blogTitle: "" }); 
    setErrors(errors); 
  }

  const handlePageTitleChange = (e) => {
    let maxChar = 70;
    pageTitleTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
    setPageTitle(e.target.value); 
    errors = Object.assign(errors, { pageTitle: "" }); 
    setErrors(errors); 
  }

  const handleMetaTitleChange = (e) => {
    let maxChar = 70;
    metaTitleTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
    setMetaTitle(e.target.value); 
    errors = Object.assign(errors, { metaTitle: "" }); 
    setErrors(errors); 
  }

  const handleMetaKeywordChange = (e) => {
      let maxChar = 150;
      metaKeywordTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
      setMetaKeyword(e.target.value); 
      errors = Object.assign(errors, { metaKeyword: "" }); 
      setErrors(errors) 
  }

  const handleMetaDescriptionChange = (e) => {
      let maxChar = 250;
      metaDescriptionTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
      setMetaDescription(e.target.value); 
      errors = Object.assign(errors, { metaDescription: "" }); 
      setErrors(errors)
  }

  //***************** Image Selector ***************** */
  const selectImageHandler = async (e) => {
    let reader = new FileReader()
    let file = e.target.files[0];
    setImageName(file.name);
    setImage(file);
    reader.onloadend = () => {
      setImageVisible(true);
      setPreview(reader.result);
    }
    reader.readAsDataURL(file);
  }

//******************** Image Upload ****************** 
const fileUpload = async (e) => {
  const formData = new FormData();
  formData.append('file', image);
  const response = await props.callApi(API.FILE_UPLOAD, formData, 'post', null, true, true);
  if (response.status === 1) {
    setSelectedFile(response.data.filePath);
  }
}

//**************** validate Form *****************/
 const  validateForm = () => {
    let errors  = { blogCategory: "", pageTitle: "", blogTitle : "", blogSlug : "", metaTitle:"", metaKeyword : "", metaDescription : "",selectedFile: "", blogContent : "", caption: ""};
    let isFormValid = true;
    
    if (!pageTitle.trim()) errors.pageTitle = '*Page title is required'
    else if(pageTitle.length > 70) errors.pageTitle = "*Page title can't be more than 70 characters"
    else errors.pageTitle = '';

    if(!selectedFile) errors.selectedFile ="*Image is required"
    else errors.selectedFile = ""

    if(!blogCategory) errors.blogCategory ="*Blog category is required"
    else errors.blogCategory = "";

    if (!blogTitle.trim()) errors.blogTitle = '*Blog title is required'
    else if(blogTitle.length > 70) errors.blogTitle = "*Blog title can't be more than 70 characters"
    else errors.blogTitle = '';

    if (!blogSlug.trim()) errors.blogSlug = '*Slug is required'
    else errors.blogSlug = '';

    if (!metaTitle.trim()) errors.metaTitle = '*Meta title is required'
    else errors.metaTitle = '';

    if (!metaKeyword.trim()) errors.metaKeyword = '*Meta keyword is required'
    else errors.metaKeyword = '';

    if (!metaDescription.trim()) errors.metaDescription = '*Meta description is required';
    else errors.metaDescription = '';

    if(!caption.trim()) errors.caption = '*Content is required'
    else if (caption.length < 50) errors.caption = "*Caption must contain minimum 50 characters"
    else errors.caption = '';

    if (errors.selectedFile !== '' || errors.blogCategory !== '' || errors.blogTitle !== '' || errors.blogSlug !== '' || errors.metaKeyword !== '' 
    || errors.metaDescription !== '' || errors.caption !== '' || errors.metaTitle !=='') isFormValid = false;
    else isFormValid = true;

    console.log("errors", errors)
    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
 }

 //************** GET BLOG CATEGORY ***************/

const fetchBlogCategories = async(id) => {
  var body = {
    page, 
    pagesize, 
    sort,
    columnKey:"blogCategoryListing"
  };
    try {
    const response = await props.callApi(API.LIST_BLOG_CATEGORY, body, 'post', null, true);
      if (response.status === 1) {
        setBlogCategoryListing(response.data.listing);
      } 
    } catch(error){
      throw (error)
  }
}

 //**************** add new Blog Page *****************/
 const addCmsPage = async (e) => {
  e.preventDefault();

   if (validateForm()) {
      let body = { 
        blogCategory: blogCategory.label,
        pageTitle,
        blogTitle : blogTitle.trim(), 
        blogSlug, 
        metaTitle,
        metaKeyword,
        metaDescription,  
        caption,
        image: selectedFile,
        tags,
        createdBy,
        createdById,
        status: blogStatus,
        postDate
      };
      
    try{
      const response = await props.callApi(API.ADD_UPDATE_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        toast('Blog added successfully',{
          position: "top-right",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type:"success"
        });
        props.history.push('/blogListing');
      }
    } catch(error) {
      throw(error)
    }
  }
}


  const handleSlugGenerator = async(e) => {
    setBlogTitle(e.target.value);
    let body = {
      blogSlugKey : blogTitle
    }

    try {
      const response = await props.callApi(API.ADD_SLUG, body, 'post', null, true);
      if (response.status === 1) {
        setBlogSlug(response.blogSlug);
      } else {
        toast.error(response.message,{
          position: "top-right",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } catch(error) {      
      throw (error);
    }

  }

  // slugGenerator();
  //************* Add tags **************** */
  const addTags = (e) => {
    if (e.key === "Enter" && e.target.value !== "") {
      setTags([...tags, e.target.value]);
      e.target.value = "";
    }
  }

  //*************** Remove Tags *************** */
  const removeTags = (index) => {
    setTags([...tags.filter(tag => tags.indexOf(tag) !== index)])
  }

  const getpreview = (content) => {
    setCaption(content);
  }

  const options = [];
  for (let i = 0; i< blogCategoryListing.length; i++) {
    options.push({
      value: blogCategoryListing[i].blogCategoryTitle,
      label: blogCategoryListing[i].blogCategoryTitle
    })
  }

  const translations = language[props.language !== undefined ? props.language :"en"];
//*********************************/
    return (
      <Home>
         <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Add Page</li>
                  <li className="breadcrumb-item" ><Link onClick={() => {props.history.push('/dashboard')}}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item"><Link onClick={() => {props.history.push('/blogListing')}}>Blog Lists</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{translations.ContentManagement.CMS.add}</li>
                </ol>
              </nav>
              <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
                <span className="author d-lg-none d-flex">{translations.ContentManagement.CMS.Author}</span>
              </div>
              {/* <div class="blog-wrapper"> */}
              <div className="blog-content-block">
                <div className="card card-media">
                  <div className="row">
                    <div className="col-md-4">
                      <React.Fragment>
                        <div className="form-group mb-0 d-flex align-items-center flex-wrap">
                          <div className="d-flex align-items-center mb-2">
                            <span className="imageselect-text">Choose Image<sup className="text-danger">*</sup></span>
                            <label className="img-upload-square sm ml-2">
                              <input type="file" className="form-control-file text-primary d-none" name='image' id="file" accept="image/*"
                              data-title="Drag and drop a file" onChange={selectImageHandler} />
                              <em className="bx bxs-edit-alt position-relative"></em>
                            </label>
                          </div>
                        </div>
                        <label>{imageName ? imageName : null}</label>
                      </React.Fragment>
                      <React.Fragment>
                        <div className="button-continer pull-right my-3">
                          <button className="btn btn-primary" type="button" onClick={fileUpload} > Upload Image</button>
                        </div>
                        <div className="form-group mb-0 mt-3">
                          <label>{translations.ContentManagement.CMS.image}</label>
                        </div>
                        <div className="row justify-content-between my-2">
                          <div className="col-md-12 select-image"><div className="custom-select-image"><img src={selectedFile ? IMG_URL+selectedFile : "/assets/images/icon/no-image-icon-0.jpg"} width="50px" height="100px" alt="icon" /></div></div>
                        </div>
                        <span className="text-danger d-block">{errors.selectedFile}</span>
                      </React.Fragment> 
                    </div>
                    <div className="col-md-8">
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="blogCategory">{translations.ContentManagement.CMS.category}<sup className="text-danger">*</sup></label>
                          <Select className="selectpicker w-100"
                            id="blogCategory"
                            name="blogCategory"
                            placeholder = "Select Category"
                            value={blogCategory}
                            options={options}
                            onChange={(e) => { setBlogCategory(e); errors = Object.assign(errors, { blogCategory: "" }); setErrors(errors)}}>
                          </Select>
                          <span className="text-danger d-block">{errors.blogCategory}</span>
                      </div>   
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="pageTitle">Page Title<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="pageTitle" 
                        name="pageTitle"
                        placeholder="IndiaNIC Joins the Clutch 1000!" 
                        value={pageTitle}
                        maxLength = "70"
                        onChange={(e) => handlePageTitleChange(e)}
                        />
                        <small id="pageTitle" className="form-text" ref={pageTitleTrackChanges}>Maximum {70 - pageTitle.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.pageTitle}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="Title">{translations.ContentManagement.CMS.blogTitle}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="blogTitle" 
                        name="blogTitle"
                        maxLength="70"
                        placeholder="IndiaNIC Joins the Clutch 1000!" 
                        value={blogTitle}
                        onChange={(e) => handleBlogTitleChange(e)}
                        onBlur={(e) => handleSlugGenerator(e)}
                        />
                        <small id="blogTitle" className="form-text" ref={blogTitleTrackChanges}>Maximum {70 - blogTitle.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.blogTitle}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="Slug">{translations.ContentManagement.CMS.slug}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="blogSlug" 
                        name="blogSlug"
                        placeholder="About Company" 
                        value={blogSlug}
                        onChange={(e) => {setBlogSlug(e.target.value) ;errors=Object.assign(errors, { blogSlug: "" });setErrors(errors)}}
                        />
                        <span className="text-danger d-block">{errors.blogSlug}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="metaTitle">{translations.ContentManagement.CMS.title}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="metaTitle" 
                        name="metaTitle"
                        placeholder="Meta title" 
                        value={metaTitle}
                        maxLength="70"
                        onChange={(e) => handleMetaTitleChange(e)}
                        />
                        <small id="metaTitle" className="form-text" ref={metaTitleTrackChanges}>Maximum {70 - metaTitle.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.metaTitle}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="MetaKeywords">{translations.ContentManagement.CMS.metaKeywords}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="metaKeyword"
                        name="metaKeyword" 
                        placeholder="Keywords here!"
                        maxLength="150" 
                        value={metaKeyword}
                        onChange={(e) => handleMetaKeywordChange(e)}
                        />
                         <small id="MetaKeyword" className="form-text" ref={metaKeywordTrackChanges}>Maximum {150 - metaKeyword.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.metaKeyword}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="Description">{translations.ContentManagement.CMS.metaDescription}<sup className="text-danger">*</sup></label>
                        <textarea 
                        rows="6" 
                        class="form-control" 
                        placeholder="Describe this a bit" 
                        id="metaDescription"
                        name="metaDescription"
                        value={metaDescription}
                        maxLength="250"
                        onChange={(e) => handleMetaDescriptionChange(e)}
                        />
                        <small id="metaDescription" className="form-text" ref={metaDescriptionTrackChanges}>Maximum {250 - metaDescription.length} characters is suitable</small>
                        <span className="text-danger d-block" >{errors.metaDescription}</span>
                      </div>
                      </div>
                  </div>
                  <div className="content-img-block">
                  
                    <div className="img-drag-section">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                          <label htmlFor="caption">{translations.ContentManagement.CMS.content}<sup className="text-danger">*</sup></label>
                             <BlogEditorAdd
                                className="form-control mx-auto"
                                editContent={caption} 
                                placeholder="Enter your content"
                                ref={editorRef} 
                                getPreview={getpreview} 
                            />
                             
                            <span className="text-danger d-block">{errors.caption}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-3">
                    <div className="form-group">
                      <div className="button-group-container">
                        <a 
                        className="btn btn-primary mr-3 mb-sm-0 mb-2"  
                        onClick={e => addCmsPage(e)}>
                            <span>Add</span>
                        </a>
                        
                        <button 
                        type="button" 
                        className="btn btn-outline-primary" 
                        onClick={() => {props.history.push('/blogListing');}}
                        >
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-sidebar">
                <div className="card card-profile">
                <div className="form-group">
                  <label>{translations.ContentManagement.CMS.postDate}</label>
                  <div className="row">
                    <div className="col-md-12">
                      <fieldset className="form-group position-relative has-icon-left">
                       <Datetime 
                        className="d-block w-100"
                        inputProps={ {
                           placeholder: "Select date & time"
                        }}
                        value={postDate ? new Date(postDate) : ""}
                        onChange={(date) => setPostDate(date)}
                        />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className="form-group site-logo">
                  <label htmlFor="tag">{translations.ContentManagement.CMS.tag}</label>
                  <div className="tags-input">
                    <ul id="tags">
                      {
                        tags.map((tag, index) => (
                            <li key={index} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span className="tag-close-icon"
                                  onClick={() => removeTags(index)}>x</span>
                            </li>
                        ))
                      }
                    </ul>
                    <input type="text" 
                    className="form-control" 
                    id="tag"
                    onKeyUp = {e => e.key="Enter" ? addTags(e) : null} 
                    placeholder="Press enter to add tags" 
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <label className="mb-0">{translations.ContentManagement.CMS.status}</label>
                  <span>
                    <div className="custom-control custom-switch light">
                      <input 
                      type="checkbox" 
                      className="custom-control-input" 
                      id="blogStatus" 
                      checked = {blogStatus}
                      onChange={() => setBlogStatus(!blogStatus)}
                      />
                      <label className="custom-control-label" htmlFor="blogStatus" />
                    </div>
                  </span>
                </div> 
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/*End Dashboard Wrapper*/}
          {/* Bundle Scripts */}
        </div></div>
      </Home>
    );
  }

const mapStateToProps = state => ({
  CmsPagesAccess: state.admin.adminData.rolePermission.cmsPagesAccess,
  admindata: state.admin.adminData,
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(AddBlog)

