import React, { useEffect, useState, useRef} from 'react';
import Home from '../Home'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction'
import {Link, withRouter} from "react-router-dom"
import common from '../../actions/common';
import API from '../../actions/API';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import * as language from "../../translations/index";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {IMG_URL} from '../../config/configs';
import parser from 'html-react-parser';
import moment from 'moment';
import {useSortng} from '../CommonFeature/Sorting';
import StaticEditorEdit from '../../utils/BlogEditorUtils/BlogEditorEdit';
import Datetime from "react-datetime";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function StaticCMS(props){

  const editorRef = useRef();
  const [blogCategory, setBlogCategory] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [blogSlug, setBlogSlug] = useState('');
  const [metaKeyword, setMetaKeyword] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [image, setImage] = useState('');
  const [caption, setCaption] = useState('');
  const [postDate, setPostDate] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [tags, setTags] = useState(["content","general"]);
  const [blogStatus, setBlogStatus] = useState(false);
  const [blogCategoryListing, setBlogCategoryListing] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [createdById, setCreatedById] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(50);
  const [sortData,sort, onSort] = useSortng();
  const [pageTitle, setPageTitle] = useState('');
  let [errors, setErrors] = useState({});
  const [dateFormatUI, setDateFormatUI] = useState('');
  const [timeFormatUI, setTimeFormatUI] = useState('');
  const [timeZoneUI, setTimeZoneUI] = useState('');

  let staticId = props.match.params.slug;

  const metaTitleTrackChanges = useRef(null);
  const metaKeywordTrackChanges = useRef(null);
  const metaDescriptionTrackChanges = useRef(null);
  const pageTitleTrackChanges = useRef(null);

  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, _id } = props.admindata;
      setCreatedBy(firstname + " " + lastname);
      setCreatedById(_id);
    }
    if(staticId){
      getBlogDetails(staticId);
    }
    fetchBlogCategories();
  }, []);

  
  const handlePageTitleChange = (e) => {
    let maxChar = 70;
    pageTitleTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
    setPageTitle(e.target.value); 
    errors = Object.assign(errors, { pageTitle: "" }); 
    setErrors(errors); 
  }

  const handleMetaTitleChange = (e) => {
    let maxChar = 70;
    metaTitleTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
    setMetaTitle(e.target.value); 
    errors = Object.assign(errors, { metaTitle: "" }); 
    setErrors(errors); 
  }

  const handleMetaKeywordChange = (e) => {
      let maxChar = 150;
      metaKeywordTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
      setMetaKeyword(e.target.value); 
      errors = Object.assign(errors, { metaKeyword: "" }); 
      setErrors(errors) 
  }

  const handleMetaDescriptionChange = (e) => {
      let maxChar = 250;
      metaDescriptionTrackChanges.current.textContent = `Maximum ${maxChar - e.target.value.length} characters is suitable`;
      setMetaDescription(e.target.value); 
      errors = Object.assign(errors, { metaDescription: "" }); 
      setErrors(errors)
  }

//************** GET STATIC CATEGORY ***************/

const fetchBlogCategories = async(id) => {
  var body = {
    page, 
    pagesize, 
    sort,
    columnKey:"blogCategoryListing"
    // filter:filterObj ? filterObj:"" 
  };
    try {
    const response = await props.callApi(API.LIST_BLOG_CATEGORY, body, 'post', null, true);
      if (response.status === 1) {
        setBlogCategoryListing(response.data.listing);
      } 
    } catch(error){
      throw (error)
  }
}

//**************** Get Details  *****************/
 const getBlogDetails = async (id) => {
   try {
    const response = await props.callApi(API.GET_BLOG + id, '', 'get', null, true);
    if (response.status === 1) {
      let {
        blogCategory,
        blogTitle,
        pageTitle,
        metaTitle,  
        metaKeyword,
        metaDescription, 
        caption,
        tags,
        status,
        updatedAt
      } = response.data;
      
      let mergedDate;
      let body = {
        loginId : localStorage.getItem('accessToken')
      }

      const responseDate = await props.callApi(API.GET_DATE_SETTINGS, body, 'get', null, true);
      if (responseDate.status === 1) {
        let { dateFormat, timeFormat, timeZone } = responseDate.data;
        setDateFormatUI(dateFormat);
        setTimeFormatUI(timeFormat);
        console.log("response date", responseDate, dateFormatUI, timeFormatUI)
        let timeZones = timeZone.split(" ")[1];
        if(timeFormat === "24 Hours") {
          mergedDate = moment(updatedAt).tz(`${timeZones}`).format(`${dateFormat} HH:mm `);
        } else {
          mergedDate = moment(updatedAt).tz(`${timeZones}`).format(`${dateFormat} h:mm A `);
        }
        setPostDate(mergedDate);
      }
      setPageTitle(pageTitle);
      setBlogTitle(blogTitle);
      setBlogCategory(blogCategory);
      setMetaTitle(metaTitle);
      setMetaKeyword(metaKeyword);
      setMetaDescription(metaDescription);
      setCaption(caption);
      setBlogStatus(status);
      setTags(tags);
    }

   } catch(error) {
     throw error;
   }
 }

  //**************** validate Form *****************/
 const  validateForm = () => {
    let errors  = { pageTitle: "", metaTitle : "", blogSlug : "", metaKeyword : "", metaDescription : "", blogContent : "", caption: ""};
    let isFormValid = true;
    
    if (!pageTitle.trim()) errors.pageTitle = '*Page title is required'
    else if( pageTitle.length > 70) errors.pageTitle = "*Page title can't be more than 70 characters"
    else errors.pageTitle = '';
    
    if (!metaTitle.trim()) errors.metaTitle = '*Meta title is required'
    else if( metaTitle.length > 70) errors.metaTitle = "*Meta title can't be more than 70 characters"
    else errors.metaTitle = '';

    if (!metaKeyword.trim()) errors.metaKeyword = '*Meta keyword is required'
    else if( metaKeyword.length > 150) errors.metaKeyword = "*Meta keywords can't be more than 150 characters"
    else errors.metaKeyword = '';

    if (!metaDescription.trim()) errors.metaDescription = '*Meta description is required';
    else if(metaDescription.length > 250) errors.metaDescription = "*Meta keywords can't be more than 250 characters";
    else errors.metaDescription = '';

    if(!caption.trim()) errors.caption = '*Content is required'
    else if (caption.length < 50) errors.caption = "*Caption must contain minimum 50 characters"
    else errors.caption = '';

    if (errors.pageTitle !== '' || errors.metaTitle !== '' || errors.blogSlug !== '' || errors.metaKeyword !== '' 
    || errors.metaDescription !== '' || errors.caption !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
 }

 //**************** Update static Page and move to static *****************/
 const updateAndMoveToStatic = async (e) => {
  e.preventDefault();

   if (validateForm()) {
      let body = { 
        id: staticId,
        blogCategory,
        blogTitle,
        pageTitle,   
        metaTitle, 
        metaKeyword, 
        metaDescription,
        caption,
        // tags,
        status: blogStatus,
      };
      
    try{
      const response = await props.callApi(API.ADD_UPDATE_BLOG, body, 'post', null, true);
      if (response.status === 1) {
        props.history.push(`/editStatic/eachStatic/${staticId}`);
      }
    } catch(error) {
      throw(error)
    }
  }
}
  
  //**************** Update Static Page *****************/
 const updateCmsPage = async (e) => {
    e.preventDefault();
    if (validateForm()) {
    let body = { 
      id: staticId,
      blogCategory,
      blogTitle,
      pageTitle, 
      metaTitle, 
      metaKeyword, 
      metaDescription,
      caption,
      // tags,
      status: blogStatus
    };
      console.log("body update", body);
      const response = await props.callApi(API.ADD_UPDATE_BLOG, body, 'post', null, true);
      console.log("Response update", response);
      if (response.status === 1) {
        toast.success('Blog updated successfully',{
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

        setTimeout(() => {
          props.history.push('/staticPage');
        }, 1500);
      }
    }
  }

  const getpreview = (content) => {
    setCaption(content);
  }

  const translations = language[props.language !== undefined ? props.language :"en"];
//*********************************/
    return (
      <Home>
         <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Edit Page</li>
                  <li className="breadcrumb-item" >
                    <Link onClick={() => {
                      props.history.push('/dashboard');
                      }}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item">
                    <Link onClick={() => {
                      props.history.push('/staticPage'); 
                      }}>Static Pages</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Edit Page</li>
                </ol>
              </nav>
              <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
                <span className="author d-lg-none d-flex">{translations.ContentManagement.CMS.Author}</span>
              </div>
              {/* <div class="blog-wrapper"> */}
              <div className="blog-content-block">
                <div className="card card-media">
                  <div className="row">
                    <div className="col-md-4">     
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="pageTitle">Page Title<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="pageTitle" 
                        name="pageTitle"
                        placeholder="IndiaNIC Joins the Clutch 1000!" 
                        value={pageTitle}
                        maxLength = "70"
                        onChange={(e) => handlePageTitleChange(e)}
                        />
                        <small id="pageTitle" className="form-text" ref={pageTitleTrackChanges}>Maximum {70 - pageTitle.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.pageTitle}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="Title">{translations.ContentManagement.CMS.title}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="metaTitle" 
                        name="metaTitle"
                        placeholder="IndiaNIC Joins the Clutch 1000!" 
                        value={metaTitle}
                        onChange={(e) => handleMetaTitleChange(e)}
                        />
                        <small id="metaTitle" className="form-text" ref={metaTitleTrackChanges}>Maximum {70 - metaTitle.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.metaTitle}</span>
                      </div>
                     
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="MetaKeywords">{translations.ContentManagement.CMS.metaKeywords}<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="metaKeyword"
                        name="metaKeyword" 
                        placeholder="Keywords here!" 
                        value={metaKeyword}
                        onChange={(e) => handleMetaKeywordChange(e)}
                        />
                         <small id="MetaKeyword" className="form-text" ref={metaKeywordTrackChanges}>Maximum {150 - metaKeyword.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.metaKeyword}</span>
                      </div>
                      <div className="form-group mb-md-5 mb-3">
                        <label htmlFor="Description">{translations.ContentManagement.CMS.metaDescription}<sup className="text-danger">*</sup></label>
                        <textarea 
                        rows="6" 
                        class="form-control" 
                        placeholder="Describe this a bit" 
                        id="metaDescription"
                        name="metaDescription"
                        value={metaDescription}
                        onChange={(e) => handleMetaDescriptionChange(e)}
                        />
                        <small id="metaDescription" className="form-text" ref={metaDescriptionTrackChanges}>Maximum {250 - metaDescription.length} characters is suitable</small>
                        <span className="text-danger d-block">{errors.metaDescription}</span>
                      </div>
                     
                      </div>
                  </div>
                  <div className="content-img-block">
                   
                    <div className="img-drag-section">
                      <div className="row">
                       
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                          <label htmlFor="caption">{translations.ContentManagement.CMS.content}<sup className="text-danger">*</sup></label>
                        
                            <StaticEditorEdit 
                              className="form-control mx-auto"
                              editContent={caption} 
                              ref={editorRef} 
                              getPreview={getpreview} 
                            />
                            <span className="text-danger d-block">{errors.caption}</span>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-3">
                    <div className="form-group">
                      <div className="button-group-container">
                        {
                        
                          <React.Fragment>
                            <a
                            className="btn btn-primary mr-3 mb-sm-0 mb-2" 
                            onClick={(e) => {
                                updateAndMoveToStatic(e);
                            }} >
                              Preview
                            </a>
                            <a 
                            className="btn btn-primary mr-3 mb-sm-0 mb-2" 
                            onClick={e => {updateCmsPage(e);}}>
                                <span>Update</span>
                            </a>
                            
                          </React.Fragment>
                         
                        } 
                        <button 
                        type="button" 
                        className="btn btn-outline-primary" 
                        onClick={() => {props.history.push('/staticPage');}}
                        >
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-sidebar">
                <div className="card card-profile">
                <div className="form-group">
                  <label>Last Update</label>
                  <div className="row">
                    <div className="col-md-12">
                      <fieldset className="form-group position-relative has-icon-left">
                      <Datetime 
                      className="d-block w-100"
                      inputProps={ {
                          placeholder: "Select date & time"
                      }}
                      dateFormat={dateFormatUI}
                      timeFormat={timeFormatUI === "24 Hours" ? "HH:mm" : "h:mm A"}
                      value={postDate ? new Date(postDate) : ""}
                      onChange={(date) => setPostDate(date)}
                      />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <label className="mb-0">{translations.ContentManagement.CMS.status}</label>
                  <span>
                    <div className="custom-control custom-switch light">
                      <input 
                      type="checkbox" 
                      className="custom-control-input" 
                      id="blogStatus" 
                      checked = {blogStatus}
                      onChange={() => setBlogStatus(!blogStatus)}
                      />
                      <label className="custom-control-label" htmlFor="blogStatus" />
                    </div>
                  </span>
                </div> 
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/*End Dashboard Wrapper*/}
          {/* Bundle Scripts */}
        </div></div>
      </Home>
    );
  }

const mapStateToProps = state => ({
  CmsPagesAccess: state.admin.adminData.rolePermission.cmsPagesAccess,
  admindata: state.admin.adminData,
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(StaticCMS)
