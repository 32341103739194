import React, { useState, useRef, useEffect } from 'react'
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as language from "../translations/index";
import { callApi, sidebaropen } from '../actions/commonFunction'; // COMMON FUNCTIONS
import { IMG_URL } from '../config/configs';
import * as _ from 'lodash';
import common from '../actions/common' // Validations
import $, { each } from 'jquery'
import { Accordion, Card } from 'react-bootstrap';
import Sider from 'antd/lib/layout/Sider';

function Sidebar(props) {
  const [userName, setUserName] = useState('');
  const [photo, setPhoto] = useState('');
  const [adminUserAccess, setAdminUserAccess] = useState({});
  const [cmsPagesAccess, setCmsPagesAccess] = useState({});
  const [emailSettingsAccess, setEmailSettingsAccess] = useState({});
  const [emailTemplateAccess, setEmailTemplateAccess] = useState({});
  const [rolesAccess, setRolesAccess] = useState({});
  const [userAccess, setUserAccess] = useState({});

  const [usermenu, openUsermenu] = useState(false);
  const [settingsmenu, openSettingsmenu] = useState(false);
  const [contentmenu, openContentmenu] = useState(false);
  const [blogsmenu, openBlogsmenu] = useState(false);
  const [socialmediamenu, openSocialmediamenu] = useState(false);
  const [sidebaropen, openSidebar] = useState(false);


  const SidebarOpen = () => {
    document.body.classList.remove('sidebar-open');
  }



  function useOuterClick(callback) {
    const innerRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return innerRef;
  }


  const innerRef = useOuterClick(e => {
    // document.body.classList.remove('sidebar-open')
  });



  let path = props.location.pathname;


  //********************
  useEffect(() => {
    if (props.rolePermissions && !_.isEmpty(props.rolePermissions)) {
      let { adminUserAccess, cmsPagesAccess, emailSettingsAccess, emailTemplateAccess, rolesAccess, userAccess } = props.rolePermissions
      setAdminUserAccess(adminUserAccess); setCmsPagesAccess(cmsPagesAccess);
      setEmailSettingsAccess(emailSettingsAccess); setEmailTemplateAccess(emailTemplateAccess);
      setRolesAccess(rolesAccess); setUserAccess(userAccess);
    }
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata
      if (props.editadminprofile && !_.isEmpty(props.editadminprofile)) {
        let { firstname, lastname, photo } = props.editadminprofile;
        setUserName(firstname + " " + lastname)
        setPhoto(photo)
      }
      else {
        setUserName(firstname + " " + lastname);
        setPhoto(photo)
      }
    }
  }, []);
  //******************** Route  **********/


  const changeRoute = (e, page) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if ((page == "/dashboard") || (page == "/rolesList") || (page == "/master") || (page == "/emailTemplates")) {
      openUsermenu(false)
      openContentmenu(false)
      openSettingsmenu(false);
    }

    if ((page == "/faq")) {
      openBlogsmenu(false)
    }
    document.body.classList.remove('sidebar-open')
    return props.history.push(page)
  }



  const changeSocialmedia = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    openSettingsmenu(true)
    openSocialmediamenu(!socialmediamenu)
  }
  //********************









  const blogs = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    openContentmenu(true)
    openBlogsmenu(!blogsmenu)
  }



  let imagePreview = null;
  if (photo) {
    imagePreview = (<img src={IMG_URL + photo} alt="/assets/images/no-image-user.png" />);
  } else {
    imagePreview = (<img src={"/assets/images/no-image-user.png"} alt="icon" />);
  }

  const user = () => {
    openUsermenu(!usermenu)
    openContentmenu(false)
    openSettingsmenu(false)
    openBlogsmenu(false)

  }


  const content = () => {
    openContentmenu(!contentmenu)
    openUsermenu(false)
    openSettingsmenu(false)
    if (!contentmenu) {
      openBlogsmenu(false)
    }
  }



  const settings = () => {
    openSettingsmenu(!settingsmenu)
    openContentmenu(false)
    openUsermenu(false);
    openBlogsmenu(false)
    if (!settingsmenu) {
      openSocialmediamenu(false)
    }
  }


  const translations = language[props.language != undefined ? props.language : "en"];
  let isLogined = localStorage.getItem('accessToken')
  return (
    <div>{
      isLogined ?
        // ref = { innerRef } 
        <div ref={innerRef} id="container"
          className="App">
          <header className="App-header">
          </header>
          <aside className="sidebar">
            <div className="sidebar-inner-wrapper">
              <div>
                {/* <div id="container" ref={innerRef}>
                </div> */}
              </div>
              <div className="sidebar-heading">
                <div className="d-flex align-items-end justify-content-between w-100">
                  <Link className="navbar-brand" >
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-logo.svg" alt="branding logo" />
                    </div>
                  </Link>
                  <span onClick={SidebarOpen} className="sidebar-close"><em className="bx bx-x"></em></span>
                </div>
              </div>
              <PerfectScrollbar>
                <div className="sidebar-menu">
                  <ul className="sidebar-menu-list">
                    <li className="menu-list-item">
                      <a className={path === '/dashboard' && !settingsmenu && !usermenu && !contentmenu ? 'menu-link active-link' : 'menu-link'} onClick={(e) => { changeRoute(e, '/dashboard')}}>
                        <i className="bx bx-home-alt" />
                        <span className="menu-title">{translations.CustomLabels.dashboard}</span>
                      </a>
                    </li>
                    <li onClick={() => { user()}} className={usermenu ? "menu-list-item has-child-submenu level1 submenu-open" : "menu-list-item has-child-submenu level1"} >
                      <a className="menu-link">
                        <i className="bx bxs-user" />
                        <span className="menu-title">User Management</span>
                        <i className="bx bxs-chevron-right" />
                      </a>

                      <ul className={usermenu ? "sidebar-menu-list sub-menu-list show" : "sidebar-menu-list sub-menu-list"} style={usermenu ? { "display": "block" } : { "display": "none" }} >
                        <li className="menu-list-item">
                          <a onClickCapture={(evt) => changeRoute(evt, '/listUsers')} className={path == '/listUsers' ? 'menu-link active-link' : 'menu-link'} >
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Manage Users</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClickCapture={(evt) => changeRoute(evt, '/listMentors')} className={path == '/listMentors' ? 'menu-link active-link' : 'menu-link'} >
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Manage Mentors</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClickCapture={(evt) => changeRoute(evt, '/listTutors')} className={path === '/listTutors' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Manage Tutors</span>
                          </a>
                        </li>
                      </ul>

                    </li>

                    <li onClick={() => content()} className={contentmenu ? "menu-list-item has-child-submenu level1 submenu-open" : "menu-list-item has-child-submenu level1"} >
                      <a className="menu-link">
                        <i className="bx bx-link-alt" />
                        <span className="menu-title">Master Data</span>
                        <i className="bx bxs-chevron-right" />
                      </a>


                      <ul onClick={() => {content(true); }} className={contentmenu ? "sidebar-menu-list sub-menu-list show" : "sidebar-menu-list sub-menu-list"} style={contentmenu ? { "display": "block" } : { "display": "none" }} >
                        <li className="menu-list-item">
                          <a onClick={(evt) => {changeRoute(evt, '/listDepartments'); }} className={path === '/listDepartments' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Departments</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClick={(evt) => {changeRoute(evt, '/listSubjects'); }} className={path === '/listSubjects' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Subjects</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClick={(evt) => {changeRoute(evt, '/listWorkType'); }} className={path === '/listWorkType' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Work Type</span>
                          </a>
                        </li>
                      </ul>
                    </li>

                    {/* <li onClick={() => content()} className={contentmenu ? "menu-list-item has-child-submenu level1 submenu-open" : "menu-list-item has-child-submenu level1"} >
                      <a className="menu-link">
                        <i className="bx bx-link-alt" />
                        <span className="menu-title">{translations.CustomLabels.content}</span>
                        <i className="bx bxs-chevron-right" />
                      </a>


                      <ul onClick={() => {content(true); }} className={contentmenu ? "sidebar-menu-list sub-menu-list show" : "sidebar-menu-list sub-menu-list"} style={contentmenu ? { "display": "block" } : { "display": "none" }} >
                        <li className="menu-list-item">
                          <a onClick={(evt) => {changeRoute(evt, '/staticPage'); }} className={path === '/staticPage' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.staticpages}</span>
                          </a>
                        </li>
                        <li
                          onClick={(e) => blogs(e)} className={blogsmenu ? "menu-list-item child-menu submenu-open level2" : "menu-list-item child-menu level2"}
                        >
                          <a className="menu-link">
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Blogs</span>
                            <i className="bx bxs-chevron-right" />
                          </a>
                          <ul style={blogsmenu ? { "display": "block" } : { "display": "none" }} className="sidebar-menu-list sub-menu-list">
                            <li className="menu-list-item level3">
                              <a onClick={(evt) => {changeRoute(evt, '/blogListing'); }} className={path === '/blogListing' ? 'menu-link active-link' : 'menu-link'}>
                                <i className="bx bxs-right-arrow-alt" />
                                <span className="menu-title">Blogs</span>
                              </a>
                            </li>
                            <li className="menu-list-item level3">
                              <a onClick={(evt) => {changeRoute(evt, '/blogCategory'); }} className={path === '/blogCategory' ? 'menu-link active-link' : 'menu-link'}>
                                <i className="bx bxs-right-arrow-alt" />
                                <span className="menu-title">{translations.CustomLabels.blogs}</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-list-item">
                          <a onClick={(evt) => {changeRoute(evt, '/faq'); }} className={path === '/faq' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.faq}</span>
                          </a>
                        </li>
                      </ul>
                    </li> */}

                    <li onClick={() => {settings(); }} className={settingsmenu ? "menu-list-item has-child-submenu level1 submenu-open" : "menu-list-item has-child-submenu level1"}>
                      <a className="menu-link">
                        <i className="bx bx-cog" />
                        <span className="menu-title">Reports</span>
                        <i className="bx bxs-chevron-right" />
                      </a>
                      <ul className="sidebar-menu-list sub-menu-list" style={settingsmenu ? { "display": "block" } : { "display": "none" }}>
                        <li className="menu-list-item">
                          <a onClick={(evt) => changeRoute(evt, '/listTasks')} className={path === '/listTasks' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">Task Management</span>
                          </a>
                        </li>

                        {/* <li className="menu-list-item level3">
                          <a onClick={(evt) => changeRoute(evt, '/socialMedia')} className={path === '/socialMedia' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.socilamedia}</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClick={(evt) => changeRoute(evt, '/paymentGateway')} className={path === '/paymentGateway' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.paymentGateway}</span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a onClick={(evt) => changeRoute(evt, '/smtp')} className={path === '/smtp' ? 'menu-link active-link' : 'menu-link'}>
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.smtp}</span>
                          </a>
                        </li>
                        <li onClick={(evt) => changeRoute(evt, '/emailSettings')} className={path === '/emailSettings' ? 'menu-link active-link' : 'menu-link'}>
                          <a className="menu-link">
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">{translations.CustomLabels.emailNotification}</span>
                          </a>
                        </li> */}
                      </ul>
                    </li>
                  </ul>

                </div>
              </PerfectScrollbar>
            </div>
          </aside>
        </div> : null

    }
    </div>
  );
}
const mapStateToProps = state => ({
  language: state.admin.language,
  admindata: state.admin.adminData,
  sidebar: state.admin.sidebar,
  editadminprofile: state.admin.editAdminProfileData,
  rolePermissions: state.admin.adminData.rolePermission,
});
export default connect(mapStateToProps, { callApi, sidebaropen })(withRouter(Sidebar));