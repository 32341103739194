import React, { Component } from 'react';
import ReactCrop from "react-image-crop";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import common from '../actions/common' // Validations
import "react-image-crop/dist/ReactCrop.css";
import API from '../actions/API';
import { callApi } from '../actions/commonFunction'; // COMMON FUNCTIONS
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import * as config from '../../../config/config';
// import swal from 'sweetalert';



// import axios from "axios"
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class CropImagesProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 16 / 9,
            },
            open: false,
            isUploading: false,
            Cropper
        }
    }

    openModel = () => {
        this.setState({ open: true })
    }

    closeLoginModal = () => {
        this.setState({ open: false, src: null })
    }
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    fileUploadSubmit = async () => {
        this.setState({ isUploading: true })
        let formData = new FormData();
        // let ImageURL = this.state.b64Img;
        // let block = ImageURL.split(";");
        // let contentType = block[0].split(":")[1];// In this case "image/gif"
        // let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
        let b64Data = this.state.Cropper && this.state.Cropper.getCroppedCanvas().toDataURL()

        // convert base64 to raw binary data held in a string
        if (b64Data && b64Data) {
            let type = 'image/png'
            var byteString = atob(b64Data && b64Data.split(',')[1]);
            // separate out the mime component
            var mimeString = b64Data.split(',')[0].split(':')[1].split(';')[0]
            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            // write the ArrayBuffer to a blob, and you're done
            var bb = new Blob([ab], { type: type });
            console.log(bb)
        }


        formData.append('file', bb)
        try {
            const response = await this.props.callApi(API.FILE_UPLOAD, formData, 'post', null, true, true);
            this.setState({ isUploading: false })
            if (response.status === 1) {
                // common.showSuccessMessage(response.message);
                toast.success(response.message,{
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                  });
                this.editAdminProfile(response.data.filePath)
                this.setState({ open: false, src: null })
                this.props.getAdminProfile()
            }
        }
        catch (error) {
            this.setState({ isUploading: false })
            // common.showSuccessMessage(error)
            toast.error(error,{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            throw (error)
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
            this.setState({ open: true })
        }
    };
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );


        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = canvas.toDataURL('image/jpeg');
                let item_image = this.fileUrl.replace(/^data:image\/(png|jpg);base64,/, "");
                this.setState({ b64Img: item_image })
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }


    editAdminProfile = async (image) => {
        console.log("rr", this.props.admindata.firstname)
        var body = {
            emailId: this.props.admindata.emailId,
            firstname: this.props.admindata.firstname,
            lastname: this.props.admindata.lastname,
            mobile: this.props.admindata.mobile,
            photo: image
        }
        const response = await this.props.callApi(API.UPDATE_PROFILE, body, 'post', "EDITADMIN_PROFILE", true);
        if (response.status === 1) {
            // common.showSuccessMessage("Details Updated Successfully");
            toast.success("Details updated Successfully",{
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
        }
    }


    render() {
        let { crop, src, open, isUploading } = this.state
        return (
            <React.Fragment>
                <div >
                    <Modal
                        isOpen={open}
                        onRequestClose={() => this.closeLoginModal()}
                        style={customStyles}
                        contentLabel="Example Modal"

                    >
                        <div>
                            <div>
                                {src && (
                                    <>
                                        <i onClick={() => this.setState({ src: '', open: false })} />
                                        {/* <ReactCrop
                                            className='reactcrop'
                                            src={src}
                                            crop={crop}
                                            ruleOfThirds
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        /> */}
                                        <Cropper
                                            style={{ height: 400, width: "100%" }}
                                            initialAspectRatio={1}
                                            preview=".img-preview"
                                            src={src}
                                            viewMode={1}
                                            guides={true}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                // setCropper(instance);
                                                this.setState({ Cropper: instance })
                                            }}
                                        />
                                        <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                            <button className="btn btn-primary" type="button" onClick={this.fileUploadSubmit}> {isUploading ? "Uploading..." : "Upload"}</button>

                                            <button className="btn btn-primary" type="button" onClick={() => this.setState({ open: false })}>Cancel</button>

                                        </div>
                                    </>)}

                            </div>
                        </div>
                    </Modal>
                    <div id="addDiv">
                        <label htmlfor="attach-file">
                            <input type="file" name='image' id="file" accept="image/*"
                                data-title="Drag and drop a file" onChange={this.onSelectFile} />
                            <span id="addPicture" style={{ display: "block" }} className="add-user">Upload pictures</span>
                        </label>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}


const mapStateToProps = state => ({
    language: state.admin.language,
    admindata: state.admin.adminData,
    editadminprofile: state.admin.editAdminProfileData,
});

export default connect(mapStateToProps, { callApi })(withRouter(CropImagesProfile));

