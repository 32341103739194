import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction';
import { isEmpty } from "lodash";
import Select from "react-select";
import common from '../../actions/common';
import {Link, withRouter} from "react-router-dom"
import errorMessages from '../../actions/errorMessages';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../actions/API';

function AddMentor(props){

 
    
    const [hiddenNewPassword, setHiddenNewPassword] = useState(true);
    const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    let [errors, setErrors] = useState({});
    const [page, setPage] = useState(1);
    const [pagesize, setPagesize] = useState(100);
    const [weekDays,setWeekDays] = useState([
      {day:"mon"}, 
      {day:"tue"}, 
      {day:"wed"},
      {day:"thu"},
      {day:"fri"},
      {day:"sat"},
      {day:"sun"}
    ]);
    const [mobile, setMobile] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    let [disablePhoneinput, setdisablePhoneinput] = useState(false);
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [deptOptions, setDeptOptions] = useState([]);
    const [workType, setWorkType] = useState('');
    const [workTypeOptions, setWorkTypeOptions] = useState([]);
    const [shiftSlot, setShiftSlot] = useState('');
    const [shiftSlotOptions, setShiftSlotOptions] = useState( [
      { value: '08:00 - 16:00', label: '08:00 - 16:00' },
      { value: '16:00 - 00:00', label: '16:00 - 00:00' },
      { value: '00:00 - 08:00', label: '00:00 - 08:00' }
    ]);
    const [password, setPassword] = useState('');
    const [description, setDescription] = useState('');
    const [selectDays, setSelectDays] = useState([]);

    useEffect(() => {
      getDepartMentLists();
      getWorkTypes();
    }, []);

    //******* GET DEPARTMENTS IN DROPDOWN ********** */
    const getDepartMentLists = async() => {
      let body = {
        page, 
        pagesize,  
      };
      let options = [];
      try {
      const response = await props.callApi(API.LIST_DEPARTMENT, body, 'post', null, true);
        if (response.settings.status === 1) {
          for (let i = 0; i< response.data.listing.length; i++) {
            options.push({
              value: response.data.listing[i]._id,
              label: response.data.listing[i].name
            })
          }
          setDeptOptions(options);          
        } 
      } catch(error) {
        throw (error)
      }
    } 

    //******* GET WORKTYPE IN DROPDOWN ********** */
    const getWorkTypes = async() => {
      let body = {
        page, 
        pagesize,  
      };
      let options = [];
      try {
      const response = await props.callApi(API.LIST_WORKTYPE, body, 'post', null, true);
        if (response.settings.status === 1) {
          for (let i = 0; i< response.data.listing.length; i++) {
            options.push({
              value: response.data.listing[i]._id,
              label: response.data.listing[i].format
            })
          }
          setWorkTypeOptions(options);          
        } 
      } catch(error) {
        throw (error)
      }
    }

  
  //*************  Validate Form ****************/
    const  validateForm = () => {
      
      let isFormValid = true;
      let errors = { firstName: "",lastName: "",email:"", phoneNo: "", description: "", password: "", department: "",
      shiftSlot:"", workType:"" , mobileNo:""};

      if (!firstName) errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
      else errors.firstName = '';

      if (!lastName) errors.lastName = errorMessages.PROVIDE_LAST_NAME;
      else errors.lastName = '';

      if (!(email.trim())) errors.email = errorMessages.PROVIDE_EMAIL;
      else if(!common.validateEmail(email)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
      else errors.email= '';

      if (!mobileNo) errors.mobileNo = errorMessages.PROVIDE_MOBILE_NUMBER;
      else if (mobileNo.length !== 10)  errors.mobileNo = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
      else errors.mobileNo = '';

      if (!description) errors.description = errorMessages.PROVIDE_DESCRIPTION;
      else errors.description = '';

      if (!shiftSlot) errors.shiftSlot = errorMessages.PROVIDE_SHIFTSLOT;
      else errors.shiftSlot = '';

      if (!department) errors.department = errorMessages.PROVIDE_DEPARTMENT;
      else errors.department = '';

      if (!workType) errors.workType = errorMessages.PROVIDE_WORKTYPE;
      else errors.workType = '';

      if (!shiftSlot) errors.shiftSlot = errorMessages.PROVIDE_SHIFTSLOT;
      else errors.shiftSlot = '';

      if (!password.trim()) errors.password = errorMessages.PROVIDE_PASSWORD;
      else if (!common.validatePassword(password)) errors.password = errorMessages.PROVIDE_VALID_PASSWORD;
      else errors.password = '';


      if (errors.firstName!== '' || errors.lastName!== '' || errors.email!== ''|| errors.password!== ''||
      errors.description!== '' || errors.department!== '' || errors.workType !== ''|| errors.shiftSlot !== '' || errors.mobileNo !== '') isFormValid = false;
      else isFormValid = true;

      setErrors(errors);
      setIsFormValid(isFormValid);

      return isFormValid
    }
    
// ********************* Add Details ******************
 const  createMentorHandler = async () => {
   console.log("create mentor")
  if (validateForm()) {
    
    let slot = [];
    let from = parseInt(shiftSlot.label.split("-")[0].trim().split(":")[0], 10)*60;
    let until = parseInt(shiftSlot.label.split("-")[1].trim().split(":")[0], 10)*60;

    for (let i = 0; i <selectDays.length; i++){
      slot.push({
        from: from,
        until: until,
        day: selectDays[i]
      })
    }

    let deptLists = [];

    for (let i = 0; i < department.length; i++) {
      deptLists.push(department[i].value);
    }

    let workTypeLists =[];

    for (let i = 0; i < workType.length; i++) {
      workTypeLists.push(workType[i].value);
    }

    console.log("slot", slot);
    var body = { 
      email,
      firstName,
      lastName,
      countryCode : countryCode,
      mobileNumber: mobileNo,
      departments :deptLists,
      worktypes: workTypeLists,
      availability: slot,
      description,
      password
    }

    console.log("body", body);
    try {
      const response = await props.callApi(API.ADD_MENTOR, body, 'post', null, true);
      console.log("response add", response);
      if (response.settings.status === 1) {
        toast.success('Mentor created successfully',{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        props.history.push('/listMentors');
      } else {
        toast.error(response.settings.message,{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } catch (error) {
      throw error;
    }
    
  }
 }

 const renderTooltip = (props) => (
    !hiddenConfirmPassword ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> :  <Tooltip id="button-tooltip" {...props}>
      Show Password
    </Tooltip>
  );

  const handleOnChanges = (value, data) => {
    setdisablePhoneinput(false)
    let dialnums = data.dialCode;
    let mobilenums = value.slice(data.dialCode.length);
    // let mobileNo = `+${dialnums}-${mobilenums}`
		setCountryCode(`+${dialnums}`)
    setMobileNo(mobilenums)
    console.log("dial code", countryCode);
		if(mobilenums.length >=10){
			setdisablePhoneinput(true)
		}

  }
  
  
  //*********** SELECT DAYS HANDLER *********** */
  const statusHandler = (day) => {
    selectDays.includes(day) ? 
    setSelectDays(selectDays.filter(id => id !== day)) 
    :
    setSelectDays([...selectDays, day])
  }

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Mentor</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listMentors')}>List All Mentors</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Mentor</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">Add Details</h5>
                    </div>
                  </div>
                  <form action="#">
                    <div className="row">
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="firstName">First Name<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="firstName"
                        name="firstName" 
                        placeholder="First name" 
                        value={firstName}
                        onChange={(e) => {setFirstName(e.target.value); errors=Object.assign(errors, { firstName: "" }); setErrors(errors)}} 
                        />
                        <span className='error-msg'  style={{color: "red"}} >{errors.firstName}</span>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="lastName">Last name<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="lastName" 
                        name="lastName"
                        placeholder="Last name" 
                        value={lastName}
                        onChange={(e) => {setLastName(e.target.value); errors=Object.assign(errors, { lastName: "" }); setErrors(errors)}} 
                        />
                        <span className='error-msg'  style={{color: "red"}} >{errors.lastName}</span>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="email"
                        name="email" 
                        placeholder="Email" 
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); errors=Object.assign(errors, { email: "" }); setErrors(errors)}} 
                        />
                        <span className='error-msg'  style={{color: "red"}} >{errors.email}</span>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                      <label htmlFor="Email">Phone number<sup className="text-danger">*</sup></label>
                        <div className="w-100">
                        <PhoneInput
                            className="form-control w-100"
                            name="phone"
                            country={'us'}
                            onlyCountries = {['us','in']}
                            masks={''}
                            // excludeCountries={'in'}
                            countryCodeEditable={false}
                            value={mobile}
                            onChange={handleOnChanges}
                        />
                        <span className='error-msg'  style={{color: "red"}} >{errors.mobileNo}</span>
                        </div>
                      </div>
                      <div className="align-items-center col-md-4 mb-md-5 mb-3 user-details">
                        <label htmlFor="department">Department<sup className="text-danger">*</sup></label>
                        <Select className="selectpicker w-100"
                          isMulti
                          id="department"
                          name="department"
                          value={department}
                          options={deptOptions}
                          onChange={(e) => {setDepartment(e); errors = Object.assign(errors, {department: ''}); setErrors(errors);}}>
                        </Select>
                        <span className='error-msg'  style={{color: "red"}} >{errors.department}</span>
                      </div>
                      <div className="align-items-center mb-md-5 col-md-4 mb-3 user-details">
                        <label htmlFor="workType">WorkType<sup className="text-danger">*</sup></label>
                        <Select className="selectpicker w-100"
                          isMulti
                          id="workType"
                          name="workType"
                          value={workType}
                          options={workTypeOptions}
                          onChange={(e) => {setWorkType(e); errors = Object.assign(errors, {workType: ''}); setErrors(errors);}}>
                        </Select>
                        <span className='error-msg'  style={{color: "red"}} >{errors.workType}</span>
                      </div>
                      <div className="align-items-center mb-md-5 col-md-4 mb-3 user-details">
                        <label htmlFor="shiftSlot">Shift Slot<sup className="text-danger">*</sup>  </label>
                        <Select className="selectpicker w-100"
                        id="shiftSlot"
                        name="shiftSlot"
                        value={shiftSlot}
                        options={shiftSlotOptions}
                        onChange={(e) => {setShiftSlot(e); errors = Object.assign(errors, {shiftSlot: ''}); setErrors(errors);}}>
                        </Select>
                        <span className='error-msg'  style={{color: "red"}} >{errors.shiftSlot}</span>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <div><label>Select Days<sup className="text-danger">*</sup></label></div>
                        <div className="d-inline-flex">
                          {
                              weekDays.map((d, i) => {
                                return (
                                  <div className="form-check mr-1">
                                      <input 
                                        key={d.day}
                                        className="form-check-input" 
                                        type="checkbox" 
                                        value={d.day} 
                                        id={d.day}
                                        checked={selectDays.includes(d.day)}
                                        onChange= {(e) => {statusHandler(d.day)}} 
                                      />
                                      <label className="form-check-label" htmlFor={d.day}>
                                          {d.day}
                                      </label>
                                  </div>
                                )
                              })
                          }
                        </div>
                      </div>
                      <div className="form-group col-md-4 mb-md-5 mb-3">
                        <label htmlFor="password">Password<sup className="text-danger">*</sup></label>
                        <input 
                        type={hiddenConfirmPassword ? "password" : "text"} 
                        className="form-control" 
                        id="password"
                        name="password" 
                        placeholder="Password" 
                        value={password}
                        onChange={(e) => {setPassword(e.target.value); errors=Object.assign(errors, { password: "" }); setErrors(errors)}} 
                        />
                        <OverlayTrigger      
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}>
                          <span 
                          onClick={() => setHiddenConfirmPassword(!hiddenConfirmPassword)}
                          toggle="#password-field" 
                          className={hiddenConfirmPassword ? "bx bx-hide field-icon mr-3 toggle-password" : "bx bx-show field-icon mr-3 toggle-password"}
                          ></span>
                        </OverlayTrigger>
                        <span className='error-msg'  style={{color: "red"}} >{errors.password}</span>
                      </div>
                      <div className="form-group align-items-center col-md-6 mb-md-5 mb-3">
                        <label htmlFor="password">Description<sup className="text-danger">*</sup></label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="description" 
                        name="description"
                        placeholder="Description" 
                        value={description}
                        onChange={(e) => {setDescription(e.target.value); errors=Object.assign(errors, { description: "" }); setErrors(errors)}} 
                        />
                        <span className='error-msg'  style={{color: "red"}} >{errors.description}</span>
                      </div>
                    </div>
                  </form>
                  <div className="text-right mt-md-5 mt-3">
                    <button className="btn btn-primary" onClick={createMentorHandler}>Create Mentor</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
          {/*End Dashboard Wrapper*/}
          {/* Bundle Scripts */}
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(AddMentor);