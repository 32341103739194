import React, { useState, useEffect, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { IMG_URL } from '../config/configs';
import theme from '../pages/Theme'
import { Dropdown } from 'react-bootstrap';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import errorMessages from '../actions/errorMessages';
import { connect } from "react-redux";
import common from '../actions/common' // Validations
import _ from 'lodash'
import Theme from '../pages/Theme'
import API from '../actions/API'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { callApi, changeLanguage, changeTheme, changeResize, sidebaropen, useWindowSize } from '../actions/commonFunction'; // COMMON FUNCTIONS
function Header(props) {
  const [value, setValue] = useState('');
  const [photo, setPhoto] = useState('');
  const [firstname, setUserName] = useState('');
  const [toggleSettings, setTogglesettings] = useState(false);
  const [checked, setChecked] = useState(props.theme && props.theme.data);
  const [resizechecked, setResizeChecked] = useState(props.resize && props.resize.data)
  const [sidebaropen, openSidebar] = useState(props.sidebar && props.sidebar.data);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  let [changeLoading, setChangeLoading] = useState(false);
  let [changeErrors, setErrors] = useState({ newPassword: "", confirmPassword: "" });
  const [passwordUIFlag, setPasswordUIFlag] = useState(false);
  const [hiddenNewPassword, setHiddenNewPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  let [isFormValid, setIsFormValid] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const size = useWindowSize()

  useEffect(() => {
    if (size.width <= 1440) {
      setResizeChecked(false);
      document.body.classList.remove('vertical');
      props.changeResize(false)
    }
  }, [size.width <= 1440]);

  const validateFormForPassword = () => {
    let changeErrors = { newPassword: "", confirmPassword: "" }
    let isFormValid = true;

    //for new password
    if (!newPassword.trim()) {
      setPasswordUIFlag(true);
      changeErrors.newPassword = errorMessages.PROVIDE_PASSWORD;
    } else if (!common.validatePassword(newPassword)) {
      setPasswordUIFlag(false);
      changeErrors.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    } else changeErrors.newPassword = '';
    
    //for confirm password
    if (!confirmPassword.trim()) {
      setPasswordUIFlag(true);
      changeErrors.confirmPassword = errorMessages.PROVIDE_PASSWORD;
    } else if (!common.validatePassword(confirmPassword)) {
      setPasswordUIFlag(true);
      changeErrors.confirmPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    }
    else changeErrors.confirmPassword = '';

    if (changeErrors.newPassword !== '' || changeErrors.confirmPassword !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(changeErrors);
    setIsFormValid(isFormValid);
    return isFormValid;
  }

  const resetPassword = async (event) => {
    event.preventDefault()
    var body = {
      oldPassword: newPassword,
      newPassword: confirmPassword,
      // token:localStorage.getItem("accessToken")
    }
    try {
      if (validateFormForPassword()) {
        setChangeLoading(true)
        console.log('validate password')
        const response = await props.callApi(API.CHANGE_PASSWORD, body, 'post', "", true);
        setChangeLoading(false)
        if (response.status === 1) {
          // common.showSuccessMessage(response.message)
          toast.success(response.message,{
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          common.setItem('accessToken', '')
          props.history.push('/')
        }
      }
    } catch (error) {
      setChangeLoading(false)
      throw error;
    }
  }

  const openPasswordModal = () => {
    setShowPassword(true);
    setConfirmPassword("");
    setNewPassword("");
  }
  
  const closePasswordModal = () => {
    setShowPassword(false);
    setConfirmPassword("");
    setNewPassword("");
    setErrors("");
  }

  const renderTooltip = (props) => (
    !hiddenConfirmPassword ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> :  <Tooltip id="button-tooltip" {...props}>
      Show Password
    </Tooltip>
  );

  const renderTooltip1 = (props) => (
    !hiddenNewPassword ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> :  <Tooltip id="button-tooltip" {...props}>
      Show Password
    </Tooltip>
  );

  function useOuterClick(callback) {
    const settingsRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          settingsRef.current &&
          callbackRef.current &&
          !settingsRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return settingsRef;
  }


  const settingsRef = useOuterClick(e => {
    setTogglesettings(false)
  });



  const SidebarOpen = () => {
    document.body.classList.add('sidebar-open');
    openSidebar(!sidebaropen)
    props.sidebaropen(!sidebaropen);
  }




  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata
      if (props.editadminprofile) {
        let { firstname, lastname, photo, theme, menuAlignment } = props.editadminprofile;
        setUserName(firstname + " " + lastname);
        setPhoto(photo)
      }
      else {
        let { firstname, lastname, photo, theme, menuAlignment } = props.admindata;
        if (theme == "Dark") {
          document.body.classList.add('dark');
          document.body.classList.remove('light');
          // props.changeTheme(false);
        } else {
          // props.changeTheme(true);
          document.body.classList.add('light');
          document.body.classList.remove('dark');
        }

        if (menuAlignment == "vertical") {
          document.body.classList.add('vertical');
          document.body.classList.remove('horizontal');
          // props.changeResize(true);
        } else {
          // props.changeResize(false);
          document.body.classList.add('horizontal');
          document.body.classList.remove('vertical');
        }
        setUserName(firstname + " " + lastname);
        setPhoto(photo)
      }
    }
  }, []);


  const handleCheckClick = () => {
    setChecked(!checked);
    if (!checked) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }
    props.changeTheme(!checked);
  }

  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata
      if (props.editadminprofile && !_.isEmpty(props.editadminprofile)) {
        let { firstname, lastname, photo, } = props.editadminprofile;
        setUserName(firstname + " " + lastname);
        setPhoto(photo)
      } else {
        setUserName(firstname + " " + lastname);
        setPhoto(photo)
      }
    }
  }, [props.admindata, props.editadminprofile])

  useEffect(() => {
    // props.changeTheme(props.theme && props.theme.data);
    if (props.theme && props.theme.data == true) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }
  }, []);


  useEffect(() => {
    if (props.resize && props.resize.data == true) {
      document.body.classList.add('vertical');
      document.body.classList.remove('horizontal');
    } else {
      document.body.classList.add('horizontal');
      document.body.classList.remove('vertical');
    }
  }, []);






  const handleCheckResize = () => {
    setResizeChecked(!resizechecked);
    if (!resizechecked) {
      document.body.classList.add('vertical');
      document.body.classList.remove('horizontal');

    } else {
      document.body.classList.add('horizontal');
      document.body.classList.remove('vertical');
    }
    props.changeResize(!resizechecked)

  }



  const handleLanguage = event => {
    const data = {
      language: event.target.value
    };
    props.changeLanguage(data);
  };


  const logout = () => {
    settings()
    props.callApi('/admin/logout', '', '', "LOGOUT", true);
    common.setItem('accessToken', '')
    localStorage.clear();

    // common.showSuccessMessage("Logged Out Successfully")
    toast.success("Logged Out Successfully",{
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    props.history.push('/')
  }


  const settings = async () => {
    let body = {
      "theme": checked ? "Dark" : "Light",
      "menuAlignment": resizechecked ? "Horizontal" : "Vertical"
    }
    try {
      const response = await props.callApi(API.SETTINGS, body, 'post', "", true);
      if (response.status === 1) {

      }
    } catch (error) {
      throw (error)
    }

  }

  let imagePreview = null;
  if (photo) {
    imagePreview = (<img class="round" src={IMG_URL + photo} alt="/assets/images/user.png" height="40" width="40" />);
  } else {
    imagePreview = (<img class="round" src={"/assets/images/avatar-s-16.jpg"} alt="avatar" height="40" width="40" />);
  }

  return (
    <div ref={settingsRef} id="container1" className="App">
      <div className="main-content-area">
        <header>
          <div className="navigation-bar">
            <nav className="navbar d-flex navbar-expand bd-navbar fixed-top">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="nav navbar-nav">
                  <li className="nav-item mobile-menu">
                    <Link onClick={() => 
                      {
                        SidebarOpen()
                      }} 
                      className="nav-link nav-menu-main menu-toggle hidden-xs"  
                    ><i className="bx bx-menu"></i></Link>
                  </li>
                  {/* <li className="nav-item mobile-menu">
                    <span onClick={() => openSidebar(true)} className="sidebar-close"><em className="bx bx-x"></em></span>
                  </li> */}
                </ul>
                <ul className="horizontal-brand nav navbar-nav">
                  <li>
                    <Link to="">
                      <img className="img-fluid" src="assets/images/brand-logo.svg" alt="branding logo" />
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="navbar-nav flex-row ml-md-auto d-md-flex">

                <li className="nav-item dropdown dropdown-language">
                  {/* <Dropdown className="custome-dropdown"
                                  
                                >
                                  <Dropdown.Toggle id="dropdown-basic" className="custome-btn"   onSelect={handleSelect}>
                                    <Link className="dropdown-toggle nav-link mr-md-2" id="dropdown-flag" to="#"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><img src="assets/images/usa-flag.svg" alt="country-flag"/><span
                                      className="selected-language">English</span>
                                    </Link>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1" className="dropdown-menu-header">
                                      <Link className="dropdown-item" to="#" data-language="en"><i
                                          className="flag-icon flag-icon-us mr-50"></i> English
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      <Link className="dropdown-item" to="#" data-language="fr"><i
                                          className="flag-icon flag-icon-fr mr-50"></i> French
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      <Link className="dropdown-item" to="#" data-language="de"><i
                                          className="flag-icon flag-icon-de mr-50"></i> German
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                      <Link className="dropdown-item" to="#" data-language="pt"><i
                                          className="flag-icon flag-icon-pt mr-50"></i> Portuguese
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> */}

                  {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-flag">
                                      
                                      
                                      
                                      
                                    </div> */}

                                    
                </li>
                {/* <li className="nav-item nav-search">
                  <Link className="nav-link nav-link-search" to="#" data-toggle="dropdown"
                    aria-expanded="false">
                    <i className="bx bx-search"></i>
                  </Link>
                  <div className="search-input">
                    <div className="search-box">
                      <div className="search-input-icon"><i className="bx bx-search primary"></i></div>
                      <input className="input" type="text" placeholder="Explore Search..."
                        tabIndex="-1" data-search="template-search" />
                      <div className="search-input-close"><i className="bx bx-x"></i></div>
                    </div>
                    <ul className="search-list">
                      <li
                        className="auto-suggestion align-items-center justify-content-between cursor-pointer current_item">
                        <Link className="align-items-center justify-content-between w-100">
                          <div className="justify-content-start">
                            <span className="mr-75 bx bx-error-circle"></span>
                            <span>No results found.</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item dropdown dropdown-notification">
                  {/* <Dropdown className="custome-dropdown">
                    <Dropdown.Toggle id="dropdown-basic" className="custome-btn">
                      <Link className="nav-link nav-link-label" to="#" data-toggle="dropdown"
                        aria-expanded="false">
                        <i className="bx bx-bell bx-tada bx-flip-horizontal"></i>
                        <span className="badge badge-pill badge-danger badge-up">5</span>
                      </Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1" className="dropdown-menu-header">
                        <div className="dropdown-header d-flex justify-content-between">
                          <span className="notification-title">7 new Notification</span>
                          <span className="text-bold-400 cursor-pointer">Mark all as read</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <div
                          className="d-flex justify-content-between read-notification cursor-pointer">
                          <div className="media d-flex align-items-center">
                            <div className="media-left pr-0">
                              <div className="avatar mr-1 m-0">
                                <img className="round" src="assets/images/hero-image.jpg"
                                                          alt="avatar" height="40" width="40"/>
                                {
                                  imagePreview
                                }
                              </div>
                            </div>
                            <div className="media-body">
                              <h6 className="media-heading"><span className="text-bold-500">New
                                                          Message</span> received</h6>
                              <small className="notification-text">You have 18 unread
                                                      messages</small>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <Link className="nav-link nav-link-label" to="#" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="bx bx-bell bx-tada bx-flip-horizontal"></i>
                                        <span className="badge badge-pill badge-danger badge-up">5</span>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <div className="dropdown-header d-flex justify-content-between">
                                                <span className="notification-title">7 new Notification</span>
                                                <span className="text-bold-400 cursor-pointer">Mark all as read</span>
                                            </div>
                                        </li>
                                        <li className="scrollable-container media-list ps ps--active-y">
                                            <div
                                                className="d-flex justify-content-between read-notification cursor-pointer">
                                                <div className="media d-flex align-items-center">
                                                    <div className="media-left pr-0">
                                                        <div className="avatar mr-1 m-0">
                                                            <img className="round" src="assets/images/hero-image.jpg"
                                                                alt="avatar" height="40" width="40"/>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading"><span className="text-bold-500">New
                                                                Message</span> received</h6>
                                                        <small className="notification-text">You have 18 unread
                                                            messages</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul> */}
                </li>

                {/* <div className="language-dropdown">
                  <select
                    className="custom-select border-0"
                    onChange={handleLanguage}
                    value={props.language}
                  >
                    <option value="en">English</option>
                    <option value="fr">French</option>
                    <option value="gr">German</option>
                    <option value="po">Portuguese</option>

                  </select>
                </div> */}


                <li className="nav-item user-dropdown dropdown">
                  <Dropdown className="custome-dropdown">
                    <Dropdown.Toggle id="dropdown-basic1" className="custome-btn">
                      <Link className="nav-link dropdown-toggle dropdown-user-link" to="#" id="userDropdown"
                        role="button" to="#" data-toggle="dropdown" aria-expanded="true">
                        <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-capitalize">{firstname}</span>
                          {/* <span className="user-status text-muted">Available</span>  */}
                        </div>
                        <span>
                          {

                            imagePreview
                          }
                        </span>
                      </Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => props.history.push("/profile")}><i className='bx bx-user mr-2'></i>Edit Profile</Dropdown.Item>
                      <Dropdown.Item onClick={openPasswordModal}><i className='bx bx-reset mr-2'></i>Change Password</Dropdown.Item>
                      <Dropdown.Item onClick={() => logout()}><i className='bx bx-log-out mr-2'></i>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Link className="nav-link dropdown-toggle dropdown-user-link" to="#" id="userDropdown"
                                        role="button" to="#" data-toggle="dropdown" aria-expanded="true">
                                        <div className="user-nav d-sm-flex d-none">
                                            <span className="user-name">John Doe</span> */}
                  {/* <span className="user-status text-muted">Available</span>  */}
                  {/* </div>
                                        <span><img className="round" src="assets/images/hero-image.jpg" alt="avatar"
                                                height="40" width="40"/></span>
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                        <Link className="dropdown-item" to="user-edit-profile.html"><i className='bx bx-user mr-2'></i>Edit Profile</Link>
                                        <Link className="dropdown-item" to="index.html"><i className='bx bx-log-out mr-2'></i>Log out</Link>
                                    </div> */}
                </li>
                <li className="nav-item theme-setting-block-nav-link">

                  <Link onClick={() => setTogglesettings(!toggleSettings)} class="theme-setting-link on click">
                    <i class="bx bx-cog bx-flip-horizontal bx-spin"></i>
                  </Link>
                  <div class={toggleSettings ? "theme-setting-block open" : "theme-setting-block "} >
                    <div class="d-flex align-items-center">
                      <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
                      <div class="custom-control custom-switch theme-switch">
                        <input type="checkbox" checked={checked} onChange={() => handleCheckClick()} class="custom-control-input" id="customSwitchTheme" />
                        <label class="custom-control-label" for="customSwitchTheme"></label>
                      </div>
                      <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
                    </div>
                    <div className="d-flex align-items-center verticle-btn">
                      <span className="vertical-icon icon mr-1 d-block"><em className='bx bx-grid-vertical'></em></span>
                      <div className="custom-control custom-switch sidebar-switch">
                        <input type="checkbox" checked={resizechecked} onChange={() => handleCheckResize()} class="custom-control-input" id="sidebarSwitchBtn" />
                        <label className="custom-control-label" for="sidebarSwitchBtn"></label>
                      </div>
                      <span className="horizontal-icon icon"><em className='bx bx-grid-horizontal'></em></span>
                    </div>
                  </div>
                </li>
              </ul>
                    
            </nav>
          </div>
          <div>
             <Modal show={showPassword} onHide={closePasswordModal} >
                <Modal.Header closeButton>
                  <div class="d-flex align-items-center">
                      <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                      <h5 class="modal-title" id="exampleModalLongTitle">Change Password</h5>
                  </div>

                </Modal.Header>
                <Modal.Body closeButton>
                  <div class="notification-form password-modal">
                      <div class="row">
                          <div class="col-md-12">
                            <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                              <label className="label xl-label">Old  Password</label>
                              <div className="w-100 position-relative">
                                <input
                                  className='form-control'
                                  type={hiddenNewPassword ? "password" : "text"}
                                  placeholder='Old password'
                                  name='newPassword'
                                  value={newPassword}
                                  onChange={(e) => { setNewPassword(e.target.value); changeErrors = Object.assign(changeErrors, { newPassword: "" }); setErrors(changeErrors) }}
                                />
                                <OverlayTrigger      
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip1}>
                                  <span 
                                  onClick={() => setHiddenNewPassword(!hiddenNewPassword)}
                                  toggle="#password-field" 
                                  className={hiddenNewPassword ? "bx bx-hide field-icon-profile toggle-password" : "bx bx-show field-icon-profile toggle-password"}
                                  ></span>
                                </OverlayTrigger>
                                {/* <div onClick={() => setHiddenNewPassword(!hiddenNewPassword)} toggle="#password-field" className={hiddenNewPassword ? "bx bx-hide field-icon-profile toggle-password" : "bx bx-show field-icon-profile toggle-password"}></div> */}
                               
                              </div>
                            </div>
                            <div className={passwordUIFlag ? "class1 error-message-password d-flex align-items-center justify-content-end" : "class2 error-message-password d-flex align-items-center justify-content-end"}>
                              <div className='error-msg d-block mb-2' style={{ color: "red" }} >{changeErrors.newPassword}</div>
                            </div>
                            <div className="form-group d-flex align-items-center mb-md-4 mb-3 position-relative">
                              <label className="label xl-label">New  Password</label>
                              <div className="w-100 position-relative">
                                <input
                                  className='form-control'
                                  type={hiddenConfirmPassword ? "password" : "text"}
                                  placeholder='New password'
                                  id='confirmpassword'
                                  name='confirmPassword'
                                  value={confirmPassword} 
                                  onChange={(e) => { setConfirmPassword(e.target.value); changeErrors = Object.assign(changeErrors, { confirmPassword: "" }); setErrors(changeErrors) }}
                                />
                                <OverlayTrigger      
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}>
                                  <span 
                                  onClick={() => setHiddenConfirmPassword(!hiddenConfirmPassword)}
                                  toggle="#password-field" 
                                  className={hiddenConfirmPassword ? "bx bx-hide field-icon-profile toggle-password" : "bx bx-show field-icon-profile toggle-password"}
                                  ></span>
                                </OverlayTrigger>
                                {/* <div onClick={() => setHiddenConfirmPassword(!hiddenConfirmPassword)} toggle="#password-field" className={hiddenConfirmPassword ? "bx bx-hide field-icon-profile toggle-password" : "bx bx-show field-icon-profile toggle-password"}></div> */}
                                <em className='fa fa-lock' aria-hidden='true' />
                                
                              </div>
                            </div>
                            <div className={passwordUIFlag ? "class1 error-message-password d-flex align-items-center justify-content-end" : "class2 error-message-password d-flex align-items-center justify-content-end"}>
                              <div className='error-msg d-block mb-2' style={{ color: "red" }} >{changeErrors.confirmPassword}</div>
                            </div>
                            {/* <div className="error-message-password d-flex align-items-center justify-content-end">
                              <div className='error-msg d-block mb-2' style={{ color: "red" }} >{changeErrors.confirmPassword}</div>
                            </div> */}

                            <div className='mt-5 text-center'>
                              <button className='btn btn-primary mr-2' type='submit' onClick={(e) => resetPassword(e)}>  {
                                changeLoading ? "Loading..." : "Change Password "
                              }</button>
                            </div>
                          </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
          </div>
        </header>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  language: state.admin.language,
  theme: state.admin.theme,
  resize: state.admin.resize,
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
});



export default connect(mapStateToProps, { changeTheme, changeLanguage, changeResize, callApi, sidebaropen })(withRouter(Header));
