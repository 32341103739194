import React, { useState, useEffect } from 'react'
import {Button} from 'react-bootstrap';
import { connect } from 'react-redux'
import common from '../actions/common' // Validations
import { callApi } from '../actions/commonFunction'; // COMMON FUNCTIONS
import errorMessages from '../actions/errorMessages'; // ERROR MESSAGE
import { Link } from 'react-router-dom';
import API from '../actions/API';

function PasswordPage(props) {
  var [errors, setErrors] = useState({password:""});
  const [password, setPassword] = useState('');
	let [loading ,setLoading]=useState(false)

  const [isFormValid, setIsFormValid] = useState(true);

   /*******************  Form validation  ******************/
  const validateForm = () =>{
    let errors = { password: "" }
    let isFormValid = true;
    // for password
    if (!password.trim()) errors.password = errorMessages.PROVIDE_PASSWORD;
    else if (!common.validatePassword(password)) errors.password = errorMessages.PROVIDE_VALID_PASSWORD;
	  else errors.password = '';
    if (errors.password !== "") isFormValid = false;
    else isFormValid = true;
    setErrors(errors);setIsFormValid(isFormValid)
    return isFormValid;
  }

  //******************* Forgot Password ***************
  const sendPassword = async (e) => {
    e.preventDefault();
    setLoading(true)
    var  body={
      emailId:localStorage.getItem( "emailId") ,
      password:password
    };
    try{
      if(validateForm()){
        const response = await props.callApi(API.LOGIN,body, 'post');
        setLoading(false)
        if(response.status === 1){
          common.showSuccessMessage(response.message)
          setPassword("")
          setErrors("")
          props.history.push('/dashboard')
        } 
      }
    }catch(error) {
      setLoading(false)
      throw (error)
    }
  }

  console.log("props" ,props && props.location.state)
 let data= localStorage.getItem( "emailId")
 console.log("data",data)
  return (
    <div className="App">
      <div className="login-wrapper" style={{background:"url(../assets/images/login-bg.jpg) no-repeat center center"}}>
      <div className="login-body">
      <div className="login-container row m-0">
				<div className="col-xl-8 col-11">
					<div className="card bg-login-card">
						<div className="row m-0">
							<div className="col-md-6 col-12 pl-0 pr-0">
								<div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
									<div className="card-header">
										<div className="card-title text-center mb-4">
											<h4 className="mb-2 mt-2">Password?</h4>
										</div>
										{/* <div className="text-center"><p className="common-small-text"><small>Enter the email or phone number you used when you joined and we will send you temporary password</small></p></div> */}
									</div>
									<div className="card-content">
										<div className="card-body">
											<form onSubmit={(evt)=>sendPassword(evt)}>
												<div className="form-group mb-2">
													<label for="EmailAddress">Password<sup className="text-danger">*</sup></label>
													<input type="password" 
													className={errors.password ? "form-control input-error" : "form-control"} 
													id="EmailAddress" 
													aria-describedby="emailHelp" 
                          placeholder="Enter password "
                          value={password}
                          name={password}
                          onChange={(e) => {setPassword(e.target.value) ;errors=Object.assign(errors, { password: "" });setErrors(errors)}}
												  />
                          <span className="error-msg" style={{color: "red"}}>{errors.password}</span>
												</div>
												<Button 
												type="button" 
												className="btn btn-primary glow position-relative btn-block" 
												onClick={(evt)=>sendPassword(evt,'password')}>
											{
														loading? "Loading...":"Send password"
													}<i className="icon-arrow bx bx-right-arrow-alt"></i>
												</Button>
											</form>
                      {/* <div className=" d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 mb-3">
												<div className="text-left common-small-text">
													<small><a href="/">Sign in</a></small>
												</div>
												<div className="text-right common-small-text">
                        <small>Don’t have an account?  <Link to="/register" >Sign Up</Link></small>	
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
								<div className="card-content">
										<img className="img-fluid" src="../assets/images/register.png" alt="sign up"></img>
								</div>
							</div>
						</div>
					</div>
				</div>
    </div>
    </div>
      </div>
    </div>
  );
}

export default connect(null, { callApi })(PasswordPage)