import React, { useState, useEffect } from "react";
import { callApi } from '../actions/commonFunction'
import { Link, withRouter } from "react-router-dom";
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from "react-select";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import common from '../actions/common'
import PhoneInput from 'react-phone-input-2'
import { connect } from "react-redux";
import moment, { months } from "moment";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import errorMessages from '../actions/errorMessages';
import countryList from 'react-select-country-list';
import API from "../actions/API"
import CropImagesProfile from '../utils/CropImagesProfile'
import { IMG_URL } from '../config/configs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home'
const queryString = require('query-string');

let { Option } = Select;
function Profile(props) {
  const options = countryList().getData();
  const [photo, setPhoto] = useState("")
  const [birthday, setBirthday] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  let [changeLoading, setChangeLoading] = useState(false);
  let [changeErrors, setErrors] = useState({ newPassword: "", confirmPassword: "" });
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "Male",
    dob: "",
    photo: "",
    website: "",
    mobile: "",
    address: "",
    country: "",
    twitterLink: "",
    fbLink: "",
    instagramLink: "",
    gitHubLink: "",
    codePen: "",
    slack: "",
    errors: {},
  });
  let [loading, setLoading] = useState(false);
  let [disablePhoneinput, setdisablePhoneinput] = useState(false);
  let [isFormValid, setIsFormValid] = useState('');
  const [hidden, setHidden] = useState(true);
  const [showInput, setShowInput] = useState({});
  const [passwordUIFlag, setPasswordUIFlag] = useState(false);
  const [hiddenNewPassword, setHiddenNewPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);

  const validateForm = () => {
    const {
      firstname,
      lastname,
      email,
      dob,
      website,
      mobile,
      address,
      country,
      twitterLink,
      fbLink,
      instagramLink,
      gitHubLink,
      codePen,
      slack,
      errors
    } = formData;

    // for firstName
    if (formData.hasOwnProperty("firstname")) {
      if (isEmpty(firstname))
        errors.firstname = errorMessages.PROVIDE_FIRST_NAME;
      else delete errors.firstname;
    }

    //for lastName
    if (formData.hasOwnProperty("lastname")) {
      if (isEmpty(lastname))
        errors.lastname = errorMessages.PROVIDE_LAST_NAME;
      else delete errors.lastname;
    }

    // for email
    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
      else if (!common.validateEmail(email))
        errors.email = errorMessages.PROVIDE_VALID_EMAIL;
      else delete errors.email;
    }

    //for mobile
    // let mobileFormat = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    // if (formData.hasOwnProperty("mobile")) {
    //   console.log("isMobile", isEmpty(mobile.split("-")[1]))
    //   if (isEmpty(mobile.split("-")[1])) {
    //     errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER
    //   }
    //   else delete errors.mobile;
    // }

    //for address
    if (formData.hasOwnProperty("address")) {
      if (address.length > 35) {
        errors.address = "Address shouldn't be more than 35 characters"
      }
      else delete errors.address;
    }


    //for website
    let lowerWebsite = website.toLowerCase();
    if (formData.hasOwnProperty("website")) {
      if (!isEmpty(lowerWebsite)) {
        if (!(lowerWebsite.indexOf(" ") > 0) && lowerWebsite.includes("https://www.") && !lowerWebsite.includes("..") && !lowerWebsite.includes("///"))
          delete errors.website;
        else errors.website = errorMessages.PROVIDE_WEBSITE;
      } else {
        delete errors.website;
      }
    }

    //for twitterLink
    let lowerTwitterlink = twitterLink.toLowerCase();
    if (formData.hasOwnProperty("twitterLink")) {
      if (!isEmpty(lowerTwitterlink)) {
        if (!(lowerTwitterlink.indexOf(" ") > 0) && lowerTwitterlink.includes("https://www.twitter.com") && !lowerTwitterlink.includes("..") && !lowerTwitterlink.includes("///"))
          delete errors.twitterLink;
        else errors.twitterLink = errorMessages.PROVIDE_TWITTER;
      } else {
        delete errors.twitterLink;
      }
    }

    //for fbLink
    let lowerFbLink = fbLink.toLowerCase();
    if (formData.hasOwnProperty("fbLink")) {
      if (!isEmpty(lowerFbLink)) {
        if (!(lowerFbLink.indexOf(" ") > 0) && lowerFbLink.includes("https://www.facebook.com") && !lowerFbLink.includes("..") && !lowerFbLink.includes("///"))
          delete errors.fbLink;
        else errors.fbLink = errorMessages.PROVIDE_FBURL;
      } else {
        delete errors.fbLink;
      }
    }

    //for instagramLink
    let lowerInstagramLink = instagramLink.toLowerCase();
    if (formData.hasOwnProperty("instagramLink")) {
      if (!isEmpty(lowerInstagramLink)) {
        if (!(lowerInstagramLink.indexOf(" ") > 0) && lowerInstagramLink.includes("https://www.instagram.com") && !lowerInstagramLink.includes("..") && !lowerInstagramLink.includes("///"))
          delete errors.instagramLink;
        else errors.instagramLink = errorMessages.PROVIDE_INSTAGRAM;
      } else {
        delete errors.instagramLink;
      }
    }

    //for gitHubLink
    let lowerGithubLink = gitHubLink.toLowerCase();
    if (formData.hasOwnProperty("gitHubLink")) {
      if (!isEmpty(lowerGithubLink)) {
        if (!(lowerGithubLink.indexOf(" ") > 0) && lowerGithubLink.includes("https://www.github.com") && !lowerGithubLink.includes("..") && !lowerGithubLink.includes("///"))
          delete errors.gitHubLink;
        else errors.gitHubLink = errorMessages.PROVIDE_GITHUB;
      } else {
        delete errors.gitHubLink;
      }
    }

    //for codepen
    let lowerCodepen = codePen.toLowerCase();
    if (formData.hasOwnProperty("codePen")) {
      if (!isEmpty(lowerCodepen)) {
        if (!(lowerCodepen.indexOf(" ") > 0) && lowerCodepen.includes("https://www.codepen.com") && !lowerCodepen.includes("..") && !lowerCodepen.includes("///"))
          delete errors.codePen;
        else errors.codePen = errorMessages.PROVIDE_CODEPEN;
      } else {
        delete errors.codePen;
      }
    }

    //for slack
    // let lowerSlack = slack.toLowerCase();
    // if (formData.hasOwnProperty("slack")) {
    //   if(!isEmpty(lowerSlack)) {
    //     if (!(lowerSlack.indexOf(" ") > 0) && lowerSlack.includes("https://www.slack.com") && !lowerSlack.includes("..") && !lowerSlack.includes("///"))
    //       delete errors.slack; 
    //     else errors.slack = errorMessages.PROVIDE_SLACK;
    //   } else  {
    //     delete errors.slack;
    //   }
    // }

    delete errors.gender;

    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    console.log("Form data", formData);
    return isFormValid;
  };



  const handleChange = (e) => {
    console.log("set form data", e)
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    } else
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
  };

  const handleOnChanges = (value, data) => {
    setdisablePhoneinput(false)
    let dialnums = data.dialCode;
    let mobilenums = value.slice(data.dialCode.length);
    mobile = `+${dialnums}-${mobilenums}`;
    if (mobile.length >= 10) {
      setdisablePhoneinput(true)
    }
    setFormData({
      ...formData,
      mobile: mobile
    })
  }

  const handleCountrySelector = val => {
    country = val;
    console.log(val)
    setFormData({
      ...formData,
      country: country
    })
  }

  const editAdminProfile = async (e) => {
    e.preventDefault();
    let {
      firstname,
      lastname,
      email,
      dob,
      website,
      mobile,
      gender,
      address,
      country,
      twitterLink,
      fblink,
      instagramLink,
      gitHubLink,
      codePen,
      slack,
    } = formData;

    let body = Object.assign({}, formData)

    body.dob = birthday
    body.username = firstname + lastname
    body.country = body.country && body.country.label && body.country.label
    body.gender = body.gender && body.gender.label
    delete body.errors

    // console.log("Body", body)

    if (validateForm()) {
      setLoading(true)
      try {
        const response = await props.callApi(API.UPDATE_PROFILE, body, 'post', "EDITADMIN_PROFILE", true);
        setLoading(false)
        if (response.status === 1) {
          // common.showSuccessMessage("Details Updated Successfully")
          toast.success('Details updated successfully', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      } catch (error) {
        throw (error)
      }
    }
  }

  let {
    firstname,
    lastname,
    email,
    website,
    mobile,
    gender,
    address,
    country,
    twitterLink,
    fbLink,
    instagramLink,
    gitHubLink,
    codePen,
    slack,
    errors,
  } = formData,
    { emailshow, mobileShow, emailText } = showInput;


  useEffect(() => {
    getAdminProfile();
  }, []);

  // ################## Get AdminData ###############
  const getAdminProfile = async () => {
    const response = await props.callApi(API.GET_PROFILE, '', 'get', "ADMIN_PROFILE", true);
    if (response.status === 1) {
      let { data } = response,
        { firstname, lastname, emailId, mobile, photo, dob, gender, website, address, country, fbLink,
          twitterLink, instagramLink, gitHubLink, slack, codePen } = data;
          console.log("gender country get", data, data.country, data.gender)
      setFormData({
        ...formData,
        firstname: firstname,
        lastname: lastname,
        email: emailId,
        gender : {
          label: gender,
          value: gender
        },
        photo: photo,
        website: website,
        mobile,
        address: address,
        country: {
          label: country,
          value: country
        },
        twitterLink: twitterLink,
        fbLink: fbLink,
        instagramLink: instagramLink,
        gitHubLink: gitHubLink,
        codePen: codePen,
        slack: slack,
      });
      setBirthday(dob)
      setPhoto(photo)
    }
  }

  let imagePreview;
  if (photo) {
    imagePreview = (<img src={photo ? IMG_URL + photo : ''} alt="Icon" />);
  } else {
    imagePreview = (<img src={"/assets/images/avatar-s-16.jpg"} alt="Icon" />);
  }

  const renderTooltip = (props) => (
    !hiddenConfirmPassword ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> : <Tooltip id="button-tooltip" {...props}>
        Show Password
    </Tooltip>
  );

  const renderTooltip1 = (props) => (
    !hiddenNewPassword ? <Tooltip id="button-tooltip" {...props}>
      Hide Password
    </Tooltip> : <Tooltip id="button-tooltip" {...props}>
        Show Password
    </Tooltip>
  );

  const validateFormForPassword = () => {
    let changeErrors = { newPassword: "", confirmPassword: "" }
    let isFormValid = true;

    //for new password
    if (!newPassword.trim()) {
      setPasswordUIFlag(true);
      changeErrors.newPassword = errorMessages.PROVIDE_PASSWORD;
    } else if (!common.validatePassword(newPassword)) {
      setPasswordUIFlag(false);
      changeErrors.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    } else changeErrors.newPassword = '';

    //for confirm password
    if (!confirmPassword.trim()) {
      setPasswordUIFlag(true);
      changeErrors.confirmPassword = errorMessages.PROVIDE_PASSWORD;
    } else if (!common.validatePassword(confirmPassword)) {
      setPasswordUIFlag(true);
      changeErrors.confirmPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    }
    else changeErrors.confirmPassword = '';

    if (changeErrors.newPassword !== '' || changeErrors.confirmPassword !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(changeErrors);
    setIsFormValid(isFormValid);
    return isFormValid;
  }

  const removePhoto = async (e) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      dob,
      website,
      mobile,
      address,
      country,
      twitterLink,
      fbLink,
      instagramLink,
      gitHubLink,
      codePen,
      slack,
      errors
    } = formData;
    // console.log("rr", this.props.admindata.firstname)
    var body = {
      emailId: email,
      firstname: firstname,
      lastname: lastname,
      mobile: mobile,
      photo: ""
    }
    const response = await props.callApi(API.UPDATE_PROFILE, body, 'post', "EDITADMIN_PROFILE", true);
    if (response.status === 1) {
      // common.showSuccessMessage("Details Updated Successfully");
      toast.success('Details updated successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      getAdminProfile();
    }
  }

  const genderOption = [
    { value: 'Female', label: 'Female' },
    { value: 'Male', label: 'Male' }
  ]

  const genderHandler = (event) => {
    if(event.value && event.label) {
      setFormData({
        ...formData,
        gender: event
      })
    } else {
      setFormData({
        ...formData,
        gender: "",
      })
    }
  }

  return (
    <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <form className="mb-7">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="content-header-title">User Profile</li>
                      <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                      <li className="breadcrumb-item active" aria-current="page">User Profile</li>
                    </ol>
                  </nav>
                  <div className="btn-blocks mb-md-0 mb-2">
                    <a className="btn btn-primary glow-primary mr-3" onClick={(e) => editAdminProfile(e)}><em className="bx bx-revision mr-2" />{loading ? "Updating" : "Update"}</a>
                  </div>
                </div>
                <div class="card profile-card mb-5">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="edit-image">
                        <div className="user-image">
                          {imagePreview}
                          <label className="img-upload" htmlfor="attach-file">
                            {/* <input type="file" id="attach-file" /> */}
                            <CropImagesProfile getAdminProfile={getAdminProfile} />
                            <em className="bx bxs-edit-alt" />
                          </label>
                        </div>
                      </div>
                      <div className="mt-3 mt-sm-4">
                        {!(imagePreview.props.src === "/assets/images/avatar-s-16.jpg") ?
                          <Button className="btn btn-primary" onClick={e => removePhoto(e)}>Remove photo</Button>
                          : null}
                        {/* <Button className="btn btn-primary" onClick={e => removePhoto(e)}>Remove photo</Button> */}
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="user-title-info user-details">
                            <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                              <label className="mb-0 xl-label" htmlFor="firstname">First Name<sup className="text-danger">*</sup></label>
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="firstname"
                                  name="firstname"
                                  placeholder="Enter first name"
                                  disabled
                                  value={firstname}
                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="text-danger d-block">{errors.firstname}</span>
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                              <label className="mb-0 xl-label" htmlFor="lastname">Last Name<sup className="text-danger">*</sup></label>
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="lastname"
                                  name="lastname"
                                  placeholder="Enter last name"
                                  disabled
                                  value={lastname}
                                  onChange={(e) => handleChange(e)}
                                />

                                <span className="text-danger d-block">{errors.lastname}</span>
                              </div>
                            </div>

                            {/* <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Email">Email</label>
                        <div className="w-100">
                          <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          disabled
                          onChange={(e) => handleChange(e)}
                          />
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      </div>

                      {
                        mobile && mobile !="" ?

                        <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Email">Phone number</label>
                        <div className="w-100">
                          <PhoneInput
                              disabled
                              className="form-control w-100"
                              name="phone"
                              country={'in'}
                              masks={''}
                              countryCodeEditable={false}
                              value={mobile}
                              onChange={handleOnChanges}
                              />
                          </div>
                        </div>:null
                        }*/}




                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="user-title-info user-details">
                            <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                              <label className="mb-0 xl-label" htmlFor="Email">Email<sup className="text-danger">*</sup></label>
                              <div className="w-100">
                                <input
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter email"
                                  type="email"
                                  value={email}

                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="text-danger d-block">{errors.email}</span>
                              </div>
                            </div>

                            {
                              mobile && mobile != "" ?

                                <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                                  <label className="mb-0 xl-label" htmlFor="Email">Phone number<sup className="text-danger">*</sup></label>
                                  <div className="w-100">
                                    <PhoneInput
                                      className="form-control w-100"
                                      name="phone"
                                      country={'in'}
                                      masks={''}
                                      countryCodeEditable={false}
                                      value={mobile}
                                      onChange={handleOnChanges}
                                    />
                                  </div>
                                </div> : null
                            }

                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6 mb-5">
                    <div className="card profile-card">
                      <h6 className="d-flex align-items-center title mb-4"><i className="bx bx-user mr-1" />Personal Info</h6>
                      <div className="form-group d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="BirthDate">Birth Date</label>
                        <fieldset className="position-relative w-100">
                          <DatePicker
                            selected={birthday ? new Date(birthday) : ""}
                            dateFormat="d MMM yyyy"
                            placeholderText="Select Date"
                            className="form-control w-100"
                            onChange={(evt) => {
                              setBirthday(evt)
                              setFormData({
                                ...formData,
                                dob: birthday,
                              });
                            }}
                          />
                          <span className="text-danger d-block">{errors.birthday}</span>
                        </fieldset>
                      </div>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="WebSite">Website</label>
                        <div className="w-100">
                          <input type="text"
                            className="form-control"
                            id="website"
                            name="website"
                            placeholder="Enter your website"
                            value={website}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="text-danger d-block">{errors.website}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="gender">Gender</label>
                        <Select className="selectpicker w-100"
                        id="gender"
                        name="gender"
                        value={gender}
                        options={genderOption}
                        onChange={(e) => genderHandler(e)}>
                        </Select>
                      </div>
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Address">Address</label>
                        <div className="w-100">
                          <input type="text"
                            id="address"
                            name="address"
                            className="form-control"
                            placeholder="Enter address"
                            value={address}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="text-danger d-block">{errors.address}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Country">Country</label>
                        <Select
                          className="selectpicker w-100"
                          data-live-search="true"
                          id="country"
                          name="country"
                          value={country}
                          options={options}
                          onChange={handleCountrySelector}
                        // menuIsOpen={true}
                        />
                      </div>
                      <span className='text-danger d-block'>{errors.country}</span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="card profile-card">
                      <h6 className="d-flex align-items-center title mb-4"><i className="bx bx-link-alt mr-1" />Social Links</h6>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="Twitter">Twitter</label>
                        <div className="w-100">
                          <input type="text"
                            className="form-control"
                            id="twitterLink"
                            name="twitterLink"
                            placeholder="Enter twitter link"
                            value={twitterLink}
                            onChange={(e) => handleChange(e)} />
                          <span className="text-danger d-block">{errors.twitterLink}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="Facebook">Facebook</label>
                        <div className="w-100">
                          <input type="text"
                            id="fbLink"
                            name="fbLink"
                            className="form-control"
                            placeholder="Enter facebook link"
                            value={fbLink}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="text-danger d-block">{errors.fbLink}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="Instagram">Instagram</label>
                        <div className="w-100">
                          <input type="text"
                            className="form-control"
                            id="instagramLink"
                            name="instagramLink"
                            placeholder="Enter instagram link"
                            value={instagramLink}
                            onChange={(e) => handleChange(e)} />
                          <span className="text-danger d-block">{errors.instagramLink}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Github">Github</label>
                        <div className="w-100">
                          <input type="text"
                            className="form-control"
                            id="gitHubLink"
                            name="gitHubLink"
                            placeholder="Enter github link"
                            value={gitHubLink}
                            onChange={(e) => handleChange(e)} />
                          <span className="text-danger d-block">{errors.gitHubLink}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-md-5 mb-3 user-details">
                        <label className="mb-0" htmlFor="Codepen">CodePen</label>
                        <div className="w-100">
                          <input type="text"
                            className="form-control"
                            id="codePen"
                            name="codePen"
                            placeholder="Enter codepen link"
                            value={codePen}
                            onChange={(e) => handleChange(e)} />
                          <span className="text-danger d-block">{errors.codePen}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </form>
            </div>
            <footer>
              <div className="footer-text d-flex align-items-centerf justify-content-between">
                <span className="d-block">2020 © IndiaNIC</span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </Home>
  );
}

const mapStateToProps = state => ({
  // UserId: state.admin.edituserId,
  // UserAccess: state.admin.adminData.rolePermission.userAccess,

})

export default connect(mapStateToProps, { callApi })(Profile);
