import React, { useEffect, useState } from 'react';
import '../App.css';
import Home from './Home.jsx';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { connect } from 'react-redux';
import { callApi } from '../actions/commonFunction';
import API from '../actions/API';
import { Link } from "react-router-dom";
import ReactFC from 'react-fusioncharts';
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { constant } from 'lodash';
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


function Dashboard(props) {

  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [usersCount, setUsersCount] = useState('');
  const [tasksCount, setTasksCount] = useState('');
  const [mentorsCount, setMentorsCount] = useState('');
  const [tutorsCount, setTutorsCount] = useState('');
  const [deptCount, setDeptCount] = useState('');
  const [subjectsCount, setSubjectsCount] = useState('');
  const [workTypeCount, setWorkTypeCount] = useState('');


  useEffect(() => {
    getDashboardData();
  }, []);

  //**************** GET ALL DEPARTMENT ****************** */
  const getDashboardData = async() => {
    setLoading(true);
    let body = {
      token: localStorage.getItem('accessToken')
    };
    try {
    const response = await props.callApi(API.GET_DASHBOARD_DATA, body, 'GET', null, true);
    console.log("response", response);
      if (response.settings.status === 1) {
        setUsersCount(response.data.students);
        setTasksCount(response.data.tasks);
        setMentorsCount(response.data.mentors);
        setTutorsCount(response.data.tutors);
        setDeptCount(response.data.departments);
        setSubjectsCount(response.data.subjects);
        setWorkTypeCount(response.data.worktypes);
      } else {
        toast.error(response.settings.message,{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
      setLoading(false);
    } catch(error) {
      setLoading(false);
      throw (error)
    }
  }

  const list = [
    { name: 'Jan' },
    { name: 'Feb' },
    { name: 'March' },
    { name: 'April' },
    { name: 'May' },
    { name: 'June' },
    { name: 'July' },
    { name: 'Aug' },
    { name: 'Sep' },
    { name: 'Oct' },
    { name: 'Nov' },
    { name: 'Dec' }
  ];

  const chartData = [
    { label: "January", value: "290" },
    { label: "February", value: "260" },
    { label: "March", value: "180" },
    { label: "April", value: "140" },
    { label: "May", value: "115" },
    { label: "June", value: "100" },
    { label: "July", value: "30" },
    { label: "August", value: "100" },
    { label: "September", value: "10" },
    { label: "October", value: "70" },
    { label: "November", value: "80" },
    { label: "December", value: "150" }
  ];

  const MenuItem = ({text, selected}) => {
    return <div
      className={`menu-item ${selected ? 'active' : ''}`}
      >{text}</div>;
  };

  const Menu = (list, selected) =>
  list.map(el => {
    const {name} = el;
    return <MenuItem text={name} key={name} selected={selected} />;
  });

  const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
  };

  const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
  const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

  const onSelect = (key) => {
    setSelected(key);
  }

  const menuItems = Menu(list, selected);


  const chartConfigs = {
    type: "column2d", // The chart type
    width: "1300", // Width of the chart
    height: "800", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "Yearly Income(2020-2021)",    //Set the chart caption
        // subCaption: "In MMbbl = One Million barrels",             //Set the chart subcaption
        xAxisName: "Months",           //Set the x-axis name
        yAxisName: "Income",  //Set the y-axis name
        numberSuffix: "K",
        theme: "fusion"                 //Set the theme for your chart
      },
      // Chart Data - from step 2
      data: chartData
    }
  };

  return (
    <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
                {/* <ScrollMenu
                  data={menuItems}
                  arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                  selected={selected}
                  onSelect={() => onSelect()}
                /> */}
                {
                  !loading ?
                  <div className="card">
                  <div className="row">
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="firstName">First Name<sup className="text-danger">*</sup></label> */}
                        <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listUsers')}>
                            {usersCount} Users
                          </Link>
                        </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="lastName">Last name<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listTasks')}>
                            {tasksCount} Tasks
                          </Link>
                      </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                         <Link onClick={() => props.history.push('/listMentors')}>
                            {mentorsCount} Mentors
                          </Link>
                      </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listTutors')}>
                            {tutorsCount} Tutors
                          </Link>
                      </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listDepartments')}>
                          {deptCount} Departments
                          </Link>
                      </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listSubjects')}>
                            {subjectsCount} Subjects
                          </Link>
                      </div>
                    </div>
                    <div className="form-group col-md-3 mb-md-5 mb-3">
                      {/* <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label> */}
                      <div className="dashboard-detail-box">
                          <Link onClick={() => props.history.push('/listWorkType')}>
                             {workTypeCount} Work Type
                          </Link>
                      </div>
                    </div>
                  </div>
                  <div className="each-detail"><h4>Task Receipts</h4></div>
                   <ReactFC {...chartConfigs} />
                  </div> : <div className="each-detail text-center"><h4>Loading</h4></div> 
                }
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
}
const mapStateToProps = state => ({
  admindata: state.admin.adminData,
  language: state.admin.language
});

export default connect(mapStateToProps, { callApi })(Dashboard);
