import React, { useEffect, useState} from 'react';
import Home from '../Home'
import queryString from 'query-string'
import { connect } from 'react-redux'
import {Link} from "react-router-dom";
import { callApi } from '../../actions/commonFunction'
import common from '../../actions/common';
import API from '../../actions/API';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import * as language from "../../translations/index";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {IMG_URL} from '../../config/configs';
import parser from 'html-react-parser';
import moment from 'moment';
import swal from 'sweetalert';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSortng} from '../CommonFeature/Sorting';

function StaticPagePreview(props){

  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(1);
  const [sortData,sort, onSort] = useSortng();
  const [blogListing, setBlogListing] = useState([]);
  const [blogTitle, setBlogTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [dateFormatUI, setDateFormatUI] = useState('');
  const [timeFormatUI, setTimeFormatUI] = useState('');
  const [timeZoneUI, setTimeZoneUI] = useState('');

  useEffect(() => {
    getAllBlogs();
  }, [])

  let blogStaticId = props.match.params.slug;
  
  const deleteBlog = (id) => {
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          var body = { ids: [id] }
          const response = await props.callApi(API.DELETE_BLOG, body, 'post', null, true);
          if (response.status === 1) {
            toast.success('Deleted successfully',{
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              props.history.push('/staticPage');
            }, 1000);
            getAllBlogs();
          }
        }
      });
  }

  const getAllBlogs = async(filterObj) => {
    var body = {page, pagesize, sort, columnKey:"blogListing", filter:filterObj ? filterObj:""}
      try {
      const response = await props.callApi(API.LIST_BLOG, body, 'post', null, true);
        if (response.status === 1) {
          setBlogListing(response.data.listing);
          setLoading(false);
        } 
      } catch(error){
        throw (error)
      }
  }

  useEffect(() => {
    (async () => {
      try {
        let body = {
          loginId : localStorage.getItem('accessToken')
        }

        const response = await props.callApi(API.GET_DATE_SETTINGS, body, 'get', null, true);
        if (response.status === 1) {
          let { dateFormat, timeFormat, timeZone } = response.data;
          setDateFormatUI(dateFormat);
          setTimeFormatUI(timeFormat);
          setTimeZoneUI(timeZone);
        } 
      } catch (error) {
        throw error;
      }
    })();
  }, [])
  
  //****** Date Time TimeZone Conversion based on setting in General Settings *********** */
  const getDateTimeFormat = (inputDatetime) => {
    let mergedDate;
    let timeZone = timeZoneUI.split(" ")[1];
    if (timeFormatUI === "24 Hours") {
      mergedDate = moment(inputDatetime).tz(`${timeZone}`).format(`${dateFormatUI} HH:mm `);
    } else {
      mergedDate = moment(inputDatetime).tz(`${timeZone}`).format(`${dateFormatUI} h:mm A `);
    }
    return mergedDate;
  }

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
                <div className="main-content-block">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="content-header-title">Content</li>
                      <li className="breadcrumb-item" ><Link onClick={() => {
                          props.history.push('/dashboard');
                        }
                        }><i className="bx bx-home-alt" /></Link></li>
                      <li className="breadcrumb-item">
                        <Link onClick={() => {
                          props.history.push('/staticPage');
                        }
                      }>Static Pages</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Static Preview</li>
                    </ol>
                  </nav>
                  
                    <div className="blog-page">
                      <div className="back-btn d-flex align-items-center mb-3">
                          <Link className="" 
                            onClick={() => {props.history.push('/staticPage'); }}
                            ><em className="bx bx-chevron-left mr-1"></em><span>Back</span>
                          </Link>
                      </div>
                      <div className="container">
                      {
                        loading ? <Loader/> :
                        blogListing.length && 
                        blogListing.filter((item, key) =>  item._id === blogStaticId)
                        .map((item, key) => {
                        return ( 
                        <div className="blog-content">
                            <head>
                              <meta name="meta-title" content={item.metaTitle}/>
                              <meta name="meta-keyword" content={item.metaKeyword}/>
                              <meta name="meta-description" content={item.metaDescription}/>
                            </head>
                            <div className="blog-title mb-3 inner-width text-left d-flex align-items-center justify-content-between">{item.blogTitle}

                                <div className="edit-btns">
                                <Link className="btn btn-primary mr-0 mr-sm-2" 
                                  onClick={() => props.history.push(`/editStatic/${blogStaticId}`)}>Edit
                                </Link>
                                <Link className="btn btn-light-primary" onClick={() => deleteBlog(blogStaticId)}>Delete</Link>
                                </div>
                            </div>
                            <div className="blog-meta mb-5 inner-width text-left">
                                By {item.createdBy}, posted on {item.postDate ?  getDateTimeFormat(item.postDate) : null} ,in {item.blogCategory}
                            </div>
                            <div className="hero-img mb-5 text-center inner-width">
                              <img
                              src={IMG_URL + item.image} 
                              alt="blog" className="w-100"
                              />
                            </div>
                            <div className="blog-description inner-width">
                              <p>{parser(item.caption)}</p>
                            </div>
                          </div>
                        )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Home>
    );
  }

const mapStateToProps = state => ({
  CmsPagesAccess: state.admin.adminData.rolePermission.cmsPagesAccess,
  admindata: state.admin.adminData,
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(StaticPagePreview)