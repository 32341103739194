import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import { callApi } from '../../actions/commonFunction';
import Select from "react-select";
import common from '../../actions/common';
import {Link, withRouter} from "react-router-dom"
import errorMessages from '../../actions/errorMessages';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import API from '../../actions/API';

function ViewMentor(props){

    const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [workType, setWorkType] = useState('');
    const [shiftSlot, setShiftSlot] = useState('');
    const [description, setDescription] = useState('');
    const [selectDays, setSelectDays] = useState('');
    const [loading, setLoading] = useState(false);
    const [mentorId, setMentorId] = useState(props.match.params.id);
    const [tempArr,setTempArr] = useState(["mon", "tue", "wed", "thu", "fri", "sat", "sun"]);

    useEffect(() => {
      getMentorDetails(mentorId);
    }, []);

    //*************** Get Mentor Details ****************/
    const getMentorDetails = async (id) => {
      try {
        const response = await props.callApi(API.GET_MENTOR + id, '', 'get', null, true);
        console.log("response get", response)
        let {data, settings} = response;
        if (response.settings.status === 1) {
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setCountryCode(data.countryCode);
          setMobile(data.mobileNumber);
          setDepartment(data.departments);
          setWorkType(data.worktypes);
          setShiftSlot(data.availability);
          setDescription(data.description);
          setLoading(true);
        } else {
          toast.error(response.settings.message,{
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      } catch (error) {
        throw error;
      }
    }

    const renderSlot = () => {

      // Easy way for filtering out single slot(static method)
      let singleSlot=[];
  
      if (shiftSlot && shiftSlot.length) {
        singleSlot.push({from: shiftSlot[0].from, until: shiftSlot[0].until})
        // console.log("singleslot", singleSlot);
        return (
          singleSlot && singleSlot.length ? 
          <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
        )
      } else {
        return null;
      }
  
  
      // proper way for filtering out single slot(if huge records)
     
      // let uniqueSlot, singleSlot;
  
      // // creating deep clone of shiftSlot
      // let newSlot = JSON.parse(JSON.stringify(shiftSlot));
      
      // // filtering only "from" and "until" from shiftSlot object
      // if (newSlot && newSlot.length) {
      //   newSlot.map((shift,index, arr) => { delete shift._id; delete shift.day}); 
      // }
  
      // // filtering unique values from object
      // uniqueSlot = new Set(newSlot.map(JSON.stringify));
  
      // // converting back to array of object
      // singleSlot = Array.from(uniqueSlot).map(JSON.parse);
      // return (
        // singleSlot && singleSlot.length ? 
        // <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
      // )
    }
  
  
   // *********************

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Mentor</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listMentors')}>List All Mentors</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">View Mentor</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">Mentor Details</h5>
                    </div>
                  </div>
                  <form action="#">
                    {
                      loading ? 
                      <div className="row">
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="firstName">First Name<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{firstName}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="lastName">Last name<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{lastName}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="Encryption">Email ID<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{email}</div>
                      </div>
                       
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="department">Department<sup className="text-danger">*</sup></label>
                        <div className="each-detail">
                        {
                          department && department.length ? 
                          department.map((dept,index, arr) => {
                            if (dept !== arr[arr.length-1]) {
                              return <span key={index}>{`${dept.name}, `}</span>
                            } else {
                              return <span key={index}>{`${dept.name}`}</span>
                            }
                          }) : null 
                        }
                        </div>
                      </div>
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="workType">WorkType<sup className="text-danger">*</sup></label>
                        <div className="each-detail">
                          {
                            
                            workType && workType.length ? 
                            workType.map((work,index, arr) => {
                              if (work !== arr[arr.length-1]) {
                                return <span key={index}>{`${work.format}, `}</span>
                              } else {
                                return <span key={index}>{`${work.format}`}</span>
                              }
                            }) : null 
                            
                          }
                        </div>
                      </div>
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="shiftSlot">Shift Slot<sup className="text-danger">*</sup></label>
                          <div className="each-detail">
                            { 
                               renderSlot()
                            }
                          </div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                         <div><label>Select Days<sup className="text-danger">*</sup></label></div>
                        <div className="d-inline-flex">
                         {
                            shiftSlot ? tempArr.map((el, i) => {
                              const day = shiftSlot.find(obj => obj.day === el);
                              const available = !!day;
                              return (
                                <>
                                <div className="form-check mr-2">
                                  <input 
                                      key={i}
                                      className="form-check-input" 
                                      type="checkbox" 
                                      value={el}
                                      id={el}
                                      checked={available}
                                    />
                                  <label className="form-check-label" htmlFor={el} >
                                    {el}
                                </label>
                                </div>
                                </>
                              )
                            }) : null
                          }
                        </div>
                      </div>
                      <div className="form-group col-md-6 mb-md-5 mb-3">
                        <label htmlFor="password">Description<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{description}</div>
                      </div>
                      
                    </div> : <div className="each-detail text-center"><h4>Loading</h4></div>
                    }
                    
                  </form>
                  <div className="text-right mt-md-5 mt-3">
                    <button className="btn btn-primary mr-2" onClick={() => props.history.push(`/editMentor/${mentorId}`)}>Edit</button>
                    <button className="btn btn-primary btn-glow" onClick={() => props.history.push('/listMentors')}>Cancel</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(ViewMentor);