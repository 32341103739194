import React, { useState, useEffect } from 'react'
import {Button} from 'react-bootstrap';
import { connect } from 'react-redux'
import common from '../actions/common' // Validations
import { callApi } from '../actions/commonFunction'; // COMMON FUNCTIONS
import errorMessages from '../actions/errorMessages'; // ERROR MESSAGE
import { Link } from 'react-router-dom';
import API from '../actions/API';
import Theme from './Theme'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ForgotPassword(props) {

  var [errors, setErrors] = useState({emailId:""});
  const [emailId, setEmailId] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  let [loading ,setLoading] = useState(false);

   /*******************  Form validation  ******************/
  const validateForm = () =>{
    let errors = { emailId: "" }
    let isFormValid = true;
    
    // for emailId
    if (!emailId.trim()) errors.emailId = errorMessages.PROVIDE_EMAIL;
    else if (!common.validateEmail(emailId)) errors.emailId = errorMessages.PROVIDE_VALID_EMAIL;
    else errors.emailId = true;

    if (errors.emailId !== true) isFormValid = false;
    else isFormValid = true;
    
    setErrors(errors);setIsFormValid(isFormValid)
    return isFormValid;
  }

  //******************* Forgot Password ***************
  const forgotPassword = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        setLoading(true)
        let  body={ email :emailId};
        localStorage.setItem('emailIdForVerify', emailId);
        const response = await props.callApi(API.FORGOT_PASSWORD,body, 'post');
        console.log("response", response);
        setLoading(false);
        if (response.settings.status === 1) {
          // common.showSuccessMessage(response.settings.message)
          toast.success(response.settings.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          props.history.push('/emailVerify');
        } else {
          setLoading(false);
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        } 
      }
    } catch (error) {
      setLoading(false)
      throw (error)
    }
  }

  return (
    <div className="App">
    <Theme/>
      <div className="login-wrapper" style={{background:"url(../assets/images/login-bg.jpg) no-repeat center center"}}>
      <div className="login-body">
      <div className="login-container row m-0">
				<div className="col-xl-8 col-11">
					<div className="card bg-login-card">
						<div className="row m-0">
							<div className="col-md-6 col-12 pl-0 pr-0">
								<div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
									<div className="card-header">
										<div className="card-title text-center mb-4">
                      <h2 className="mb-2 mt-2">Super Admin</h2>
											<h4 className="mb-2 mt-2">Forgot Password?</h4>
										</div>
										<div className="text-center"><p className="common-small-text"><small>Enter the email you used when you joined and we will send you temporary password</small></p></div>
									</div>
									<div className="card-content">
										<div className="card-body">
											<form onSubmit={(evt)=>forgotPassword(evt)}>
												<div className="form-group mb-4">
													{/* <label for="EmailAddress">Email<sup className="text-danger">*</sup></label> */}
													<input type="email" 
													className={errors.emailId ? "form-control input-error" : "form-control"} 
													id="EmailAddress" 
													aria-describedby="emailHelp" 
                          placeholder="Enter Email "
                          value={emailId}
                          onChange={(e) => {setEmailId(e.target.value) ;errors=Object.assign(errors, { emailId: "" });setErrors(errors)}}
												  />
                          <span className="error-msg" style={{color: "red"}}>{errors.emailId}</span>
												</div>
                        <Button 
												type="button" 
												className="btn btn-primary glow position-relative btn-block" 
												onClick={()=> {props.history.push('/'); localStorage.removeItem('emailIdForVerify');}}>
												Cancel
												</Button>
												<Button 
												type="button" 
												className="btn btn-primary glow position-relative btn-block" 
												onClick={(evt)=>forgotPassword(evt,'password')}>
												{
                          loading ?"Loading":"Submit"
                        }
												</Button>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
								<div className="card-content">
										<img className="img-fluid" src="../assets/images/register.png" alt="sign up" width="300"></img>
								</div>
							</div>
						</div>
					</div>
				</div>
    </div>
    </div>
      </div>
    </div>
  );
}

export default connect(null, { callApi })(ForgotPassword)