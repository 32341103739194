import React, { useEffect, useState, useMemo } from 'react';
import Home from '../Home';
import { connect } from 'react-redux';
import { callApi } from '../../actions/commonFunction'; // COMMON FUNCTIONS
import API from '../../actions/API';
import { Link } from "react-router-dom"
import { Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import {useSortng} from '../CommonFeature/Sorting';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDays } from 'date-fns';

function ListTasks(props) { 

  const [page, setPage] = useState(1);
  const [sortData,sort, onSort] = useSortng();
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(1);
  const [condition, setCondition] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [deptOptions, setDeptOptions] = useState([]);
  const [userListing, setUserListing] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [department, setDepartment] = useState('');
  const [taskListing, setTaskListing] = useState([]);
  const [dateSelector, setDateSelector] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

    useEffect(() => {
      getAllTasks();
    }, []);
    
    
    //**************** GET ALL TASKS ****************** */
    const getAllTasks = async() => {
    
      let body = {
        page, 
        pagesize
      };

      try {
      setLoading(true);
      const response = await props.callApi(API.LIST_TASK, body, 'post', null, true);
      console.log("response", response); 
        if (response.settings.status === 1) {
          setTaskListing(response.data.listing);
        } else {
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      } catch(error) {
        setLoading(false);
        throw (error)
      }
    }

    const renderFilter = () => {
      console.log("clicked filter");
      return (
        openFilter ? 
        <div className="float-right advance-filter">
          <div className="mb-2">
          <label htmlFor="updatedDate">By Task Last Updated Date</label>
            <div className="each-detail">
              <DateRange
                editableDateInputs={true}
                onChange={item => setDateSelector([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateSelector}
              />
            </div>
          </div> 
          <div className="mb-2">
          <label htmlFor="CreatedDate">By Task Creation Date</label>
            <div className="each-detail">
              <DateRange
                editableDateInputs={true}
                onChange={item => setDateSelector([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateSelector}
              />
            </div>
          </div> 
          <div className="mb-2">
          <label htmlFor="mentor(createdBy)">By Mentor Name(creator)</label>
            <div className="each-detail">
            <Select className="selectpicker w-100"
              isMulti
              id="department"
              name="department"
              value={department}
              options={deptOptions}
              // onChange={(e) => {setDepartment(e); errors = Object.assign(errors, {department: ''}); setErrors(errors);}}
            >
            </Select>
            </div>
          </div>
          <div className="mb-2">
          <label htmlFor="mentor(createdBy)">By Mentor Name(Completed By)</label>
            <div className="each-detail">
               <input 
                  type="text" 
                  className="form-control" 
                  id="mentor"
                  name="mentor" 
                  placeholder="Type in mentor name.." 
                />
            </div> 
          </div> 
          <div className="mb-2">
          <label htmlFor="mentor(createdBy)">By Associated Mentor Name</label>
            <div className="each-detail">
               <input 
                  type="text"
                  className="form-control" 
                  id="mentor"
                  name="mentor" 
                  placeholder="Type in mentor name.." 
                />
            </div>
          </div>
          <div className="mb-2">
          <label htmlFor="mentor(createdBy)">By Student Name</label>
            <div className="each-detail">
               <input 
                  type="text" 
                  className="form-control" 
                  id="mentor"
                  name="mentor" 
                  placeholder="Type in student name.." 
                />
            </div>
          </div>  
          <div className="mb-2">
          <label htmlFor="mentor(createdBy)">By Tutor Name</label>
            <div className="each-detail">
               <input 
                  type="text" 
                  className="form-control" 
                  id="mentor"
                  name="mentor" 
                  placeholder="Type in tutor name.." 
                />
            </div>
          </div> 
        </div> :null
      )
    }

    //************** DOWNLOAD CSV HANDLER ****************/
    const exportCSVHandler = async() => {
      let body = {
        key:  "taskListing",
        fields: ["userInfo", "department","subject", "worktype", "taskprice","createdBy", "lastUpdatedOn", "status"]
      }
    
      try {
        const response = await props.callApi(API.EXPORT_CSV, body, 'POST', null, true);
        console.log("export CSV", response);
        window.open(response.data, "_blank");
        if(response.settings.status === 1) {
          toast.success('Tasks Lists downloaded successfully', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.settings.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        throw error;
      }
    }

    //************** SEARCH FIELD HANDLER *************** */
    const searchField = async() => {
        let body = {
          page,
          pagesize,
          searchText: searchText && searchText.length > 1 ? searchText : ""
        };
    
        try {
          const response = await props.callApi(API.LIST_TASK, body, 'POST', null, true);
          let {data, settings} = response;
          if (settings.status === 1) {
            setTaskListing(data.listing);
            setTotal(data.total);
          } else {
            toast.error(settings.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          throw(error);
        }
    }
  
    //************** CLEAR SEARCH BOX *************** */
    const clearSearchBox = () => {
        setSearchText("");
        getAllTasks();
    }

    return (
        <Home>
        <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">Manage Task</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  {/* nav navbar-nav<li className="breadcrumb-item">Home</li> */}
                  <li className="breadcrumb-item active" aria-current="page">List Tasks</li>
                </ol>
              </nav>
              <div className="content-area position-relative">
                <div className="grid">
                <Button  id="exportCSV" type="button" className="btn glow-primary btn-primary ml-2 float-right" onClick={exportCSVHandler}>
                  <i className="bx bx-user-plus d-lg-none" />
                  <span className="d-none d-sm-none d-lg-inline-block">Export CSV</span>
                </Button>
                {/* <Button  
                  id="advancedFilter" 
                  type="button" 
                  className={openFilter ? "btn glow-primary btn-primary ml-2 float-right open" : "btn glow-primary btn-primary ml-2 float-right"} 
                >
                  <i className="bx bx-user-plus d-lg-none" />
                  <span className="d-none d-sm-none d-lg-inline-block">Advanced Search Option</span>
                </Button> */}
                {/* <div 
                  id="container1" 
                  className="btn glow-primary btn-primary ml-2 float-right open"
                  onClick = {() => setOpenFilter(!openFilter)}
                >
                  Advanced search 
                </div> */}
                <div>
                  {renderFilter()}
                </div>
                  <div className="grid-content">
                    {/* filterbar buttons */}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="search position-relative has-icon-left">
                            <input type="search" className="form-control text-capitalize"  placeholder="search"
                            value = {searchText} onChange={(e) => {setSearchText(e.target.value); searchField();}}
                            />
                              {
                                searchText && searchText.length > 0 ?
                                  <div className="clear">
                                    <span onClick={clearSearchBox}><em className="bx bx-x"></em></span>
                                  </div> : null
                              }
                            <div className="form-control-position">
                                <em className="bx bx-search"></em>
                            </div>
                        </div>
                    </div>
                    <table className="table row-border nowrap common-datatable" id="content-blog-listing">
                      <thead>
                        <tr>
                          <th className="all"><b>User Info</b></th>
                          <th className="all"><b>Department</b></th> 
                          <th className="all"><b>Subject</b></th>
                          <th className="all"><b>Work Type</b></th>
                          <th className="all"><b>Task Price</b></th>
                          <th className="all"><b>Created By</b></th> 
                          <th className="all"><b>Last Updated On</b></th> 
                          <th className="all"><b>Status</b></th> 
                        </tr>
                      </thead>
                      <tbody>
                         {
                            loading ? 
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="7"><Loader/>
                              </td>
                            </tr>
                            :
                            (taskListing && taskListing.length !== 0) ? 
                            taskListing && Array.isArray(taskListing) && taskListing.map((item, key) =>{

                              return (
                                <tr key={key}>
                                    <td><Link onClick={() => props.history.push(`/listTasks/viewTask/${item._id}`)}>{`${item.student.firstName} ${item.student.lastName}`}</Link></td>
                                    <td>{item.department.name}</td>
                                    <td>{item.subject.name}</td>
                                    <td>{item.worktype.format}</td>
                                    <td>{`${item.amount.currency}${item.amount.price}`}</td>
                                    <td>{`${item.createdBy.firstName} ${item.createdBy.lastName}`}</td>
                                    <td>{moment(item.updatedAt).format('L')}<br/>{moment(item.updatedAt).format('LT')}</td>
                                    <td>{item.taskStatus}</td>
                                </tr>
                              )
                            }) : 
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="7">
                              <h6 className="text-center text-danger not-found-txt" colSpan="7">No Records found!</h6>
                              </td>
                            </tr>
                          }
                      </tbody>
                    </table>
                    {/* <div className="mt-3">
                      <Pagination  
                      pageSize={pagesize} 
                      current={page} 
                      total={total} 
                      // showPrevNextJumpers={false} 
                      onChange={CategoryPagination}
                      />
                    </div> */}
                    {/* datatable ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Home>
    )
}

export default connect(null, { callApi })(ListTasks)