import React, { useState, useEffect, useRef } from 'react';
import Home from '../Home';
import { connect } from 'react-redux'
import 'antd/dist/antd.css'
import { Select, Spin, Button } from 'antd'
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import { callApi, editUser } from '../../actions/commonFunction';
import swal from 'sweetalert'
import _, { filter } from 'lodash'
import { Link, withRouter } from "react-router-dom";
import { IMG_URL } from '../../config/configs'
import $, { each, type } from 'jquery'
import Loader from '../../components/Loader';
import errorMessages from '../../actions/errorMessages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSortng } from '../CommonFeature/Sorting';
import API from "../../actions/API";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
$.DataTable = require('datatables.net');
require('datatables.net-responsive');

var { Option } = Select;
function UsersList(props) {
  const [columnSettingsArr, setColumnSettingsArr] = useState([]);
  const [columnSettings, setColumnSettings] = useState({ photo: true, firstName: true, lastName: true, emailId: true, mobile: true, emailVerificationStatus: true, status: true });
  const [usersList, setUsersList] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [length, setLength] = useState(1);
  const [loading, setLoading] = useState(true);
  const [multipleDelete, setMultipleDelete] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [visible, setVisible] = useState(false);
  let [filterName, setFilterName] = useState('');
  const [getFilterValues, setGetFilterValues] = useState([]);
  const [addedFilter, setaddedFilter] = useState([]);
  const [searchFilter, setSearchAddedfilter] = useState([]);




  const [UserAccess, setUserAccess] = useState({});
  const [bgColor, setBgColor] = useState('');
  const [tempName, setTempName] = useState('');
  const [savedTemp, setSavedTemp] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);
  let [errors, setErrors] = useState({});
  const [latestColumnsArr, setLatestColumnsArr] = useState([]);
  const [filterObj, setFilterObj] = useState([{ key: "", type: "", input: "", value: { startDate: '', endDate: '' }, condition: "$and" }])
  const [condition, setCondition] = useState('$and');
  const [templateSettings, setTemplateSettings] = useState([{
    "key": "photo", "status": false
  },
  { "key": "firstName", "status": false },
  { "key": "lastName", "status": false },
  { "key": "emailId", "status": false },
  { "key": "mobile", "status": false },
  { "key": "emailVerificationStatus", "status": false },
  { "key": "status", "status": false },
  { "key": "dob", "status": false },
  { "key": "website", "status": false },
  { "key": "gender", "status": false },
  { "key": "address", "status": false },
  ])
  const [selectedOption, setSelectedOption] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [item, setItem] = useState('');
  const [val, setVal] = useState(false);
  const [sortData, sort, onSort] = useSortng();
  const [filterColor, SetFiltercolor] = useState("green");
  const [templateColor, SetTemplatecolor] = useState("green");

  const [allChecked, setAllChecked] = useState(false);
  const [loadingcheckbox, setLoadingcheckbox] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const [filterpopup, openFilterpopup] = useState(false);
  const [downloadpopup, openDownloadpopup] = useState(false);
  const [filterID, editFilter] = useState("");
  const [templateID, editTemplate] = useState("");
  const [togglestatus, setTogglestatus] = useState(false);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);

  //filter outside click
  function useOuterClick(callback) {
    const settingsRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          settingsRef.current &&
          callbackRef.current &&
          !settingsRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return settingsRef;
  }



  //download outside click
  function useOuterClick(callback) {
    const downloadRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          downloadRef.current &&
          callbackRef.current &&
          !downloadRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return downloadRef;
  }


  // const downloadRef = useOuterClick(e => {
  //   openDownloadpopup(false)
  // });




  useEffect(() => {
    (async () => {
      if (props.UserAccess && !_.isEmpty(props.UserAccess) && await props.UserAccess.viewList === false) {
        props.history.push('/dashboard')
      } else {
        await setUserAccess(props.UserAccess ? props.UserAccess : {})
      }
      await getAllUsers();
      let settings = columnSettings;
      _.map(settings, (val, column) => {
        let columnSetting = _.find(columnSettingsArr, (setting) => {
          return _.isEqual(setting.key, column);
        });
        settings[column] = columnSetting ? columnSetting.status : val;
        setColumnSettings({ ...settings })
      });
      $('.filterlink').click(function () {
        $('#itemlist').hide();
        $('#filterlist').stop().slideToggle();
      });
      $('.listlink').click(function () {
        $('#filterlist').hide();
        $('#itemlist').stop().slideToggle();
      });
    })();
  }, [page, pagesize, sort]);

  //********************************************************//
  const getAllUsers = async (filterObj) => {
    var body = { page, pagesize, sort, columnKey: "userListing", filter: filterObj ? filterObj : "", }
    const response = await props.callApi(API.USERS_LIST, body, 'post', null, true);
    if (response.status === 1) {
      var result = response.data.listing.map(function (el) {
        var o = Object.assign({}, el);
        o.isChecked = false;
        return o;
      })
      setUsersList(result);
      setColumnSettingsArr(response.data.columnSettings);
      setSavedTemp(response.data.templateSettings)
      setLatestColumnsArr(response.data.latestColumns);
      setGetFilterValues(response.data.filterSettings);
      setTotal(response.total); setLength(response.data.listing.length);
      setLoading(false);
    }
  }
  //*********** PAGINATION *********
  const paginationChange = (page, pagesize) => {
    setPage(page);
    setPagesize(pagesize);
  }

  //*********** User Status Change ************************//
  const userStatusChange = async (status, userId) => {
    var body = { userIds: [userId], status };
    const response = await props.callApi(API.USERS_STATUS, body, 'post', null, true);
    if (response.status === 1) {
      // common.showSuccessMessage("Status Updated Successfully")
      toast.success('Status updated successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }
  //************************************************************************************//
  //*********** Delete User *************************************************************//
  const deleteUser = async (uid) => {
    let data = usersList.filter((rec) => rec.isChecked == true)
    let result = data.map(({ _id }) => _id)
    if (result.length > 0) {
      swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async willDelete => {
          if (willDelete) {
            if (result.length > 0) {
              var body = { userIds: result }
            }
            else {
              body = { userIds: result }
            }
            const response = await props.callApi(API.DELETE_USERS, body, 'post', null, true);
            if (response.status === 1) {
              // common.showSuccessMessage("Deleted Successfully")
              toast.success('Deleted successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              setPage(1); setPagesize(10);
              getAllUsers()
            }
          }
        });
    }

  }





  //************************************************************************************//
  //**************************** CHECKBOXES ***************************************************//



  //*****************************************************************************//
  //********************** BULK ACTIONS *****************************************//
  const applyBulkActions = async () => {
    let data = usersList.filter((rec) => rec.isChecked == true)
    let delArr = data.map(({ _id }) => _id)

    if (delArr.length > 0) {
      delArr = _.compact(delArr)
      if (!togglestatus) {
        var body = { userIds: delArr, status: true }
      } else {
        body = { userIds: delArr, status: false }
      }
      const response = await props.callApi(API.USERS_STATUS, body, 'post', null, true);
      if (response.status === 1) {
        setSelectedOption('Select here')
        setSelectAll(false);
        setVal(false);
        toast.success('Updated successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        getAllUsers()
      }
    } else {
      toast.error('Please select Records to change status', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }



  const getsavedfilter = async (value) => {
    setaddedFilter(value)
    setSearchAddedfilter([])
    value.filter && value.filter.forEach(function (obj) {
      if (obj.type == "date") {
      } else {

        obj = obj
      }
    });
    var body = { page: 1, pagesize: 10, filter: value.filter, sort, columnKey: "userListing" }
    const response = await props.callApi('/user/userListing', body, 'post', null, true);
    if (response.status == 1) {
      setUsersList(response.data.listing); setColumnSettingsArr(response.data.columnSettings); setLatestColumnsArr(response.data.latestColumns);
      setGetFilterValues(response.data.filterSettings); setTotal(response.total); setLength(response.data.listing.length);
      openFilterpopup(false)
      setLoading(false);
    }
  }


  //***********************************************************************************//
  //********************** DOWNLOAD CSV ************************************************//
  const DownloadCSV = async () => {
    var body = {
      "columns": ["firstName", "lastName", "username", "emailId", "mobile"]
    }
    if (filterArray.length) {
      body["filter"] = filterArray
    }
    const response = await props.callApi('/user/downloadCsv', body, 'post', null, true);
    if (response.status === 1) {
      window.open(response.data, '_blank');
      toast.success('Downloaded Excel file successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

    }
  }

  //***********************************************************************************//

  //********************** DOWNLOAD EXCEL ************************************************//


  //*******************************************************************************************//
  //*********** COLUMN HIDE SHOW ****************************************************************//
  const columnSettingsSave = async () => {
    var columnArr = []
    latestColumnsArr.map((arr, key) => {
      var array = { key: arr.colFieldname, status: arr.isVisible }
      columnArr.push(array)
    })
    var body = { columns: columnArr, key: "userListing", latestColumns: latestColumnsArr };

    const response = await props.callApi(API.SAVE_COLUMN_SETTINGS, body, 'post', null, true);
    if (response.status === 1) {
      getAllUsers()
      toast.success('Columns update successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }

  //******************** APPLY FILTER  ************************************************//
  const ApplyFilter = async (tab) => {
    setaddedFilter([])
    if (validateFiltersearch(tab)) {
      filterObj.forEach(function (obj) {
        if (obj.type != "date") {
          delete obj.value;
          obj.value = obj.input
        } else {
          delete obj.input
        }
      });
      var result = filterObj.map(function (obj) {
        return { key: obj.key, value: obj.value, type: obj.type, condition: obj.condition };
      });
      setSearchAddedfilter(filterObj)
      await getAllUsers(result)
      openFilterpopup(false)
      setFilterName("")
    }
  }














  //******************** APPLY FILTER  ************************************************//
  const filterSearch = async (tab, i) => {
    if (searchFilter.length <= 1) {
      resetFilter()
    } else {
      var array = [...filterObj]
      array.splice(i, 1);
      setFilterObj(array)
      var array1 = [...errorsLicence]
      array1.splice(i, 1);
      setErrorsLicence(array1)
      array.forEach(function (obj) {
        if (obj.type != "date") {
          delete obj.value;
          obj.value = obj.input
        } else {
          delete obj.input
        }
      });
      var result = array.map(function (obj) {
        return { key: obj.key, value: obj.value, type: obj.type, condition: obj.condition };
      });
      setSearchAddedfilter(array)
      await getAllUsers(result)
      openFilterpopup(false);
      setFilterName("")
    }
  }



  //************************************************************************************//
  //******************** RESET FILTER  ************************************************//
  const resetFilter = async () => {
    setCondition('$and')
    SetFiltercolor("green")
    openFilterpopup(false)
    var body = { page, pagesize, columnKey: "userListing" }
    const response = await props.callApi(API.USERS_LIST, body, 'post', null, true);
    if (response.status === 1) {
      setUsersList(response.data.listing); setGetFilterValues(response.data.filterSettings);
      setColumnSettingsArr(response.data.columnSettings); setTotal(response.total);
      setLength(response.data.listing.length);
      setLoading(false);
      setFilterObj([{ key: "", type: "", input: "", value: { startDate: "", endDate: "" }, condition: "$and" }])
    }
    setaddedFilter([]);
    setFilterName("")
    setSearchAddedfilter([])
  }

  //******************************* SAVE FILTER ******************************************//
  const SaveFilter = async (e, tab) => {
    if (validateFilter(tab)) {
      openFilterpopup(false)
      filterObj.forEach(function (obj) {
        if (obj.type == "date") {
          delete obj.input
        } else {
          obj.value = obj.input
          delete obj.input
        }
      });

      var body = {
        "description": filterName,
        condition,
        filter: filterObj,
        "key": "userListing",
        "color": filterColor,
      }

      if (filterID != "") {
        body.filterId = filterID
      }
      const response = await props.callApi(API.SAVED_FILTER, body, 'post', null, true);
      if (response.status == 1) {
        toast.success('Filter saved successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setSearchAddedfilter([])
        filterObj && filterObj.forEach(function (obj) {
          if (obj.type == "date") {
          } else {
            obj = obj
          }
        });
        openFilterpopup(false)
        var body = { page: 1, pagesize: 10, filter: filterObj, sort, columnKey: "userListing" }
        const response = await props.callApi('/user/userListing', body, 'post', null, true);
        if (response.status == 1) {
          setUsersList(response.data.listing); setColumnSettingsArr(response.data.columnSettings); setLatestColumnsArr(response.data.latestColumns);
          setGetFilterValues(response.data.filterSettings); setTotal(response.total); setLength(response.data.listing.length);
          setLoading(false);
        }
        setFilterObj([{ key: "", type: "", input: "", value: { startDate: "", endDate: "" }, condition: "$and" }])
        setFilterName("")
        editFilter("")
        let obj = {
          description: filterName
        }
        setaddedFilter(obj)
      }
      else {
        toast.error('Please send proper data', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  }


  //************************************************************************************//
  //******************************* DELETE FILTER ******************************************//
  const DeleteFilter = async (id) => {
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          const response = await props.callApi(API.DELETE_FILTER + id, '', 'delete', null, true);
          if (response.status == 1) {
            toast.success('Details are deleted successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            getAllUsers()
            setFilterObj([{ key: "", type: "", input: "", value: { startDate: '', endDate: '' }, condition: "$and" }])
          }
        }

      });
  }

  //******************************* DELETE Temp ******************************************//
  const DeleteTemp = async (id) => {
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async willDelete => {
        if (willDelete) {
          const response = await props.callApi(API.DELETE_TEMP + id, '', 'delete', null, true)
          if (response.status == 1) {
            toast.success('Details are deleted successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            getAllUsers();
            setTemplateSettings([{
              "key": "photo", "status": false
            },
            { "key": "firstName", "status": false },
            { "key": "lastName", "status": false },
            { "key": "emailId", "status": false },
            { "key": "mobile", "status": false },
            { "key": "emailVerificationStatus", "status": false },
            { "key": "status", "status": false },
            { "key": "dob", "status": false },
            { "key": "website", "status": false },
            { "key": "gender", "status": false },
            { "key": "address", "status": false },
            { "key": "address", "status": false }])
          }
        }
      });

  }

  //************************************* */
  const saveTemplets = async () => {
    if (validateForm()) {
      var body = { key: "userListing", "description": tempName, "columns": templateSettings, "color": templateColor }
      const response = await props.callApi(API.SAVED_TEMP_Settings, body, 'post', null, true);
      if (response.status == 1) {
        toast.success('Filter saved Successfully', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setBgColor("");
        setTempName("");
        setTemplateSettings([{
          "key": "photo", "status": false
        },
        { "key": "firstName", "status": false },
        { "key": "lastName", "status": false },
        { "key": "emailId", "status": false },
        { "key": "mobile", "status": false },
        { "key": "emailVerificationStatus", "status": false },
        { "key": "status", "status": false },
        { "key": "dob", "status": false },
        { "key": "website", "status": false },
        { "key": "gender", "status": false },
        { "key": "address", "status": false },
        { "key": "address", "status": false }])
        getAllUsers()
      }
      else {
        toast.success('Please send proper data', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }

  }


  //************************ edit saved Templates ************* */
  const editSavedTemplates = (value) => {
    let tempArray = [];
    value && value.columns.map((each) => {
      return tempArray.push({ "key": each.key, "status": each.status })
    })
    setTemplateSettings(tempArray);
    setTempName(value.description)
    SetTemplatecolor(value.color)
  }

  //************************* Download User Files ************ */
  const downloadUserFiles = async (tab) => {
    var array = [];
    templateSettings.map((o, key) => {
      if (o.status === true) {
        array.push(o.key)
      }
    })
    var body = {
      "filteredFields": array,
      "type": tab
    }
    const response = await props.callApi(API.DOWNLOAD_FILE, body, 'post', null, true);
    if (response.status === 1) {
      if (tab === 'csv') {
        var data1 = 'http://commonapi.node.indianic.com/public' + '/csv/' + response.data.filePathAndName;
      } else if (tab === 'excel') {
        var data1 = 'http://commonapi.node.indianic.com/public' + '/excel/' + response.data.filePathAndName
      } else if (tab === 'pdf') {
        var data1 = 'http://commonapi.node.indianic.com/public' + '/pdf/' + response.data.filePathAndName
      } else if (tab === 'print') {
        var data1 = 'http://commonapi.node.indianic.com/public' + '/print/' + response.data.filePathAndName
      }
      setTemplateSettings([{
        "key": "photo", "status": false
      },
      { "key": "firstName", "status": false },
      { "key": "lastName", "status": false },
      { "key": "emailId", "status": false },
      { "key": "mobile", "status": false },
      { "key": "emailVerificationStatus", "status": false },
      { "key": "status", "status": false },
      { "key": "dob", "status": false },
      { "key": "website", "status": false },
      { "key": "gender", "status": false },
      { "key": "address", "status": false },
      { "key": "address", "status": false }])
      window.open(data1, '_blank');
      toast.success('Downloaded file successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }



  //************************ Validate form ************************ 
  const validateForm = (type) => {
    let errors = { tempName, filterName, }
    let isFormValid = true;
    if (type === "filter") {
      if (!filterName.trim()) errors.filterName = errorMessages.PROVIDE_NAME;
      else if (filterName.length >= 10) errors.filterName = "*Name shouldn't be more than than 10 characters."
      else errors.filterName = '';

      if (errors.filterName !== '') isFormValid = false;
      else isFormValid = true;
      setErrors(errors); setIsFormValid(isFormValid)
      return isFormValid
    }
    else {
      if (!tempName.trim()) errors.tempName = errorMessages.PROVIDE_NAME;
      else if (tempName.length >= 10) errors.tempName = "*Name shouldn't be more than than 10 characters."
      else errors.tempName = '';


      let validCheckbox = templateSettings.filter(vendor => vendor['status'] === true)
      if (validCheckbox.length <= 0) errors.checkbox = "Please select checkbox";
      else errors.checkbox = '';

      if (errors.tempName !== '' || errors.checkbox !== '') isFormValid = false;
      else isFormValid = true;
      setErrors(errors);
      setIsFormValid(isFormValid)
      return isFormValid
    }
  }



  //***********************

  const validateFilter = () => {
    let isFormValid1 = true;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/;
    if (!filterName.trim()) errors.filterName = errorMessages.PROVIDE_NAME;
    else if (filterName.length >= 10) errors.filterName = "*Name shouldn't be more than than 10 characters."
    else errors.filterName = '';
    if (errors.filterName !== '') isFormValid1 = false;
    else isFormValid1 = true;

    setErrors(errors); setIsFormValid(isFormValid1)
    filterObj.map((each, key) => {
      //user key
      if (_.isEmpty(each.key) && errorsLicence[key]) {
        errorsLicence[key].key = "Enter field";
        isFormValid1 = false;
      } else if (errorsLicence[key]) delete errorsLicence[key].key;

      //type
      if (_.isEmpty(each.type) && errorsLicence[key]) {
        errorsLicence[key].type = "Enter  date or user";
        isFormValid1 = false;
      } else if (errorsLicence[key]) delete errorsLicence[key].type;


      if ((each.type == "contains") || (each.type == "greaterThan") || (each.type == "lessThan")) {
        if (each.input == "" && errorsLicence[key] && each.type != "date") {
          errorsLicence[key].input = "Enter  input";
          isFormValid1 = false;
        }
        else if (each.key == "emailId") {
          if (!mailFormat.test(each.input)) {
            errorsLicence[key].input = "Please enter vaild email";
            isFormValid1 = false;
          }
        }
        else {
          delete errorsLicence[key].input;
        }
      }
      if (each.type == "date") {
        if (each.value && each.value.startDate == "" && errorsLicence[key] && each.type == "date") {
          errorsLicence[key].value.startDate = "Enter start date";
          isFormValid1 = false;
        } else if (errorsLicence[key]) delete errorsLicence[key].value.startDate;

        if (each.value && each.value.endDate == "" && errorsLicence[key] && each.type == "date") {
          errorsLicence[key].value.endDate = "Enter end date";
          isFormValid1 = false;
        }
        else if (((Date.parse(each.value && each.value.endDate)) < Date.parse(each.value && each.value.startDate))) {
          errorsLicence[key].value.endDate = errorMessages.START_END_DATE_ERROR;
          isFormValid1 = false;
        }
        else {
          delete errorsLicence[key].value.endDate;
        }

      }
      return setErrorsLicence([...errorsLicence]);
    });

    return isFormValid1;
  };


  const toggleStatus = () => {
    setTogglestatus(!togglestatus);
    applyBulkActions()
  }

  //*********************** validateFiltersearch **********************

  const validateFiltersearch = () => {
    let isFormValid1 = true;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/;
    setErrors(errors); setIsFormValid(isFormValid1)
    filterObj.map((each, key) => {
      //user key
      if (_.isEmpty(each.key) && errorsLicence[key]) {
        errorsLicence[key].key = "Enter  field";
        isFormValid1 = false;
      } else if (errorsLicence[key]) delete errorsLicence[key].key;

      //type
      if (_.isEmpty(each.type) && errorsLicence[key]) {
        errorsLicence[key].type = "Enter  date or user";
        isFormValid1 = false;
      } else if (errorsLicence[key]) delete errorsLicence[key].type;


      if ((each.type == "contains") || (each.type == "greaterThan") || (each.type == "lessThan")) {
        if (each.input == "" && errorsLicence[key] && each.type != "date") {
          errorsLicence[key].input = "Enter Input";
          isFormValid1 = false;
        }
        else if (each.key == "emailId") {
          if (!mailFormat.test(each.input)) {
            errorsLicence[key].input = "Please enter vaild email";
            isFormValid1 = false;
          }
        }
        else {
          delete errorsLicence[key].input;
        }
      }

      if (each.type == "date") {
        if (each.value && each.value.startDate == "" && errorsLicence[key] && each.type == "date") {
          errorsLicence[key].value.startDate = "Enter start date";
          isFormValid1 = false;
        } else if (errorsLicence[key]) delete errorsLicence[key].value.startDate;

        if (each.value && each.value.endDate == "" && errorsLicence[key] && each.type == "date") {
          errorsLicence[key].value.endDate = "Enter end date";
          isFormValid1 = false;
        }
        else if (((Date.parse(each.value && each.value.endDate)) < Date.parse(each.value && each.value.startDate))) {
          errorsLicence[key].value.endDate = errorMessages.START_END_DATE_ERROR;
          isFormValid1 = false;
        }
        else {
          delete errorsLicence[key].value.endDate;
        }

      }
      return setErrorsLicence([...errorsLicence]);
    });

    return isFormValid1;
  };


  const searchField = async (keyword) => {
    var body = { searchText: keyword, page, pagesize, sort, columnKey: "userListing", }
    const response = await props.callApi(API.USERS_LIST, body, 'post', null, true);
    if (response.status === 1) {
      if (response.status === 1) {
        setUsersList(response.data.listing);
        setColumnSettingsArr(response.data.columnSettings);
        setSavedTemp(response.data.templateSettings)
        setLatestColumnsArr(response.data.latestColumns);
        setGetFilterValues(response.data.filterSettings);
        setTotal(response.total); setLength(response.data.listing.length);
        setLoading(false);
      }

    }
  }


  //***********************Add new Row *************** */
  const addNewRow = (e) => {
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and"
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and"
      },
    ]);
  }
  //**************** Delete Row  ****************** */
  const removeRow = (index) => {
    var array = [...filterObj]
    array.splice(index, 1);
    setFilterObj(array)
    var array = [...errorsLicence]
    array.splice(index, 1);
    setErrorsLicence(array)
    setTimeout(function () { setLoadingFilter(true); }, 100);

  }


  const EditSaveFilteredRecords = async (value) => {
    value.filter && value.filter.forEach(function (obj) {
      if (obj.type == "date") {
        delete obj.input
      } else {
        obj.input = obj.value
        delete obj.value
      }
    });
    editFilter(value._id)
    setFilterObj(value.filter)
    SetFiltercolor(value.color)
    setFilterName(value.description)
  }

  const handleChangeColor = (value) => {
    SetFiltercolor(value)
  }

  const handleChangeTempaletColor = (value) => {
    SetTemplatecolor(value)
  }



  const handleChangeCheckbox2 = (e) => {
    let list = usersList;
    var checkedALL = allChecked;
    if (e.target.value === "checkAll") {
      list.forEach(item => {
        item.isChecked = e.target.checked;
        checkedALL = e.target.checked
      });
    }
    else {
      list.find(item => item.firstName === e.target.name).isChecked = e.target.checked;
      let magenicVendors = list.filter(vendor => vendor['isChecked'] === false)
      if (magenicVendors.length <= 0) {
        checkedALL = true
      } else {
        checkedALL = false
      }
    }
    setUsersList(list);
    setAllChecked(checkedALL)
    setTimeout(function () { setLoadingcheckbox(!loadingcheckbox); }, 100);
  }



  //**************** render Filter   ****************** */
  const renderFilter = () => {
    return (
      <div class="custom-dropdown-menu w-100 dropdown-icon">
        <div class="container-fluid">
          <div className="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                  <div className="form-repeat">
                    {filterObj && filterObj.length > 0 && filterObj.map((o, i) => {
                      return (
                        <form key={i} class="form repeater-default" data-limit="5">
                          <div className="field-form">
                            <div className="and-or-data text-center mt-1 mb-2">
                              {
                                i >= 1 ?

                                  <Button className={o.condition == "$and" ? " btn btn-outline-primary mr-3 condition-text" : "btn btn-outline-primary mr-3"} onClick={() => { o.condition = "$and"; setFilterObj([...filterObj]); }}
                                  >AND</Button> : null
                              }
                              {
                                i >= 1 ?
                                  <Button className={o.condition == "$or" ? " btn btn-outline-primary condition-text" : "btn btn-outline-primary"} onClick={() => { o.condition = "$or"; setFilterObj([...filterObj]); }}
                                  >OR</Button> : null
                              }

                            </div>
                            <div>
                              <div class="target-form">
                                <div class="target-details">
                                  <div class="row align-items-end">
                                    <div class="form-group col-md-6 col-lg-3 mb-2">
                                      <label className="text-left">Select Field</label>

                                      <Select
                                        showSearch
                                        placeholder="Enter Field"
                                        optionFilterProp="children"
                                        className="w-100 custom-input filter-select"
                                        value={o.key}
                                        onChange={(value) => {
                                          o.key = value; setFilterObj([...filterObj]);
                                          if (errorsLicence[i]) delete errorsLicence[i].key;
                                        }}
                                      >
                                        <Option value="firstName">First Name</Option>
                                        <Option value="lastName">Last Name </Option>
                                        <Option value="emailId">Email</Option>
                                        <Option value="mobile">Mobile</Option>
                                      </Select>

                                      <span className="text-danger error-msg">
                                        {errorsLicence[i] &&
                                          errorsLicence[i].key}
                                      </span>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-3 mb-2">
                                      <label className="text-left">Select Content</label>
                                      <Select
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type == "date") {
                                            delete o.value
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            }
                                          }
                                          setFilterObj([...filterObj]);
                                          if (errorsLicence[i]) delete errorsLicence[i].type;

                                        }}
                                      >
                                        <Option value="contains">Content</Option>
                                        <Option value="greaterThan"> {`>=`} </Option>
                                        <Option value="lessThan">{`<=`}</Option>
                                        <Option value="date">Date</Option>

                                      </Select>
                                      <span className="text-danger error-msg">
                                        {errorsLicence[i] &&
                                          errorsLicence[i].type}
                                      </span>
                                    </div>

                                    <div class="col-md-6 col-lg-6 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control" >
                                        {
                                          o.type == "date" ?
                                            <div className="row">
                                              <div className="col-md-6">
                                                <label className="text-left">Select Start date</label>
                                                <DatePicker
                                                  selected={o.value.startDate ? new Date(o.value.startDate) : ''}
                                                  placeholderText="Select Start Date"
                                                  dateFormat="MMM d yyyy"
                                                  className="form-control w-100"
                                                  onChange={(evt) => {
                                                    o.value.startDate = evt
                                                    setFilterObj([...filterObj]);
                                                    if (errorsLicence[i]) delete errorsLicence[i].value.startDate;
                                                  }}
                                                />
                                                <span className="text-danger error-msg">
                                                  {(errorsLicence[i] &&
                                                    errorsLicence[i].value.startDate)}
                                                </span>
                                              </div>
                                              <div className="col-md-6">
                                                <label className="text-left">Select end date</label>

                                                <DatePicker
                                                  selected={o.value.endDate ? new Date(o.value.endDate) : ''}
                                                  dateFormat="MMM d yyyy"
                                                  placeholderText="Select End Date"
                                                  className="form-control w-100"
                                                  onChange={(evt) => {
                                                    o.value.endDate = evt
                                                    setFilterObj([...filterObj]);
                                                    if (errorsLicence[i]) delete errorsLicence[i].value.endDate;


                                                  }}
                                                />
                                                <span className="text-danger error-msg">
                                                  {errorsLicence[i] &&
                                                    errorsLicence[i].value.endDate}
                                                </span>
                                              </div>
                                            </div>
                                            : null

                                        }

                                        {
                                          (o.type == "contains") || (o.type == "greaterThan") || (o.type == "lessThan") ?
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="form-group mb-0">
                                                  <label className="text-left">Input</label>
                                                  <input type="text" className="form-control" name={o.input} value={o.input}
                                                    placeholder="Enter input"
                                                    onChange={(evt) => {
                                                      o.input = evt.target.value; setFilterObj([...filterObj]);
                                                      if (errorsLicence[i]) delete errorsLicence[i].input;

                                                    }}
                                                  />
                                                  <span className="text-danger error-msg">
                                                    {errorsLicence[i] &&
                                                      errorsLicence[i].input && errorsLicence[i].input}

                                                  </span>
                                                </div>
                                              </div>
                                            </div> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="add-row">
                                  {filterObj && filterObj.length >= 2 ?
                                    <span class="bx bxs-minus-circle mr-1 text-danger" onClick={() => removeRow(i)} type="button"></span>
                                    : null}
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="add-row add-plus">
                            <span className="add-ic" onClick={(e) => addNewRow(e)} class="bx bxs-plus-circle text-success" type="button"></span>
                          </div>
                        </form>
                      )
                    })}
                  </div>
                </div>

                {
                  loadingcheckbox ? <div></div> : null
                }

                {
                  loadingFilter ? <div></div> : null
                }

                <div class="col-md-12">
                  <form class="form-inline align-items-start mt-3">
                    <div class="form-group mr-sm-2 mb-2">
                      <button onClick={(evt) => ApplyFilter(evt, "filter")} type="button" class="btn btn-primary">
                        Search
                                                                                        </button>
                    </div>
                    <div class="form-group mx-sm-2 mb-2">
                      <button onClick={resetFilter} type="button" class="btn btn-secondary disabled">
                        Clear
                                                                                        </button>
                    </div>

                    <div class="form-group mx-sm-2 mb-2 d-flex flex-column">
                      <div class="input-group">
                        <input type="text" name="filterName" name='filterName' className="form-control" value={filterName} placeholder='Enter Name to Save Fliter'
                          onChange={(e) => { setFilterName(e.target.value); errors = Object.assign(errors, { filterName: "" }); setErrors(errors) }} />

                        <div class="input-group-append">
                          <button onClick={(evt) => SaveFilter(evt, "filter")} class="btn btn-default" type="button">Save &amp; Search</button>
                        </div>
                      </div>
                      <span className="error-block text-danger error-msg d-block text-left w-100 mt-1"> {errors.filterName} </span>
                    </div>
                    <div class="form-group mx-sm-2 mb-2">
                      <Select
                        showSearch={false}
                        selected={filterColor}
                        defaultValue="green"
                        value={filterColor}
                        className="custom-input color-dropdown"
                        style={{ width: 140 }}
                        onChange={handleChangeColor}
                      >

                        <Option value="gray"><a class="dropdown-item" href="#"><span class="color-shade bg-gray"></span><span class="color-text">Gray</span></a>
                        </Option>
                        <Option value="yellow">
                          <a class="dropdown-item" href="#"><span class="color-shade bg-yellow"></span><span class="color-text">Yellow</span></a>
                        </Option>
                        <Option value="green"> <a class="dropdown-item" href="#"><span class="color-shade bg-green"></span><span class="color-text">Green</span></a>
                        </Option>
                        <Option value="purple"> <a class="dropdown-item" href="#"><span class="color-shade bg-purple"></span><span class="color-text">Purple</span></a>
                        </Option>
                        <Option value="rose"><a class="dropdown-item" href="#"><span class="color-shade bg-rose"></span><span class="color-text">Rose</span></a>
                        </Option>
                        <Option value="skyblue"><a class="dropdown-item" href="#"><span class="color-shade bg-skyblue"></span><span class="color-text">Skyblue</span></a>
                        </Option>
                      </Select>
                    </div>
                  </form>
                </div>


              </div>
            </div>
            <div class="col-md-3">
              <div class="label-container d-flex flex-wrap">
                {
                  getFilterValues && getFilterValues.length ?
                    getFilterValues.map((filter, key) => {
                      return (
                        <div className="d-flex align-items-center flex-wrap">
                          <span class="user-save label d-flex align-items-center border rounded text-nowrap p-2 mr-2 mb-2">
                            {
                              filter && filter.color ?
                                <span class={`label-color-dot ${filter.color} border rounded-circle`}></span> : null
                            }
                            <span onClick={() => getsavedfilter(filter)} data-toggle="popover" data-toggle="popover" className="text-capitalize" >{filter.description}</span>
                          </span>
                          <buttom onClick={() => EditSaveFilteredRecords(filter)}  ><em className="bx bx-edit text-primary cursor-pointer"></em></buttom>
                          <span><em onClick={() => DeleteFilter(filter._id)} className="bx bx-trash-alt text-danger cursor-pointer"></em></span>

                        </div>
                      )
                    })
        
                    : <div className="filter-title">No saved Filters</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  let validCheckbox = templateSettings.filter(vendor => vendor['status'] === true)
  var { firstName, photo, lastName, emailId, mobile, emailVerificationStatus, status, _id } = columnSettings;
  let isSelected = usersList.filter((rec) => rec.isChecked == true)
  let isCheckboxSelected = isSelected.map(({ _id }) => _id)

  return (
    <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block overflow-auto">

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="content-header-title">Users</li>
                  <li class="breadcrumb-item"><Link onClick={() => props.history.push('/dashboard')}><i class="bx bx-home-alt"></i></Link></li>
                  <li class="breadcrumb-item active" aria-current="page">users</li>
                </ol>
              </nav>

              <div class="content-area position-relative">
                <div class="grid">
                  <div class="grid-content">
                    {/* <!-- filterbar buttons --> */}
                    <div class="user-listing-filterOptions mb-2 d-block">
                      <div class="row mb-2">
                        <div class="col-sm-8 position-static">
                          <div class="left-buttons d-flex ">
                            <label>
                              <div class="search-input-wrapper position-relative">
                                <i class="bx bx-search"></i>        <input type="search" className="form-control text-capitalize" placeholder="search"
                                  onChange={(evt) => searchField(evt.target.value,)} />

                              </div>
                            </label>

                            <div id="container1" class={filterpopup ? "custom-dropdown filter-data-dropdown position-static ml-2 open" : "custom-dropdown filter-data-dropdown position-static ml-2"}>
                              <button onClick={() => { openFilterpopup(!filterpopup); openDownloadpopup(false) }} class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white" type="button">
                                <i class="bx bx-filter d-lg-none"></i>
                                <span class="d-none d-sm-none d-lg-inline-block">Filter Data</span>
                              </button>
                              {
                                renderFilter()
                              }
                            </div>
                            {
                              isCheckboxSelected.length > 0 ?
                                <React.Fragment>
                                  <button onClick={() => deleteUser()} type="button" class="btn btn-default delete-btn minW-0 btn-bg-white">
                                    <i class='bx bx-trash-alt'></i>
                                  </button>
                                  <div className="custom-checkbox mx-1">
                                    <label>
                                      <input type="checkbox"
                                        // value="checked"                               
                                        onChange={() => { setUserStatus({ usersList }); toggleStatus() }}
                                        id="cccq"
                                        checked={togglestatus ? togglestatus : false}
                                      />

                                      <span className="switch"><i class='bx bx-toggle-left'></i></span>
                                    </label>
                                  </div>
                                </React.Fragment>

                                : null
                            }
                          </div>
                        </div>
                        {
                          loading ? <Loader /> : null
                        }
                        <div class="col-sm-4">
                          <div class="right-buttons d-flex justify-content-end">
                            <div className={downloadpopup ? "custom-dropdown download-dropdown dropdown download-btn open" : "custom-dropdown download-dropdown dropdown download-btn"}>
                              <button onClick={() => { openDownloadpopup(!downloadpopup); openFilterpopup(false) }} class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white" type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-download d-lg-none"></i>
                                <span class="d-none d-sm-none d-lg-inline-block">Download</span>
                              </button>
                              <div class="pt-3 pr-3 pb-3 pl-0 download-dropdown-menu">
                                <div class="container-fluid">
                                  <div class="row flex-nowrap">
                                    <div class="left-col p-0">

                                      <span className="error-block error-msg text-danger d-block text-left p-2"> {errors.checkbox} </span>
                                      {templateSettings.map((o, i) => {
                                        return (
                                          <div className="list-group-item">
                                            <div className="custom-checkbox">
                                              <label>
                                                <input id={i} type="checkbox" checked={o.status} onChange={(evt) => {
                                                  o.status = !o.status
                                                  setTemplateSettings([...templateSettings]);
                                                  errors = Object.assign(errors, { checkbox: "" }); setErrors(errors)
                                                }}
                                                />
                                                <span></span>
                                                {o.key == "photo" ? "Photo" : o.key == "firstName" ? "First Name" : o.key == "lastName" ? "Last Name" : o.key == "emailId" ? "Email Id" : o.key == "mobile" ? "Mobile" : o.key == "emailVerificationStatus" ? "Emailverification status" : o.key == "status" ? "Status" : o.key == "dob" ? "DOB" : o.key == "website" ? "website" : o.key == "gender" ? "Gender" : o.key == "address" ? "Address" : null}
                                              </label>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                    <div class="right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3">
                                      <div class="row m-0">
                                        <div class="col-left">
                                          <div class="input-group mb-3">
                                            <input class="form-control" type="text" value={tempName} name="tempName" placeholder="Save template"
                                              onChange={(e) => { setTempName(e.target.value); errors = Object.assign(errors, { tempName: "" }); setErrors(errors) }}
                                            />
                                            <div class="input-group-append">
                                              <button class="btn btn-primary" type="button" onClick={() => saveTemplets("userListing")}  >Save Template</button>
                                            </div>
                                          </div>
                                          <span className="error-block error-msg  text-danger d-block text-left"> {errors.tempName} </span>
                                        </div>
                                        <div class="col-right">
                                          <Select
                                            showSearch={false}
                                            selected={templateColor}
                                            defaultValue="green"
                                            value={templateColor}
                                            className="custom-input color-dropdown"
                                            style={{ width: 140 }}
                                            onChange={handleChangeTempaletColor}
                                          >
                                            <Option value="gray"><a class="dropdown-item" href="#"><span class="color-shade bg-gray"></span><span class="color-text">Gray</span></a>
                                            </Option>
                                            <Option value="yellow">
                                              <a class="dropdown-item" href="#"><span class="color-shade bg-yellow"></span><span class="color-text">Yellow</span></a>
                                            </Option>

                                            <Option value="green"> <a class="dropdown-item" href="#"><span class="color-shade bg-green"></span><span class="color-text">Green</span></a>
                                            </Option>

                                            <Option value="purple"> <a class="dropdown-item" href="#"><span class="color-shade bg-purple"></span><span class="color-text">Purple</span></a>

                                            </Option>

                                            <Option value="rose"><a class="dropdown-item" href="#"><span class="color-shade bg-rose"></span><span class="color-text">Rose</span></a>

                                            </Option>
                                            <Option value="skyblue"><a class="dropdown-item" href="#"><span class="color-shade bg-skyblue"></span><span class="color-text">Skyblue</span></a>
                                            </Option>
                                          </Select>
                                        </div>

                                      </div>
                                      <div className="row m-0 flex-column">
                                        <div class="scrollable template-list-outer right-col-list">
                                          <div class="label-container d-flex flex-wrap">
                                            {
                                              savedTemp && savedTemp.length ?
                                                savedTemp.map((template, key) => {
                                                  return (
                                                    <div className="d-flex align-items-center w-100">
                                                      <div>
                                                        <span class="filter-label d-flex align-items-center rounded text-nowrap p-2 mr-2 mb-2">
                                                          {
                                                            template && template.color ?
                                                              <span class={`label-color-dot ${template.color} border rounded-circle`}></span> : null
                                                          }
                                                          <span data-toggle="popover" data-toggle="popover" onClick={() => editSavedTemplates(template)}>{template.description}</span>
                                                        </span>
                                                      </div>
                                                      <span class="d-inline-flex align-items-center mb-2">
                                                        <a onClick={() => DeleteTemp(template._id)}><i class='bx bx-trash-alt text-primary'></i></a>
                                                      </span>
                                                    </div>
                                                  )
                                                })
                                                : <div className="filter-title">No saved Files</div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      {
                                        validCheckbox.length > 0 ?
                                          <div class="bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3">
                                            <button onClick={() => downloadUserFiles("excel")} type="button" class="btn border mr-2" id="ExportReporttoExcel">
                                              <span class="mr-2">
                                                <img class="img-fluid" src="assets/images/icon/excel-icon.svg" alt="Excel" />
                                              </span>
                                                                                    Excel
                                              <span className="bx bx-download ml-2"></span>
                                        </button>
                                            <button onClick={() => downloadUserFiles("csv")} type="button" class="btn border" id="ExportReporttoCSV">
                                              <span class="mr-2" >
                                                <img class="img-fluid" src="assets/images/icon/csv-icon.svg" alt="Excel" />
                                              </span>
                                                                                    CSV
                                              <span className="bx bx-download ml-2"></span>
                                        </button>
                                          </div> : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <button id="addNewUser" type="button" class="btn glow-primary btn-primary mr-0 minW-md-0">
                                <i class="bx bx-user-plus d-lg-none"></i>
                                <span class="d-none d-sm-none d-lg-inline-block">Add New User</span>
                            </button> */}

                          </div>
                        </div>
                      </div>
                      <div className="filter-labels">
                        {
                          addedFilter.description ?
                            <div className="multiple-filter">
                              <label className="mb-0">{addedFilter.description}</label>
                              <label className="remove-label mb-0" onClick={resetFilter}><em className="bx bx-x"></em></label>
                            </div>
                            : null
                        }
                      </div>
                      <div className="search-label">
                        {
                          searchFilter && searchFilter.length > 0 ? <div className="multiple-text">
                            {
                              searchFilter && searchFilter.length >= 0 && Array.isArray(searchFilter) && searchFilter.map((filter, i) => {
                                return (
                                  <React.Fragment>
                                    <div className="text-label mr-2">
                                      {
                                        filter && filter.type != "date" && typeof filter.value != "object" ?
                                          <label className="mb-0" >{filter.key} <span className="text-success">contains</span> {filter.value}</label> : null
                                      }
                                      {
                                        filter && filter.type == "date" && typeof filter.value == "object" ?
                                          <React.Fragment>
                                            {
                                              filter && filter.value && filter.value.startDate ?
                                                <React.Fragment>
                                                  <label className="mb-0">{filter.key} <span className="text-success">is added from  Date </span> {filter.value.startDate && filter.value && filter.value.startDate ? moment(filter.value.startDate).format('L') : null} TO Date {filter.value.startDate && filter.value && filter.value.endDate ? moment(filter.value.endDate).format('L') : null}   </label>

                                                </React.Fragment>
                                                : null

                                            }
                                          </React.Fragment>
                                          : null
                                      }
                                      <label className="remove-label" onClick={() => filterSearch("filter", i)}><em className="bx bx-x"></em></label>
                                    </div>
                                  </React.Fragment>
                                )
                              })
                            }
                          </div> : null
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- filter bar buttons end --> */}
              {/* <!-- datatable start --> */}
              <table class="table row-border nowrap common-datatable" id="user-listing">
                <thead>
                  <tr>
                    <th className="checkbox-table">
                      <div className='custom-checkbox'>
                        <label>
                          <input type="checkbox"
                            value="checkAll"
                            checked={allChecked}
                            onChange={handleChangeCheckbox2} />
                          <span></span>
                        </label>
                      </div>
                    </th>
                    {photo ? <th>Profile</th> : null}
                    <th onClick={() => onSort('firstName')}>First Name <i aria-hidden="true" className={(sortData['firstName']) ? "bx bxs-chevron-up" : "bx bxs-chevron-down"}></i></th>
                    <th onClick={() => onSort('lastName')}>Last Name <i aria-hidden="true" className={(sortData['lastName']) ? "bx bxs-chevron-up" : "bx bxs-chevron-down"}></i></th>
                    <th onClick={() => onSort('emailId')}>Email <i aria-hidden="true" className={(sortData['emailId']) ? "bx bxs-chevron-up" : "bx bxs-chevron-down"}></i></th>
                    <th>Status</th>
                    <th class="all">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (usersList && usersList.length === 0) ? <tr className="text-center text-danger not-found-txt">
                      <td colSpan="6">
                        {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">No Records Found!</h6> : null}
                      </td>
                    </tr> :
                      usersList && Array.isArray(usersList) && usersList.map((user, key) => {
                        return (
                          <tr>
                            <td>
                              <div className='custom-checkbox'>

                                <label>
                                  <input key={user._id} type="checkbox" name={user.firstName} value={user.firstName} checked={user.isChecked} onChange={handleChangeCheckbox2} />
                                  <span />
                                </label>
                              </div>
                            </td>
                            {photo ? <td>
                              <div className="thumb-img">
                                {
                                  user.photo && user.photo != "" ?
                                    <img src={user.photo ? IMG_URL + user.photo : '/assets/images/no-image-user.png'} alt="/assets/images/no-image-user.png" /> :
                                    <img src={"/assets/images/no-user.png"} alt="/assets/images/no-image-user.png" />
                                }
                              </div>
                            </td> : null}
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>
                              <div class="eamil-col d-flex align-items-center">
                                <div class="badge-outer circle round d-flex align-items-center mr-2">
                                  {
                                    user.status ?
                                      <span class="badge badge-success circle"> </span> :
                                      <span class="badge badge-danger circle"> </span>
                                  }
                                </div>
                                <span>{user.emailId}</span>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch light">
                                <input type="checkbox" class="custom-control-input"
                                  onChange={() => { user.status = !user.status; setUserStatus({ usersList }); userStatusChange(user.status, user._id) }}
                                  id={user._id}
                                  checked={user.status ? user.status : false}
                                />
                                <label class="custom-control-label"
                                  for={user._id}></label>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex">

                                <a onClick={() => props.history.push(`/userdetails/${user._id}`)} class="cursor-pointer mr-3"><i class='bx bx-show-alt'></i></a>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                  }
                </tbody>
              </table>
              {
                (usersList && usersList.length === 0) ? <div>  </div> :
                  <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center justify-content-between">
                    <div>
                      <label className="mr-2">Showing</label>
                      <Select placeholder={<b>10 Items Per Page</b>} optionFilterProp="children" value={length}
                        onChange={(value) => setPagesize(value)}  >
                        <Option value={2}>2</Option>
                        <Option value={5}>5</Option>
                        <Option value={10}>10</Option>
                        <Option value={15}>15</Option>
                        <Option value={50}>50</Option>
                      </Select>
                      <label className="ml-2">Out of {total} Users</label>
                    </div>
                    <div className="pagination-list mt-2 mt-sm-3">
                      <Pagination className="ant-pagination" pageSize={pagesize} current={page} total={total} onChange={paginationChange} />
                    </div>
                  </div>
              }
            </div>
            <footer>
              <div className="footer-text d-flex align-items-centerf justify-content-between">
                <span className="d-block">2020 © IndiaNIC</span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </Home>

  );
}
const mapStateToProps = state => ({
  UserAccess: state.admin.adminData && state.admin.adminData.rolePermission && state.admin.adminData.rolePermission.userAccess,
});

export default connect(mapStateToProps, { callApi, editUser })(UsersList);



