import React, { useState, useEffect } from 'react';
import {Tab , Tabs,  Button, Modal} from 'react-bootstrap';
import Home from '../Home';
import { connect } from 'react-redux'
import errorMessages from '../../actions/errorMessages'
import { callApi } from '../../actions/commonFunction';
import {Link} from 'react-router-dom';
import  Select  from 'react-select';
import common from '../../actions/common';
import swal from 'sweetalert';
import * as _ from 'lodash';
import * as language from "../../translations/index";
import {useSortng} from '../CommonFeature/Sorting';
import API from '../../actions/API';
import Pagination from 'rc-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

function Faq(props) {

  const [faqID, setFaqID] = useState('');
  const [faqCategoryName, setFaqCategoryName] = useState('');
  const [faqCategory, setFaqCategory] = useState('');
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');
  const [total, setTotal] = useState(1);
  const [showCategory, setShowCategory] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const [eventKey, setEventKey] = useState("faqList");
  const [isFormValid, setIsFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [faqListing, setFaqListing] = useState([]);
  const [faqCategoryID, setFaqCategoryID] = useState('');
  const [categoryListing , setCategoryListing] = useState([]);
  const [faqPageSize, setFaqPageSize] = useState(10);
  const [faqPage, setFaqPage] = useState(1);
  const [faqTotal, setFaqTotal] = useState(1);
  const [faqLength, setFaqLength] = useState(1);
  const [categoryLength, setCategoryLength] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [categoryPageSize, setCategoryPageSize] = useState(10);
  const [categoryTotal, setCategoryTotal] = useState(1);
  const [sortData,sort, onSort] = useSortng();
  const [condition, setCondition] = useState('');
  const [multipleDelete, setMultipleDelete] = useState('');
  const [searchText, setSearchText] = useState('');
  let [errors, setErrors] = useState('');
  const translations = language[props.language !== undefined ? props.language :"en"];

  useEffect(() => {
    getFAQCategoryListing();
    getFAQListDetails();
  }, [])

  //**************** VALIDATE FORM **************** */
  const  validateForm = (type) => {
    let errors  = { faqCategoryName : "", faqQuestion: "", faqAnswer:"", faqCategory: ""};
    let isFormValid = true;
    
    if(type === 'faqCategory') {
      if (!faqCategoryName.trim()) errors.faqCategoryName = '*FAQ category is required';
      else errors.faqCategoryName = '';
    }
    
    else if(type === 'faqList') {
      
      if(!faqCategory) errors.faqCategory = "*FAQ category is required";
      else errors.faqCategory = "";

      if (!faqQuestion) errors.faqQuestion = '*FAQ question is required';
      else if (faqQuestion.length < 10) errors.faqQuestion = "Question must be minimum 10 characters."
      else errors.faqQuestion = '';

      if (!faqAnswer) errors.faqAnswer = '*FAQ answer is required';
      else if(faqAnswer.length < 20) errors.faqAnswer ="Answer must be minimum 20 characters."
      else errors.faqAnswer = '';
    }

    if (errors.faqCategoryName !== '' || errors.faqQuestion !== '' || errors.faqAnswer !== '') isFormValid = false;
    else isFormValid = true;

    setErrors(errors);
    setIsFormValid(isFormValid);
    
    return isFormValid
  }

  //************ LIST FAQ CATEGORIES ********* */
  const getFAQCategoryListing = async () => {
    let body = {
      page: categoryPage, 
      pagesize: categoryPageSize, 
      sort, 
      columnKey:"faqCategoriesListing",
      // filter:filterObj ? filterObj:"" 
      condition:condition
    };
    // console.log("body", body)
    try {
    const response = await props.callApi(API.FAQ_CATEGORY_LISTING, body, 'post', null, true);
    console.log("response", response);
      if (response.status === 1) {
        setCategoryListing(response.data.listing);
        // setColumnSettingsArr(response.data.columnSettings);
        // setGetFilterValues(response.data.filterSettings);
        setTotal(response.total);
        // setLength(response.data.listing.length);
        setLoading(false);
      } 
    } catch(error){
      setLoading(false);
      throw (error)
    }
  }

  //***************** GET FAQ CATEGORY DETAILS  ******************* */
  const getFAQCategoryDetails = async(id) => {
    setShowCategory(true);
    try {
    const response = await props.callApi(API.GET_FAQ_CATEGORY_DETAILS + id, '', 'get', null, true);
    console.log("category details", response);
      if (response.status === 1) {
      let {  faqCategoryName } = response.data;
      setFaqCategoryName(faqCategoryName);
      }

    } catch(error) {
      throw error;
    }
  }

  //************ ADD FAQ CATEGORY ****************/
  const addFAQCategory = async(e) => {
    e.preventDefault();
    setShowCategory(true);
    if(validateForm('faqCategory')) {
      
      let body = {
        faqCategoryName
      };

      try {
        const response = await props.callApi(API.ADD_UDPATE_FAQ_CATEGORY, body, 'post', null, true);
        if(response.status === 1) {
          toast.success('Category added successfully',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setShowCategory(false);
        setFaqCategoryName("");
        getFAQCategoryListing();
      } catch(error) {
        throw error;
      }
    }
  }

  //*************** DELETE FAQ CATEGORY ************** */
  const  deleteFaqCategory  = async (bId) => {
    let delArr = multipleDelete;
    let body;
    delArr = _.compact(delArr)
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async willDelete => {
      if (willDelete) {
        if (delArr.length > 0) {
          body = { ids: delArr }
        }
        else {
          body = { faqCategoriesIds: [bId] }
        }
        const response = await props.callApi(API.DELETE_FAQ_CATEGORY, body, 'post', null, true);
        if (response.status === 1) {
          toast.success('Category Deleted successfully',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getFAQCategoryListing()
        }
      }
    });
  }

  //**************** EDIT FAQ CATEGORY ******************* */
  const updateFaqCategory = async(id) => {
    if(validateForm('faqCategory')){
      let body = {
          faqCategoryId: id,
          faqCategoryName
      };

      try{
          const response = await props.callApi(API.ADD_UDPATE_FAQ_CATEGORY, body, 'post', null, true);
          console.log("Response updated", response);
          if (response.status === 1) {
            toast.success('Category updated successfully',{
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowCategory(false);
            getFAQCategoryListing();
          }
      } catch(error) {
        throw(error)
      }
    }
  }

  //************ All FAQ LISTS ********* */
  const getFAQListDetails = async () => {
    let body = {
      page: faqPage, 
      pagesize: faqPageSize, 
      sort, 
      columnKey:"faqsListing",
      // filter:filterObj ? filterObj:"" 
      condition:condition
    };
    // console.log("body", body)
    try {
    const response = await props.callApi(API.FAQ_LISTNG, body, 'post', null, true);
      if (response.status === 1) {
        setFaqListing(response.data.listing);
        setTotal(response.total);
        setLoading(false);
      } 
    } catch(error){
      setLoading(false);
      throw (error)
    }
  }

  //************ ADD FAQ ****************/
  const addFAQ = async(e) => {
    e.preventDefault();
    setShowFaq(true);
    if(validateForm('faqList')) {
      
      let body = {
        faqQuestion,
        faqAnswer,
        faqCategoryName: faqCategory.label
      };

      try {

        const response = await props.callApi(API.ADD_UPDATE_FAQ, body, 'post', null, true);
        if(response.status === 1) {
          toast.success('New FAQ added successfully',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setShowFaq(false);
        setFaqCategory("");
        setFaqQuestion("");
        setFaqAnswer("");
        getFAQListDetails();
      } catch(error) {
        throw error;
      }
    }
  }

  //*************** DELETE FAQ  ************** */
  const  deleteFaq  = async (bId) => {
    
    let delArr = multipleDelete;
    let body;
    delArr = _.compact(delArr)
    
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async willDelete => {
      if (willDelete) {
        if (delArr.length > 0) {
          body = { ids: delArr }
        }
        else {
          body = { faqsIds: [bId] }
        }
        const response = await props.callApi(API.DELETE_FAQ, body, 'post', null, true);
        if (response.status === 1) {
          toast.success('FAQ Deleted successfully',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getFAQListDetails();
        }
      }
    });
  }

  //**************** EDIT FAQ  ******************* */
  const updateFaq = async(id) => {
    if(validateForm('faqList')){
      
      let body = {
          faqId: id,
          faqQuestion,
          faqAnswer,
          faqCategoryName: faqCategory.label
      };

      try{
          const response = await props.callApi(API.ADD_UPDATE_FAQ, body, 'post', null, true);
          console.log("Response updated", response);
          if (response.status === 1) {
            toast.success('FAQ updated successfully',{
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowFaq(false);
            getFAQListDetails();
          }
      } catch(error) {
        throw(error)
      }
    }
  }

  //***************** GET PARTICULAR FAQ DETAILS ******************
  const getAllFaqDetailsHandler = async(id) => {
    setShowFaq(true);
    try {
    const response = await props.callApi(API.GET_FAQ_DETAILS + id, '', 'get', null, true);
    console.log("faq details", response);
      if (response.status === 1) {
      let {  faqCategoryName, faqQuestion, faqAnswer } = response.data;
      
      setFaqCategory({value:faqCategoryName, label: faqCategoryName});
      setFaqQuestion(faqQuestion);
      setFaqAnswer(faqAnswer);  
      }
    } catch(error) {
      throw error;
    }
  }

  const clearInput = () => {
    setSearchText("");
    getFAQCategoryListing();
    getFAQListDetails();
  }

  const searchField = async(eventKey) => {
    try {
      if(eventKey === "faqList") {
        
        let body = {
          page: faqPage,
          pagesize: faqPageSize,
          searchText: searchText && searchText.length > 1 ? searchText : ""
        };

        const response = await props.callApi(API.FAQ_LISTNG, body, 'post', null, true);
        if (response.status === 1) {
          setFaqListing(response.data.listing);
          setTotal(response.data.total);
          setFaqLength(response.data.listing.length);
        } 
      } else if(eventKey === "faqCategory") {

        let body = {
          page: categoryPage,
          pagesize: categoryPageSize,
          searchText: searchText && searchText.length > 1 ? searchText : ""
        };

        const response = await props.callApi(API.FAQ_CATEGORY_LISTING, body, 'post', null, true);
        if (response.status === 1) {
          setCategoryListing(response.data.listing);
          setTotal(response.data.total);
          setCategoryLength(response.data.listing.length);
        } 
      } 
    } catch (error) {
      throw(error);
    }
  }

  const openCategoryModal = () => {
    setShowCategory(true);
    if (faqCategoryID) {
      setFaqCategoryID(null);
      setFaqCategoryName("");
    }
  }
  
  const closeCategoryModal = () => {
    setShowCategory(false);
    setErrors("");
  }

  const openFAQModal = () => {
    setShowFaq(true);
    if (faqID) {
      setFaqID(null);
      setFaqCategory(null);
      setFaqQuestion("");
      setFaqAnswer("");
    }
  }

  const closeFAQMdal = () => {
    setShowFaq(false);
    setErrors("");
  }

  const addUpdateCategory = (e) => {
    faqCategoryID ? updateFaqCategory(faqCategoryID) : addFAQCategory(e);
  }

  const addUpdateFAQ = (e) => {
    faqID ? updateFaq(faqID) : addFAQ(e);
  }

  const faqPagination = (page, pageSize) => {
    setFaqPage(page);
    setFaqPageSize(pageSize);
  } 

  const categoryPagination = (page, pageSize) => {
    setFaqPage(page);
    setFaqPageSize(pageSize);
  } 

  const options = [];
  for (let i = 0; i < categoryListing.length; i++) {
    options.push({
      value: categoryListing[i].faqCategoryName,
      label: categoryListing[i].faqCategoryName
    })
  }

  return (
    <Home>
      <div>
        <div className="dashboard-container">
          {/* Main start here */}
          <div className="main-content-area">
           
           
            {/* Sidebar end */}
            {/* Body overlay */}
            <div className="overlay" />
            {/* -- Body overlay */}
            {/* Header end */}
            <div className="main-content-block">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="content-header-title">{translations.ContentManagement.FAQ.faq}</li>
                  <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                  <li className="breadcrumb-item" >{translations.ContentManagement.FAQ.content}</li>
                  <li className="breadcrumb-item active" aria-current="page">{translations.ContentManagement.FAQ.faq}</li>
                </ol>
              </nav>
              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                <div className="d-flex align-items-center">
                  <div className="search position-relative has-icon-left">
                    <input type="search" className="form-control text-capitalize" placeholder={`${translations.ContentManagement.CMSListing.search}`} 
                    value={searchText} onChange={(evt) => {searchField(eventKey); setSearchText(evt.target.value)}}
                    />
                      {
                        searchText && searchText.length > 0 ?
                          <div className="clear">
                            <span onClick={clearInput} ><em className="bx bx-x"></em></span>
                          </div> : null
                      }
                    <div className="form-control-position">
                      <em className="bx bx-search" />
                    </div>
                  </div>
                </div>
                <div className="button-box mb-sm-0 mb-2  mt-sm-0 mt-2">
                  <Button 
                  type="button" 
                  className="btn btn-secondary glow-secondary mr-1" 
                  onClick={openCategoryModal}>
                    {translations.ContentManagement.FAQ.addCategory}
                  </Button>
                  <Modal show={showCategory} onHide={closeCategoryModal} >
                      <Modal.Header closeButton>
                        <div class="d-flex align-items-center">
                            <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                            <h5 class="modal-title" id="exampleModalLongTitle">{translations.ContentManagement.FAQ.addCategory}</h5>
                        </div>

                      </Modal.Header>
                      <Modal.Body closeButton>
                        <div class="notification-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-4">
                                        <label class="mb-0" for="AddCategory">{translations.ContentManagement.FAQ.addCategory}<sup className="text-danger">*</sup></label>

                                          <input 
                                          type="text" 
                                          className="form-control" 
                                          id="faqCategoryName" 
                                          name="faqCategoryName"
                                          placeholder={`${translations.ContentManagement.FAQ.addCategory}`}
                                          value={faqCategoryName}
                                          onChange={(e) => {setFaqCategoryName(e.target.value) ;errors=Object.assign(errors, { faqCategoryName: "" }); setErrors(errors)}}
                                          />

                                          <span className='error-msg'  style={{color: "red"}} >{errors.faqCategoryName}</span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                  <div class="modal-btn">
                                      <Link onClick={(event) => addUpdateCategory(event)} class="btn btn-primary">{faqCategoryID ? "Update" : "Add"}</Link>
                                  </div>
                                </div>
                          </div>
                        </div>
                      </Modal.Body>
                  </Modal>
                  <Button 
                  type="button"
                  className="btn btn-primary glow-primary"
                  onClick={openFAQModal}
                  >{translations.ContentManagement.FAQ.addNewFAQ}
                  </Button>
                  <Modal show={showFaq} onHide={closeFAQMdal} >
                      <Modal.Header closeButton>
                        <div class="d-flex align-items-center">
                            <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                            <h5 class="modal-title" id="exampleModalLongTitle">{translations.ContentManagement.FAQ.addNewFAQ}</h5>
                        </div>

                      </Modal.Header>
                      <Modal.Body closeButton>
                        <div class="notification-form">
                            <div class="row">
                                <div class="col-md-12">
                                  <div className="form-group mb-md-3 mb-3">
                                  <label htmlFor="blogCategory">{translations.ContentManagement.FAQ.category}<sup className="text-danger">*</sup></label>
                                        <Select className="selectpicker w-100"
                                          id="faqCategory"
                                          name="faqCategory"
                                          placeholder = "Select Category"
                                          value={faqCategory}
                                          options={options}
                                          onChange={(e) => { setFaqCategory(e); errors = Object.assign(errors, { faqCategory: "" }); setErrors(errors)}}>
                                        </Select>
                                        <span className='error-msg'  style={{color: "red"}} >{errors.faqCategory}</span>
                                  </div>
                                  <div><div className="modalTitle mb-2"><span class='bx bxs-plus-circle' onClick={openCategoryModal}></span><label>Add New Category</label></div>
                                    <Modal show={showCategory} onHide={closeCategoryModal} >
                                          <Modal.Header closeButton>
                                            <div class="d-flex align-items-center">
                                                <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
                                                <h5 class="modal-title" id="exampleModalLongTitle">{translations.ContentManagement.FAQ.addCategory}</h5>
                                            </div>

                                          </Modal.Header>
                                          <Modal.Body closeButton>
                                            <div class="notification-form">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group mb-4">
                                                            <label class="mb-0" for="AddCategory">{translations.ContentManagement.FAQ.addCategory}</label>

                                                              <input 
                                                              type="text" 
                                                              className="form-control" 
                                                              id="faqCategoryName" 
                                                              name="faqCategoryName"
                                                              placeholder={`${translations.ContentManagement.FAQ.addCategory}`}
                                                              value={faqCategoryName}
                                                              onChange={(e) => {setFaqCategoryName(e.target.value) ;errors=Object.assign(errors, { faqCategoryName: "" }); setErrors(errors)}}
                                                              />

                                                              <span className='error-msg'  style={{color: "red"}} >{errors.faqCategoryName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div class="modal-btn">
                                                          <Link onClick={(event) => addUpdateCategory(event)} class="btn btn-primary">{faqCategoryID ? "Update" : "Add"}</Link>
                                                      </div>
                                                    </div>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                      </Modal>
                                  </div>
                                  <div class="form-group mb-4">
                                      <label class="mb-0" for="CountryName">{translations.ContentManagement.FAQ.question}<sup className="text-danger">*</sup></label>

                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="faqQuestion" 
                                        name="faqQuestion"
                                        placeholder="Add your question" 
                                        value={faqQuestion}
                                        onChange={(e) => {setFaqQuestion(e.target.value) ;errors=Object.assign(errors, { faqQuestion: "" }); setErrors(errors)}}
                                        />

                                        <span className='error-msg'  style={{color: "red"}} >{errors.faqQuestion}</span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-4">
                                        <label class="mb-0" for="CountryName">{translations.ContentManagement.FAQ.answer}<sup className="text-danger">*</sup></label>

                                          <textarea 
                                          type="text" 
                                          className="form-control" 
                                          style={{height:'200px'}}
                                          id="faqAnswer" 
                                          name="faqAnswer"
                                          placeholder="Add your answer" 
                                          value={faqAnswer}
                                          onChange={(e) => {setFaqAnswer(e.target.value) ;errors=Object.assign(errors, { faqAnswer: "" }); setErrors(errors)}}
                                          />

                                          <span className='error-msg'  style={{color: "red"}} >{errors.faqAnswer}</span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                  <div class="modal-btn">
                                      <Link onClick={(event) => addUpdateFAQ(event)} class="btn btn-primary">{faqID ? "Update" : "Add"}</Link>
                                  </div>
                                </div>
                          </div>
                        </div>
                      </Modal.Body>
                  </Modal>
                </div>
              </div>
              <div className="card notification-card tabs-block p-0">
              <Tabs defaultActiveKey="faqList" id="uncontrolled-tab-example" className="pl-5 pt-3"  onSelect={(eventKey) =>  setEventKey(eventKey)} >
                  <Tab eventKey="faqList" title={`${translations.ContentManagement.FAQ.faqList}`}>
                    <div className="card-body content faq-listing">
                      {/* datatable start */}
                      <div class="table-responsive">
                      <table className="table row-border nowrap common-datatable">
                          <thead>
                              <tr>
                                  <th className="all">{translations.ContentManagement.FAQ.category}</th>
                                  <th className="all">{translations.ContentManagement.FAQ.question}</th>
                                  <th className="all">{translations.ContentManagement.FAQ.answer}</th>
                                  <th className="all">{translations.ContentManagement.FAQ.action}</th>
                              </tr>
                          </thead>
                          <tbody>
                            {faqListing  && faqListing.length === 0 ?
                            <tr className="text-center text-danger not-found-txt">
                            <td colSpan="4">


                              {
                                loading ? <Loader /> : null
                              }                  {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="4">No records found!</h6> : null}
                            </td>
                            </tr> :

                              faqListing && Array.isArray(faqListing) && faqListing.map((item, listKey)=>{

                                return (
                                    <tr>
                                      <td>
                                          <div className="d-flex align-items-center">
                                              {/* <span className="country-img d-block mr-1"><img src="assets/images/india-flag.svg" alt="country-flag"/></span> */}
                                              <span>{item.faqCategoryName}</span>
                                          </div>
                                      </td>
                                      <td><span>{item.faqQuestion}</span></td>
                                      <td><span>{item.faqAnswer.slice(0,150)}...</span></td>
                                      <td>
                                          <div className="d-flex">
                                              <a className="cursor-pointer mr-3" onClick={() => {getAllFaqDetailsHandler(item._id); setFaqID(item._id);}} ><i className='bx bx-edit'></i></a>
                                              <a className="cursor-pointer mr-3" onClick={() =>deleteFaq(item._id)} ><i className='bx bx-trash-alt'></i></a>
                                          </div>
                                      </td>
                                  </tr>
                                )
                            })}

                          </tbody>
                      </table>
                      </div>
                     {/* datatable ends  */}
                     {/* <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center justify-content-between">
                    <div>
                        <label className="mr-2">Showing</label>
                        <Select placeholder={<b>10 Items Per Page</b>} optionFilterProp="children" value={faqLength}
                          onChange={(value) => setFaqPageSize(value)}  >
                          <Option value={2}>2</Option>
                          <Option value={5}>5</Option>
                          <Option value={10}>10</Option>
                          <Option value={15}>15</Option>
                          <Option value={50}>50</Option>
                        </Select>
                        <label className="ml-2">Out of {total} Users</label>
                      </div>
                      <div className="pagination-list mt-2 mt-sm-3">
                        <Pagination className="ant-pagination" pageSize={faqPageSize} current={faqPage} total={faqTotal} onChange={faqPagination} />
                      </div>
                    </div> */}
                  </div>
                  </Tab>
                  <Tab eventKey="faqCategory" title={`${translations.ContentManagement.FAQ.faqCategory}`} >
                    <div className="card-body content">
                      <div class="table-responsive">
                        <table className="table row-border nowrap common-datatable" id="master-timezone-listing">
                              <thead>
                                  <tr>
                                      <th className="all">Category</th>
                                      <th className="all">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {categoryListing && categoryListing.length === 0 ?
                                  <tr className="text-center text-danger not-found-txt">
                                    <td colSpan="6">


                                      {
                                        loading ? <Loader /> : null
                                      }                  {!loading ? <h6 className="text-center text-danger not-found-txt" colSpan="6">No Records found!</h6> : null}
                                    </td>
                                  </tr> :
                                  categoryListing && Array.isArray(categoryListing) && categoryListing.map((item, key)=>{

                                      return (
                                        <tr key={key}>
                                          <td>
                                              <div className="d-flex align-items-center">

                                                  <span>{item.faqCategoryName}</span>
                                              </div>
                                          </td>
                                          <td>
                                              <div className="d-flex">
                                                  <a className="cursor-pointer mr-3" onClick={() => {getFAQCategoryDetails(item._id); setFaqCategoryID(item._id);}}><i className='bx bx-edit'></i></a>
                                                  <a className="cursor-pointer" onClick={() => deleteFaqCategory(item._id)}><i className='bx bx-trash-alt'></i></a>
                                              </div>
                                          </td>
                                      </tr>
                                      )
                                  })}

                              </tbody>
                          </table>
                          </div>
                          {/* <div className="mt-3">
                            <Pagination  pageSize={categoryPageSize} current={categoryPage} total={categoryTotal} onChange={categoryPagination} />
                          </div> */}
                      </div>
                  </Tab>
              </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/*End Dashboard Wrapper*/}
      </div>

    </Home>
  );
}


const mapStateToProps = state => ({
  language: state.admin.language
});
export default connect(mapStateToProps, { callApi })(Faq);