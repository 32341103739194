import React, { useState,useEffect } from 'react';
import Home from '../Home';
import { connect } from 'react-redux';
import { callApi } from '../../actions/commonFunction';
import {Link, withRouter} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../actions/API';
import moment from 'moment';
import { create } from 'lodash';

function ViewTask(props) {

    const [userInfo, setUserInfo] = useState('');
    const [department, setDepartment] = useState('');
    const [subject, setSubject] = useState('');
    const [workType, setWorkType] = useState('');
    const [price, setPrice] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [taggedTutors, setTaggedTutors] = useState('');
    const [taskCreatedOn, setTaskCreatedOn] = useState('');
    const [taskUpdatedOn, setTaskUpdatedOn] = useState('');
    const [taskStatus, setTaskStatus] = useState('');
    const [taskRating, setTaskRating] = useState('');
    const [taskReview, setTaskReviews] = useState('');
    const [otherTaggedTutors, setOtherTaggedTutors] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [taskId, setTaskId] = useState(props.match.params.id);
    const [shiftSlot, setShiftSlot] = useState('');

    useEffect(() => {
      getTaskDetails(taskId);
    }, []);

    //*************** Get Mentor Details ****************/
    const getTaskDetails = async (id) => {
      try {
        const response = await props.callApi(API.GET_TASK + id, '', 'get', null, true);
        console.log("response get", response)
        let {data, settings} = response;
        if (response.settings.status === 1) {
          setUserInfo(`${data.student.firstName} ${data.student.lastName}`)
          setDepartment(data.department.name);
          setSubject(data.subject.name);
          setWorkType(data.worktype.format);
          setPrice(data.amount);
          setCreatedBy(`${data.createdBy.firstName} ${data.createdBy.lastName}`);
          setTaggedTutors(data.taggedTutors);
          setTaskCreatedOn(data.createdAt);
          setTaskUpdatedOn(data.updatedAt);
          setTaskStatus(data.taskStatus);
          setOtherTaggedTutors(data.otherTaggedTutors);
          setLoading(true);
        } else {
          toast.error(settings.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      } catch (error) {
        throw error;
      }
    }

    const renderSlot = () => {

      // Easy way for filtering out single slot(static method)
      let singleSlot=[];
  
      if (shiftSlot && shiftSlot.length) {
        singleSlot.push({from: shiftSlot[0].from, until: shiftSlot[0].until})
        // console.log("singleslot", singleSlot);
        return (
          singleSlot && singleSlot.length ? 
          <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
        )
      } else {
        return null;
      }
  
  
      // proper way for filtering out single slot(if huge records)
     
      // let uniqueSlot, singleSlot;
  
      // // creating deep clone of shiftSlot
      // let newSlot = JSON.parse(JSON.stringify(shiftSlot));
      
      // // filtering only "from" and "until" from shiftSlot object
      // if (newSlot && newSlot.length) {
      //   newSlot.map((shift,index, arr) => { delete shift._id; delete shift.day}); 
      // }
  
      // // filtering unique values from object
      // uniqueSlot = new Set(newSlot.map(JSON.stringify));
  
      // // converting back to array of object
      // singleSlot = Array.from(uniqueSlot).map(JSON.parse);
      // return (
        // singleSlot && singleSlot.length ? 
        // <p>{`${(singleSlot[0].from)/60}:00 - ${(singleSlot[0].until)/60}:00 `}</p> : null
      // )
    }
  
  
   // *********************

    return (
      <Home>
         <div>
          <div className="dashboard-container">
            {/* Main start here */}
            <div className="main-content-area">
              {/* Body overlay */}
              <div className="overlay" />
              {/* -- Body overlay */}
              {/* Header end */}
              <div className="main-content-block">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Manage Tasks</li>
                    <li className="breadcrumb-item" ><Link onClick={() => props.history.push('/dashboard')}><i className="bx bx-home-alt" /></Link></li>
                    <li className="breadcrumb-item"><Link onClick={() => props.history.push('/listTasks')}>List All Tasks</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">View Task</li>
                  </ol>
                </nav>
                <div className="card notification-card">
                  <div className="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                    <div className="icon d-flex align-items-center justify-content-center mr-1"><i className="bx bx-mail-send" /></div>
                    <div className="text">
                      <h5 className="mb-0 text Uppercase">Task Details</h5>
                    </div>
                  </div>
                  <form action="#">
                    {
                      loading ? 
                      <div className="row">
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="userInfo">User Info<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{userInfo}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskType">Task Type<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{department}<br/>{subject}-{workType}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskPrice">Task Price<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{price.currency}{price.price}</div>
                      </div>
                       
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="createdBy">Task Created By<sup className="text-danger">*</sup></label>
                        <div className="each-detail">
                            {createdBy}
                        </div>
                      </div>
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="tutorsTagged">Tutors Tagged<sup className="text-danger">*</sup></label>
                        <div className="each-detail">
                          {
                            taggedTutors && taggedTutors.length ? 
                            taggedTutors.map((tutor,index, arr) => {
                              if (tutor !== arr[arr.length-1]) {
                                return <span key={index}>{`${tutor.firstName} ${tutor.lastName}`}<br/> </span>
                              } else {
                                return <span key={index}>{`${tutor.firstName} ${tutor.lastName}`}</span>
                              }
                            }) : null 
                            
                          }
                        </div>
                      </div>
                      <div className="align-items-center col-md-3 mb-md-5 mb-3 user-details">
                        <label htmlFor="taskCreatedOn">Task Created On<sup className="text-danger">*</sup></label>
                          <div className="each-detail">
                            {moment(taskCreatedOn).format('L')}<br/>{moment(taskCreatedOn).format('LT')}
                          </div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskUpdatedOn">Task Last Updated On<sup className="text-danger">*</sup></label>
                          <div className="each-detail">
                            {moment(taskUpdatedOn).format('L')}<br/>{moment(taskUpdatedOn).format('LT')}
                          </div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskStatus">Task Status<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{taskStatus}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskRatings">Task Ratings<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{description}****</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskReviews">Task Reviews<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{description}Lorem Ipsum</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taskCompletedBy">Task Completed By<sup className="text-danger">*</sup></label>
                        <div className="each-detail">{description}</div>
                      </div>
                      <div className="form-group col-md-3 mb-md-5 mb-3">
                        <label htmlFor="taggedTutors">Other Tagged Tutors<sup className="text-danger">*</sup></label>
                        <div className="each-detail">
                        {
                            otherTaggedTutors && otherTaggedTutors.length ? 
                            otherTaggedTutors.map((tutor,index, arr) => {
                              if (tutor !== arr[arr.length-1]) {
                                return <span key={index}>{`${tutor.firstName} ${tutor.lastName}`}<br/> </span>
                              } else {
                                return <span key={index}>{`${tutor.firstName} ${tutor.lastName}`}</span>
                              }
                            }) : null 
                            
                          }
                        </div>
                      </div>
                    </div> : <div className="each-detail text-center"><h4>Loading</h4></div>
                    }
                  </form>
                  <div className="text-right mt-md-5 mt-3">
                    {/* <button className="btn btn-primary btn-glow mr-2" onClick={() => props.history.push('/listTasks')}>View Chat Details</button> */}
                    <button className="btn btn-primary btn-glow" onClick={() => props.history.push('/listTasks')}>Back</button>
                  </div>
                </div>
              </div>
              <footer>
                <div className="footer-text d-flex align-items-centerf justify-content-between">
                  <span className="d-block">2020 © IndiaNIC</span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Home>
    );
  }


export default connect(null, { callApi })(ViewTask);