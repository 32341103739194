import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { callApi } from '../actions/commonFunction'; // COMMON FUNCTIONS
import common from '../actions/common' // Validations
import errorMessages from '../actions/errorMessages'; // ERROR MESSAGE
import API from '../actions/API';
import Theme from './Theme'
import Home from './Home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryString = require('query-string')




function ChangePassword(props) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  let [changeLoading ,setChangeLoading] = useState(false);
  const [token, setToken] = useState('');
  let [hidden ,setHidden]=useState(true)
  var [changeErrors, setChangeErrors] = useState({newPassword:"",confirmPassword:""});

  useEffect(() => {
    let url = props.location.search;
    let params = queryString.parse(url);
      //   this.setState({ token: params.token })
   setToken(params.token)
  }, []);

  const validateForm = () => {
    let changeErrors = { newPassword: "",confirmPassword:"" }
    let isFormValid = true;
    
    //for new password
    if (!newPassword.trim()) changeErrors.newPassword = errorMessages.PROVIDE_PASSWORD;
    else if (!common.validatePassword(newPassword)) changeErrors.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeErrors.newPassword = '';
    
    //for confirm password
    if (!confirmPassword.trim()) changeErrors.confirmPassword = errorMessages.PROVIDE_PASSWORD;
    else if (!common.validatePassword(confirmPassword)) changeErrors.confirmPassword = errorMessages.PROVIDE_VALID_PASSWORD;

    // else if (newPassword !== confirmPassword) changeErrors.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
    else changeErrors.confirmPassword = '';
    
    if(changeErrors.newPassword !== '' || changeErrors.confirmPassword !== '') isFormValid = false;
    else isFormValid = true;

    setChangeErrors(changeErrors);
    setIsFormValid(isFormValid);
    return isFormValid;
  }
  const resetPassword = async (event) => {
    event.preventDefault()
    var body = { 
      oldPassword:newPassword, 
      newPassword:confirmPassword,
      // token:localStorage.getItem("accessToken")
    }
    try{
      // if(validateForm()) {
      // setChangeLoading(true)
      //   console.log('validate password')
      //   const response = await props.callApi(API.CHNAGE_PASSWORD, body, 'post' ,"" ,true);
      //   setChangeLoading(false)
      //   if(response.status === 1) {
      //     // common.showSuccessMessage(response.message)
      //     toast.success(response.message,{
      //       position: "top-right",
      //       autoClose: 1500,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     common.setItem('accessToken', '')
      //     props.history.push('/')
      //   }
      // }
    } catch (error) {
      setChangeLoading(false)
      throw error;
    }
  }

  return (
    <Home>
    <div className="App">
      <header className="App-header">
      <div className="login-wrapper" style={{background: 'url(assets/images/login-bg.jpg) no-repeat center center', backgroundSize: 'cover'}}>
        <div className="login-body">
          <section className="login-container row m-0">
            <div className="col-xl-8 col-11">
              <div className="card bg-login-card">
                <div className="row m-0">
                  <div className="col-md-6 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header">
                        <div className="card-title text-center mb-4">
                          <h4 className="mb-2 mt-2">Change Password</h4>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="card-body">
                          <form autoComplete='off'>
                          <div className="form-group mb-2">
                            <label  className="col-form-label label">old  Password</label>
                            <div className="col-md-12">
                            <input  className='form-control' type='password' placeholder='old Password'
                              name='newPassword' value={newPassword} 
                              onChange={(e) => {setNewPassword(e.target.value) ;changeErrors=Object.assign(changeErrors, { newPassword: "" });setChangeErrors(changeErrors)}} />
                                 <span className='error-msg'  style={{color: "red"}} >{changeErrors.newPassword}</span>
                            </div>
                          </div>
                          <div className="form-group mb-2 position-relative">
                          <label  className="col-form-label label">New  Password:</label>
                            <div className="col-md-12">
                            <input className='form-control'  type={hidden ? "password" :"text"} placeholder='New Password' id='confirmpassword'
                              name='confirmPassword'  value={confirmPassword} required 
                              onChange={(e) => {setConfirmPassword(e.target.value) ;changeErrors=Object.assign(changeErrors, { confirmPassword: "" });setChangeErrors(changeErrors)}} />
                              												  	{/* <span onClick={()=>setHidden(!hidden)} toggle="#password-field" className={hidden ? "bx bx-hide field-icon toggle-password":"bx bx-show field-icon toggle-password"}></span> */}
  
                        <em className='fa fa-lock' aria-hidden='true' />
                               <span className='error-msg'  style={{color: "red"}} >{changeErrors.confirmPassword}</span>
                            </div>
                          </div>

                          
                          <div className='mt-5 text-center'>
                          <button className='btn btn-primary mr-2' type='submit'  onClick={resetPassword}>  {
														changeLoading? "Loading...":"Change Password "
													}</button>
                        </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <picture>
                        <source srcSet="assets/images/forgot-password.webp" type="image/webp" />
                        <source srcSet="assets/images/forgot-password.png" type="image/png" /> 
                        <img className="img-fluid" src="assets/images/forgot-password.png" alt="forgot-password" width={300} />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </header>
      {/* <Theme/> */}

   
   

    {/* End Login Wrapper */}
    </div>
   </Home>
  );
}

export default connect(null,{callApi})(ChangePassword);