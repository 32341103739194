const INTIAL_STATE = {
  adminData: {},
  adminProfileData: {},
  editAdminProfileData: {},
  edituserId: '',
  editEmailId: '',
  language: "en",
  theme: true,
  resize: true,
  sidebar: true,
}
export default (state = INTIAL_STATE, action) => {
  console.log("sf", action.type, action.payload)
  switch (action.type) {
    case 'ADMIN_LOGIN':
      return Object.assign({}, state, {
        adminData: action.payload.data
      })
    case "ADMIN_PROFILE":
      return Object.assign({}, state, {
        adminProfileData: action.payload.data
      });
    case "EDITADMIN_PROFILE":
      return Object.assign({}, state, {
        editAdminProfileData: action.payload.data
      });
    case "EDIT_USER":
      return Object.assign({}, state, {
        EDIT_USER: action.payload
      });
    case "EDIT_EMAIL":
      return Object.assign({}, state, {
        editEmailId: action.payload
      });
    case "theme":
      return Object.assign({}, state, {
        theme: action.payload
      });

    case "resize":
      return Object.assign({}, state, {
        resize: action.payload
      });
    case "sidebar":
      return Object.assign({}, state, {
        sidebar: action.payload
      });

    case "language":
      return Object.assign({}, state, { language: action.payload.data });
    // case EDIT_CMS_DATA:
    // return  Object.assign({},state,{editcms:action.payload});
    // case UPDATE_QA:
    // return  Object.assign({},state,{editQA:action.payload});
    // case USER_DETAILS:
    // return  Object.assign({},state,{userdetails:action.payload.data});
    // case VIEW_REPORTEDUSER :
    // return Object.assign({},state,{reportedUserId:action.id});
    // case EDIT_EMAIL_DATA :
    // return Object.assign({},state,{editEmailData:action.payload});
    // case UPDATE_EVENT :
    // return Object.assign({},state,{editEventData:action.payload});
    // case CONSULTANT :
    // return Object.assign({},state,{consultant:action.consultant});
    case "LOGOUT":
      return Object.assign({}, state, {
        adminData: {},
        adminProfileData: {},
        editAdminProfileData: {},
        edituserId: '',
        editEmailId: '',
        language: "en",
        // theme:true
      })
    default:
      return state;
  }

}