import swal from "sweetalert";

//**********************************************************************
// @PURPOSE : //We can use following function to store data in localstorage,
//Validations and 
//Messages
//*********************************************************************//
export default {
    
    /*************************************************************************************
                                    Store Data To local Storage
    **************************************************************************************/
    setItem(key, value) {
        localStorage.setItem(key, value);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Get Data From local Storage
   **************************************************************************************/
    getItem(key) {
        return localStorage.getItem(key);
    },
    /*************************************************************************************
                                 Remove Data in local Storage
   **************************************************************************************/
    removeItem(key) {
        localStorage.removeItem(key)
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Email Validation
    **************************************************************************************/
    validateEmail(email) {
        var pattern = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return pattern.test(email);
    },
    /*************************************************************************************/
    
     /*************************************************************************************
                               App ID and secret Validation
    **************************************************************************************/
    validateIDSecret(id) {
            const pattern = new RegExp(/^[a-zA-Z0-9_]{5,50}$/);
            return pattern.test(id);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Password Validation
    **************************************************************************************/
    validatePassword(pass) {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/])[A-Za-z\d-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/]{6,20}$/);
        return pattern.test(pass);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               UserName Validation
    **************************************************************************************/
    validateUsername(name) {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%-*?&#])[A-Za-z\d@$!_%-*?&#]{5,}$/);
        return pattern.test(name);
    },

    /*************************************************************************************
                               UserName Validation
    **************************************************************************************/
    validatePort(port) {
        var pattern = new RegExp(/^([0-9]){3,4}$/);
        return pattern.test(port);
    },

     /*************************************************************************************
                               UserName Validation
    **************************************************************************************/
    validateHostName(name) {
        var pattern = new RegExp(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/);
        return pattern.test(name);
    },
    /*************************************************************************************/
    /*************************************************************************************
                               Mobile Number Validation
    **************************************************************************************/
    validateMobileNumber(mobileNo) {
        var pattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
        return pattern.test(mobileNo);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               First and Last Name Validation
    **************************************************************************************/
    validateName(name){
        const pattern = new RegExp(/^[a-z ,.'-]+$/i);
        return pattern.test(name)
    } ,
    /*************************************************************************************
                                Character Validation
    **************************************************************************************/
   
    allowChar(e) {
        const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
        return pattern.test(e);
    },
    /*************************************************************************************
                                URL Validation
    **************************************************************************************/
    validateURL(url) {
        const pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return pattern.test(url);
    },
    /*************************************************************************************
                                Number Validation
    **************************************************************************************/
    allowNumbers(e) {
        const pattern = new RegExp(/^[0-9\b]+$/);
        return pattern.test(e);
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Set Success Message
    **************************************************************************************/
    showSuccessMessage(msg) {
        swal({
            position: 'center',
            icon: 'success',
            text: msg,
            buttons: false,
            timer: 2500,
            className: 'custom-toaster'
        });
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Set Error Message
    **************************************************************************************/
    showErrorMessage(msg) {
        swal({
            position: 'center',
            icon: 'error',
            text: msg,
            buttons: false,
            timer: 3000,
            className: 'custom-toaster'
        });
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Set Warning Message
    **************************************************************************************/
    showWarningMessage(msg) {
        swal({
            position: 'center',
            icon: 'warning',
            text: msg,
            buttons: false,
            timer: 3000,
            className: 'custom-toaster'
        });
    },
    /*******************************************************************************************/

    // /*************************************************************************************
    //                            PinCode Validate
    // **************************************************************************************/
    // // for pincode number validate
    // validatePincodeNumber(mobileNo) {
    //     var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
    //     return pattern.test(mobileNo);
    // },
}