import React, { Component } from 'react';
import Home from '../Home';
function HomePage(props){
    return ( 
      <Home>
<div>
    <div className="dashboard-container">
      {/* Main start here */}
      <div className="main-content-area">
        <div className="overlay" />
        {/* -- Body overlay */}
        {/* Header end */}
        <div className="main-content-block">
        <img className="round" src="assets/images/coming-soon.png" alt="avatar"  /> 
        </div>
        <footer>
          <div className="footer-text d-flex align-items-centerf justify-content-between">
            <span className="d-block">2020 © IndiaNIC</span>
          </div>
        </footer>
      </div>
    </div>
  </div>
        
      </Home>
     );
  }

 
export default HomePage;