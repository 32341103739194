

// *************************************************************//
//@Purpose : We can use following function to use localstorage
//*************************************************************//
export default {
    REQUIRED:"*Field Required",
    PROVIDE_EMAIL_OR_PHONE: "*Please enter email or phone Number",
    PROVIDE_EMAIL: "*Please enter email",
    PROVIDE_VALID_EMAIL: "*Please enter valid email",
    PROVIDE_PASSWORD: "*Please enter password",
    PROVIDE_CURRENT_PASSWORD: "*Please enter current password",
    PROVIDE_NEW_PASSWORD: "*Please enter new password",
    PROVIDE_VALID_PASSWORD: `* Password should contain atleast a special character (@, $, !, %, etc), a capital letter, a number and length should be between 6-20
`,
    PROVIDE_CONFIRM_PASSWORD: "*Please enter confirm password",
    PASSWORD_NOT_MATCHED: "New password and confirm password not matched",
    PROVIDE_NAME: "*Please enter your name",
    PROVIDE_FIRST_NAME: "*Please enter first name",
    PROVIDE_LAST_NAME: "*Please enter last name",
    PROVIDE_MOBILE_NUMBER: "*Please enter mobile number",
    PROVIDE_VALID_MOBILE_NUMBER: "*Please enter valid mobile number(length 10)",
    PROVIDE_NAME: "*Please enter name",
    PROVIDE_ADDRESS: "*Please enter address",
    PROVIDE_ADDRESS_LINE_1: "*Enter address line 1",
    PROVIDE_ADDRESS_LINE_2: "*Enter address line 2",
    PROVIDE_CITY: "*Enter city",
    PROVIDE_STATE: "*Enter state",
    PROVIDE_ZIPCODE: "*Enter zipcode",
    PROVIDE_VALID_ZIPCODE: "*Enter valid zipcode",
    PROVIDE_WEBSITE: "Please enter valid website (no whitespace)",
    PROVIDE_ROLE:"*select role",
    PROVIDE_COUNTRY_NAME:"*Enter country name ",
    PROVIDE_COUNTRY_CODE:"*Enter country code",
    PROVIDE_PHONE_CODE: "*Enter phone code ",
    PROVIDE_TIMEZONE:"*Enter Timezone",
    PROVIDE_CURRENCY:"*Enter currency",
    PROVIDE_BACKEND:"*Secret key is required",
    PROVIDE_FROUNTEND:'*Publishable key is required',
    PROVIDE_CLIENTID:'*ClientId is required',
    PROVIDE_CLIENTSECRET:'* clientSecret is required',
    PROVIDE_MODE:'*Mode is required',
    PROVIDE_FROM_EMAIL:"Please enter from email",
    PROVIDE_VALID_FROM_EMAIL: "Please enter valid from email",
    PROVIDE_PORT:"Please enter SMTP port",
    PROVIDE_VALID_PORT: "Please enter valid port number",
    PROVIDE_USER_NAME: "*Please enter user name",
    PROVIDE_HOST: "Please enter host name ",
    PROVIDE_VALID_HOST: "Please enter valid host name ",
    PROVIDE_ENCRYPTION:"Please enter encryption",
    PROVIDE_VALID_ENCRYPTION : "Please enter encryption (minimum length 3)",
    PROVIDE_FROMNAME: "Please enter from name",
    PROVIDE_VALID_FROMNAME: "Please enter from name (minimum length 5)",
    PROVIDE_APPID: "Please enter appId ",
    PROVIDE_VALID_APPID: "Please enter appId (minimum length 5)",
    PROVIDE_SMS_USER_NAME: "Please enter user name (minimum length 5)",
    PROVIDE_VALID_SMTP_USER_NAME: "User name can be alphanumeric with special character _ (minimum length 5)",
    PROVIDE_SMTP_USER_NAME: "Please enter user name",
    PROVIDE_SMTP_PASSWORD: "Please enter password (minimum length 5)",
    PROVIDE_SMS_PASSWORD: "Please enter password",
    PROVIDE_VALID_SMS_PASSWORD: "Please enter password (minimum length 5)",
    PROVIDE_ACCOUNTSID:"*Please enter account SID",
    PROVIDE_AUTHTOKEN:"*Enter auth token",
    PROVIDE_FBURL:'Please enter correct facebook link (no whitespace)',
    PROVIDE_TWITTER:'Please enter correct twitter link (no whitespace)',
    PROVIDE_INSTAGRAM:'Please enter correct instagram link (no whitespace)',
    PROVIDE_GITHUB:'Please enter correct github link (no whitespace)',
    PROVIDE_CODEPEN:'Please enter correct codepen link (no whitespace)',
    PROVIDE_SLACK : 'Please enter correct slack link (no whitespace)',
    PROVIDE_PINTERESTURL: 'Please enter correct pinterest link (no whitespace)',
    PROVIDE_LINKEDINURL: 'Please enter  correct linkedIn link (no whitespace)',
    PROVIDE_FACEBOOK: "Please enter facebook link",
    PROVIDE_FBAPPID:"*Please enter facebook appId",
    PROVIDE_VALID_FBAPPID:"*Please enter valid facebook appId (length between 5-50 characters)",
    PROVIDE_TWITTERAPID:"*Please enter twitter appId",
    PROVIDE_VALID_TWITTERAPID:"*Please enter valid twitter appId (length between 5-50 characters)",
    PROVIDE_LINKEDINAPID:"*Please enter linkedIn appId",
    PROVIDE_VALID_LINKEDINAPID:"*Please enter valid linkedIn appId (length between 5-50 characters)",
    PROVIDE_FBAPPSECRET:"*Please enter facebook app secret",
    PROVIDE_VALID_FBAPPSECRET:"*Please enter valid facebook app secret (length between 5-50 characters)",
    PROVIDE_TWITTERAPSECRET:"*Please enter twitter app secret",
    PROVIDE_VALID_TWITTERAPSECRET:"*Please enter valid twitter app secret (length between 5-50 characters)",
    PROVIDE_LINKEDINAPSECRET:"*Please enter linkedIn app secret ",
    PROVIDE_VALID_LINKEDINAPSECRET:"*Please enter valid linkedIn app secret (length between 5-50 characters)",
    PROVIDE_ENVIRONMENT: "*Environment is required",
    PROVIDE_VALID_ENVIRONMENT:"*Provide valid environment (length between 5-50 characters)",
    PROVIDE_PUBLIC_API_ID: "*API loginId is required",
    PROVIDE_VALID_API_ID: "*Provide valid ID (length between 5-50 characters)",
    PROVIDE_TEST_TRANSACTION_KEY: "*Test transaction key is required",
    PROVIDE_VALID_TEST_TRANSACTION_KEY: "*Provide valid test transaction key (length between 5-50 characters)",
    PROVIDE_PUBLIC_TRANSACTION_KEY: "*Public transaction key is required",
    PROVIDE_VALID_PUBLIC_TRANSACTION_KEY: "*Provide valid public transaction key (length between 5-50 characters)",
    PROVIDE_GOOGLETOKEN:"Enter Google OAuth Token",
    PROVIDE_OOFLINEMSG:"Enter offline message",
    PROVIDE_METATITLE:"*Enter Meta Title ",
    PROVIDE_METAKEYWORD:"*Enter Meta Keyword",
    PROVIDE_METADESCRIPTION:"*Enter Meta Discription",
    PROVIDE_HEADERSINPPET:"Add header snippet",
    PROVIDE_FOOTERSINPPET:"Add footer snippet",
    PORVIDE_ANALYTICS :" Add Analytics snippet",
    PROVIDE_COMPANY_NAME:"Enter company name ",
    PROVIDE_TEMPLATE_NAME:"Enter template name ",
    PROVIDE_TEMPLATE_SUBJECT:"Enter template subject ",
    PROVIDE_EMAIL_FROM:"Enter email id from ",
    PROVIDE_EMAIL_TEMPLATES:"Enter email templates ",
    PROVIDE_SITE_FEVICON:"Please provide Site fevicon Icon",
    PROVIDE_SITE_SMALL_LOGO:"Please provide Site Small Image Icon",
    PROVIDE_SITE_LARGE_LOGO:"Please provide Site Large Image Icon",
    PROVIDE_LIMITED_OFFLINE_MSG: "Message length should be under 50 characters",
    PROVIDE_METATITLE_LIMIT: "Meta title should be under 70 characters",
    PROVIDE_METADESCRIPTION_LIMIT: "Meta description should be under 160 characters",
    PROVIDE_METAKEYWORDS_LIMIT: "Meta keywords should be under 150 characters",
    START_END_DATE_ERROR: "End date and time should be greater than start date and time",
    START_LESS_THAN_CURRENT: "Start date and time should be greater than current date and time",
    END_LESS_THAN_CURRENT: "End date and time should be greater than current date and time",
    START_END_DATE_EQUAL_ERROR: "Start and end date time can't be same",
    
    PROVIDE_DEPARTMENT: "*Please provide department",
    PROVIDE_SHIFTSLOT: "*Please provide shift slot",
    PROVIDE_DESCRIPTION: "*Please provide description",
    PROVIDE_WORKTYPE: "*Please provide work type"

}

// PROVIDE_VALID_PASSWORD: `*Please enter valid password

// • A password should be alphanumeric.

// • At least one  letter of the password should be capital.

// •  Password must contain a special character (@, $, !, &, etc).

// •  Password length must be equal or  greater than 6 characters.
// `,