import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import common from '../actions/common' // Validations
import Header from '../components/Header.jsx';
function Home(props) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  let Theme = props.theme && props.theme.data;
  let resize = props.resize && props.resize.data;
  let sidebar = props.sidebar && props.sidebar.data;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [Theme, resize, sidebar])


  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            {props.children}
          </div>

        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  language: state.admin.language,
  theme: state.admin.theme,
  resize: state.admin.resize,
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
});



export default connect(mapStateToProps, null)(withRouter(Home));